import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';
import { Chat, ChatGroup, Messages, UsersChat } from 'app/chat/chat.model';
import { Message } from 'app/inbox/inbox.model';
import { GroupUser } from 'app/pages/full-pages/imports-api-group/Model/GroupUser';
import { MyGroup } from 'app/pages/full-pages/imports-api-group/Model/MyGroup';
import { JsonContainer } from 'app/utils/json-container';
import { RestService } from 'app/utils/rest-service';
import { StorageUtils } from 'app/utils/storage-utils';
import { Group } from 'ng2-dragula';
import { Session } from 'protractor';
import { StudySession } from '../study-model/StudySession';
import {HandHistory} from '../../full-pages/hh/hh/hand-history';




@Injectable()
export class StudyService extends RestService {

  constructor(private client: HttpClient, private storageUtils: StorageUtils) {
    super(client, 'studySession', storageUtils);
  }

  newSession(session: StudySession, callback:(result: JsonContainer, study: StudySession) => void){
    this.executePost('newSession' ,session, callback)
  }

  updateSession(session: StudySession, callback:(result: JsonContainer, study: StudySession) => void){
    this.executePost('updateSession' ,session, callback)
  }

  getSessionByUser(idUser: string, callback:(result: JsonContainer, responseList: any) => void){
    this.executePost('getSessionByUser/' + idUser ,null, callback)
  }

  searchBlock(session: StudySession, callback:(result: JsonContainer, responseList: any) => void){
    this.executePost('searchBlock', session, callback)
  }

  searchLiberate(session: StudySession, callback:(result: JsonContainer, responseList: any) => void){
    this.executePost('searchLiberate', session, callback)
  }

  searchGroupStudy(session: StudySession, callback:(result: JsonContainer, responseList: any) => void){
    this.executePost('searchGroupStudy', session, callback)
  }

  tableSearch(session: StudySession, callback:(result: JsonContainer, responseList: any) => void){
    this.executePost('tableSearch', session, callback)
  }

  saveNotes(session: StudySession, callback:(result: JsonContainer, studyNote: any) => void){
    this.executePost('saveNotes', session, callback)
  }

  searchNotes(idHH: string, idUser: string, idSession: string, callback:(result: JsonContainer, responseList: any) => void){
    this.executePost(`searchNotes/${idHH}/${idUser}/${idSession}` ,null, callback)
  }

  searchAnnotations(idHH: string, idUser: string, idSession: string, callback:(result: JsonContainer, responseList: any) => void){
    this.executePost(`searchAnnotations/${idHH}/${idUser}/${idSession}` ,null, callback)
  }

  listGroup(idSession: string, callback: (result: JsonContainer, groupUser: GroupUser[]) => void) {
    this.executeGet('listGroup/' + idSession, callback)
  }

  listMessages(idUser: String, idChat: String, callback: (result: JsonContainer, responseList: Messages[]) => void) {
    this.executeGet('listMessages/' + idUser + "/" + idChat, callback)
  }

  saveMessage(message: Messages, callback:(result: JsonContainer, responseList: Messages[]) => void){
    this.executePost('saveMessage',message, callback)
  }

  newChatFromSession(idUser1: string, idUser2: string,idSession: string, callback:(result: JsonContainer, chat: any) => void){
    this.executeGet(`newChatFromSession/${idUser1}/${idUser2}/${idSession}` , callback)
  }

  loadChatSession(idUser1: String, idUser2: string, callback:(result: JsonContainer, chat: any) => void){
    this.executeGet(`loadChatSession/${idUser1}/${idUser2}` , callback)
  }

// saveChatInSession(session: StudySession, callback:(result: JsonContainer, study: StudySession) => void){
//   this.executePost('saveChatInSession' ,session, callback)
// }

  groupChat(groupChat: ChatGroup, callback:(result: JsonContainer, groupChat: any) => void){
    this.executePost('groupChat' ,groupChat, callback)
  }

 // videoLink(ss: StudySession, callback:(result: JsonContainer, study: StudySession) => void){
 //   this.executePost('videoLink', ss, callback)
 // }

  closeSession(file: FormData, callback:(result: JsonContainer, url: String) => void){
    this.executePostVideo('closeSession', file, callback)
  }

  closeSessionQueue(ss: StudySession, callback:(result: JsonContainer, url: String) => void){
    this.executePostVideo('closeSessionQueue', ss, callback)
  }

  searchLiberateList(session: StudySession, callback:(result: JsonContainer, responseList: any) => void){
    this.executePost('searchLiberateList', session, callback)
  }

  downloadHHFromStudy(idSession: String, callback:(result: JsonContainer, hh: HandHistory) => void){
    this.executeGet(`downloadHH/${idSession}` , callback)
  }
}

