import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {FullLayoutComponent} from './layouts/full/full-layout.component';
import {ContentLayoutComponent} from './layouts/content/content-layout.component';

import {Full_ROUTES} from './shared/routes/full-layout.routes';
import {CONTENT_ROUTES} from './shared/routes/content-layout.routes';

import {AuthGuard} from './shared/auth/auth-guard.service';
import {LoginPageComponent} from './pages/content-pages/login/login-page.component';
import { RecordVideoComponent } from './pages/content-pages/record-video/record-video.component';

const appRoutes: Routes = [
  { path: 'login', component: LoginPageComponent, data: { title: 'Login' }},
  { path: '', component: FullLayoutComponent, children: Full_ROUTES,  canActivate: [AuthGuard]}, //canActivate: [AuthGuard]
  { path: '', component: ContentLayoutComponent, children: CONTENT_ROUTES },
  { path: 'record-video/:id', component: RecordVideoComponent, data: { title: 'Video' }},
  {
    path: '**',
    redirectTo: 'pages/error'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})

export class AppRoutingModule {
}
