

<div class="chart-import-page">

   <!-- <x-chartist class="height-400"
  [data]="barChart.data"
  [type]="barChart.type"
  [options]="barChart.options"
  [responsiveOptions]="barChart.responsiveOptions"
    [events]="barChart.events">
  </x-chartist> -->

  <apx-chart
  [series]="columnChartOptions.series"
  [colors]="columnChartOptions.colors"
  [chart]="columnChartOptions.chart"
  [xaxis]="columnChartOptions.xaxis"
  [yaxis]="columnChartOptions.yaxis"
  [title]="columnChartOptions.title"
  [dataLabels]="columnChartOptions.dataLabels"
  [stroke]="columnChartOptions.stroke"
  [grid]="columnChartOptions.grid"
  [tooltip]="columnChartOptions.tooltip"
  [plotOptions]="columnChartOptions.plotOptions"
  [fill]="columnChartOptions.fill"
  [legend]="columnChartOptions.legend"
  [labels]="columnChartOptions.labels"
  [markers]="columnChartOptions.markers"
  [theme]="columnChartOptions.theme"
  [responsive]="columnChartOptions.responsive">
</apx-chart>

</div>
