import { Injectable } from '@angular/core';
import { isNullOrUndefined } from '@swimlane/ngx-datatable';
import { Chat, UsersChat } from 'app/chat/chat.model';
import { Round } from 'app/shared/components/replayer-table/types/desk-type';
import { Constants } from 'app/utils/constants';
import { BehaviorSubject } from 'rxjs';
import { ArrowFunction } from 'typescript';

@Injectable({
  providedIn: 'root'
})
export class SectionStudyService {


  public chat1: Chat[] = [new Chat(true, "", ["TESTE - RODINEI"], "text")];
  public chat2: Chat[] = [
    new Chat(false, "", ["Teste"], "text"),
    new Chat(false, "", ["How can we help? We are here for you!"], "text"),
    new Chat(
      false,
      "",
      [
        "Hey John, I am looking for the best admin template.",
        "Could you please help me to find it out?",
        "It should be angular 9 bootstrap compatible.",
      ],
      "text"
    ),
    new Chat(
      false,
      "30 minutes ago",
      [
        "Absolutely!",
        "Apex admin is the responsive angular 9 bootstrap admin template.",
      ],
      "text"
    ),
    new Chat(
      false,
      "1 hour ago",
      [
        "Looks clean and fresh UI.",
        "It is perfect for my next project.",
        "How can I purchase it?",
      ],
      "text"
    ),
    new Chat(false, "", ["Thanks, from ThemeForest."], "text"),
    new Chat(false, "", ["I will purchase it for sure.", "Thanks."], "text"),
  ];
  public chat3: Chat[] = [
    new Chat(false, "", ["Teste chat 3"], "audio"),
    new Chat(false, "", ["How can we help? We are here for you!"], "text"),
    new Chat(
      true,
      "",
      [
        "Hey John, I am looking for the best admin template.",
        "Could you please help me to find it out?",
        "It should be angular 9 bootstrap compatible.",
      ],
      "text"
    ),
    new Chat(
      false,
      "30 minutes ago",
      [
        "Absolutely!",
        "Apex admin is the responsive angular 9 bootstrap admin template.",
      ],
      "text"
    ),
    new Chat(
      true,
      "1 hour ago",
      [
        "Looks clean and fresh UI.",
        "It is perfect for my next project.",
        "How can I purchase it?",
      ],
      "text"
    ),
    new Chat(false, "", ["Thanks, from ThemeForest."], "text"),
    new Chat(true, "", ["I will purchase it for sure.", "Thanks."], "text"),
  ];
  public usersChat: UsersChat[] = [
    {
      userId: "1",
      name: "Elizabeth Elliott",
      avatar: "assets/img/portrait/small/avatar-s-2.png",
      lastChatTime: "9:04 PM",
      status: "online",
      isPinnedUser: true,
      isMuted: false,
      unreadMessageCount: "",
      isActiveChat: false,
      lastChatMessage: "Okay",
      chats: this.chat1,
    },
    {
      userId: "2",
      name: "Lucas Howell",
      avatar: "assets/img/portrait/small/avatar-s-7.png",
      lastChatTime: "4:14 AM",
      status: "busy",
      isPinnedUser: false,
      isMuted: false,
      unreadMessageCount: "3",
      isActiveChat: false,
      lastChatMessage: "Thank you",
      chats: this.chat2,
    },
    {
      userId: "3",
      name: "Sarah Woods",
      avatar: "assets/img/portrait/small/avatar-s-8.png",
      lastChatTime: "2:14 AM",
      status: "away",
      isPinnedUser: false,
      isMuted: true,
      unreadMessageCount: "12",
      isActiveChat: true,
      lastChatMessage: "Hello John!",
      chats: this.chat3,
    },
  ];
  currentIndexBehavior = new BehaviorSubject<number>(0);
  currentIndex$ = this.currentIndexBehavior.asObservable();
  currentIndex = 0;
  round: Round = {} as Round;
  round$ = new BehaviorSubject<Round>({} as Round);
  refreshLibraryPage = false;
  showModalConfirm = false;
  showModalWait = false;
  showModalShare = false;
  ngbNavItem = 1;
  private urlWSBase;
  private notesClient: WebSocket;
  private chatClient: WebSocket;

  constructor() {
    this.urlWSBase = Constants.WS_URL
  }

  openWSAnnotation(ssId: string, cb: WSMessage) {
    if (this.isConnectedWSAnnotation()) this.closeWSAnnotation();

    this.notesClient = new WebSocket(
      `${this.urlWSBase}/ws/annotation/${ssId}`
    );
    this.notesClient.onmessage = cb;

  }

  closeWSAnnotation() {
    this.notesClient.close();
  }

  sendMessageWSAnnotation(mesage: string) {
    if(this.isConnectedWSAnnotation())
      this.notesClient.send(mesage);
  }

  isConnectedWSAnnotation() {
    if(isNullOrUndefined(this.notesClient)) return false;
    if(this.notesClient.readyState === this.notesClient.OPEN) return true;

    return false;
  }

  openWSChat(idUser: string, cb: WSMessage) {
    if (this.isConnectedWSChat()) this.closeWSChat();

    this.chatClient = new WebSocket(
      `${this.urlWSBase}/ws/chat/${idUser}`
    );
    this.chatClient.onmessage = cb;
  }

  sendMessageWSChat(mesage: string) {
    if(this.isConnectedWSChat())
      this.chatClient.send(mesage);
  }

  closeWSChat() {
    this.chatClient?.close();
  }

  isConnectedWSChat() {
    if(isNullOrUndefined(this.chatClient)) return false;
    if(this.chatClient.readyState === this.chatClient.OPEN) return true;

    return false;
  }
}

interface WSMessage {
  (message: MessageEvent): void;
}
