import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';
import { JsonContainer } from 'app/utils/json-container';
import { RestService } from 'app/utils/rest-service';
import { StorageUtils } from 'app/utils/storage-utils';
import { Plan } from './plan-model';




@Injectable()
export class PlanService extends RestService {

  constructor(private client: HttpClient, private storageUtils: StorageUtils) {
    super(client, 'plan', storageUtils);
  }

  searchPlan(name: string, callback: (result: JsonContainer, plan: Plan) => void) {
    this.executeGet('load/' + name, callback)
  }

}