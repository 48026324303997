<section id="login">
  <div class="row auth-height full-height-vh m-0"> <!-- auth-height full-height-vh -->
    <div class="login-container">
      <div class="row popup">
        <app-popup-language></app-popup-language>
      </div>
      <div class="col-12 col-md-12">
        <div class="logo-content">
          <img src="../../../../assets/img/logos/logo-good-poker.png" alt="good poker">
        </div>

        <div class="form-content">
          <form [formGroup]="loginForm">

            <div class="input-group input-group-custom">
              <div class="input-group-prepend input-group-prepend-custom">
                <span class="input-group-text" id="basic-addon1"><img class="input-icon"
                    src="../../../../assets/img/icons/user-icon.png" alt="" srcset=""></span>
              </div>
              <input type="text" formControlName="username" class="form-control form-control-custom"
                placeholder="E-mail"
                [ngClass]="{ 'is-invalid': loginFormSubmitted && lf.username.invalid, 'is-valid': loginFormSubmitted && !lf.username.invalid }"
                required>
              <div *ngIf="loginFormSubmitted && (lf.username.invalid || lf.username.errors?.required)"
                class="help-block mt-1 text-danger">
                <i class="ft-alert-circle align-middle"></i> {{ 'login.email_valido' | translate }}
              </div>
            </div>

            <div class="input-group input-group-custom">
              <div class="input-group-prepend input-group-prepend-custom">
                <span class="input-group-text" id="basic-addon1"><img class="input-icon"
                    src="../../../../assets/img/icons/password-icon.png" alt="" srcset=""></span>
              </div>
              <input type="password" formControlName="password" class="form-control form-control-custom"
                placeholder="{{ 'login.senha' | translate }}"
                [ngClass]="{ 'is-invalid': loginFormSubmitted && lf.password.invalid, 'is-valid': loginFormSubmitted && !lf.password.invalid }"
                required>
              <div *ngIf="loginFormSubmitted && (lf.password.invalid || lf.password.errors?.required)"
                class="help-block mt-1 text-danger">
                <i class="ft-alert-circle align-middle"></i> {{ 'login.senha_valida' | translate }}
              </div>
              <div *ngIf="loginFormSubmitted && emailError != ''" class="help-block mt-1 text-danger">
                <i class="ft-alert-circle align-middle"></i> {{emailError}}
              </div>
            </div>

            <div class="form-group form-group-custom">
              <button class="btn btn-custom btn-login btn-bg-green" (click)="onSubmit()">login</button>
            </div>

            <div class="mb-3 font-small-2 checkbox-container"> <!-- d-sm-flex justify-content-between -->
              <div class="remember-me mb-2 mb-sm-0">
                <div class="checkbox auth-checkbox">
                  <input type="checkbox" formControlName="rememberMe" class="form-control form-control-custom"
                    id="rememberMe">
                  <label class="label-custom" for="rememberMe"><span class="mb-3">{{ 'login.lembre_de_mim' | translate
                      }}</span></label>
                </div>
              </div>
              <a [routerLink]="['/pages/forgotpassword']">{{ 'login.esqueci_minha_senha' | translate }}</a>
            </div>

            <hr>

            <div class="form-group form-group-custom account-signup">
              <p>{{ 'login.ainda_nao_possui_uma_conta' | translate }}? <a
                  href="https://goodpoker.net/planos/" target="_blank">{{ 'login.clique_aqui' | translate
                  }}</a></p>
            </div>
          </form>
        </div>

      </div>
    </div>

    <div class="modal-banner-container">
      <div class="modal-banner-content">

        <!-- <iframe width="600px" height="400" src="https://www.youtube.com/embed/MRqjOuhZsoY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->

        <!-- <div class="description">
          <p> Conheça a plataforma The Good Poker</p>
        </div> -->
        <!-- <div class="play-icon">
          <a class="link-btn-modal"  (click)="videoEmbeded.fire()"><img src="../../../../assets/img/icons/play-video-icon.png" alt="executar video"></a>
        </div> -->
      </div>

    </div>

    <ngx-spinner></ngx-spinner>
    <swal #videoEmbeded [showCancelButton]="false" [showConfirmButton]="false" [focusCancel]="false" [swalOptions]>

      <div *swalPortal>
        <iframe width="100%" height="400" src="https://www.youtube.com/embed/MRqjOuhZsoY" title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe>
      </div>
    </swal>
  </div>
</section>
