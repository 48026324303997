<!-- <p>chart-donut works!</p> -->

<!-- <p class="font-medium-2 text-center mb-0 mt-2">Indice de desempenho</p> -->
<div id="donut-dashboard-chart" class="donut">
  <x-chartist class="height-250"
  [data]="DonutChart.data"
  [type]="DonutChart.type"
  [options]="DonutChart.options"
  [responsiveOptions]="DonutChart.responsiveOptions"
  [events]="DonutChart.events">
  </x-chartist>
</div>
