export class UserCard {

    creationDate: any;

    email: string;

    id: string;

    name: string;

    status: string;

    avatar: string;

    // type: string;
}
