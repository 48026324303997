<div class="modal-container-custom" *ngIf="isVisible">
  <div class="modal-body-custom animate__animated" [ngClass]="{'animate__bounceIn': isVisible, 'animate__bounceOut': !isVisible}">
    <div class="modal-content-custom text-center">
      <p class="loader"></p>
      <p class="text-white text-bold-700 font-large-1">Aguarde</p>
      <p class="text-bold-600 font-medium-2 mb-5">Estamos processando o vídeo do seu estudo...</p>
      <p class="text-white text-bold-700 font-medium-1 mb-4">Você receberá uma menmsagem quando o vídeo estiver disponível na biblioteca</p>
    </div>
    <div class="modal-footer-custom text-center">
      <button class="btn btn-custom btn-modal-cancel mr-3" (click)="confirmOptionModal()"> VER BIBLIOTECA </button>
    </div>
  </div>

</div>
