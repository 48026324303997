import 'rrweb-player/dist/style.css';

import { ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import rrwebPlayer from 'rrweb-player';

@Component({
  selector: 'app-rrweb-replayer',
  templateUrl: './rrweb-replayer.component.html',
  styleUrls: ['./rrweb-replayer.component.scss']
})
export class RrwebReplayerComponent implements OnInit, OnChanges {
  @ViewChild("rrweb")
  private rrweb: ElementRef;

  rrwebPlayer: rrwebPlayer;

  @Input() events: any;

  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.events?.currentValue) {
      const events: any[] = changes.events.currentValue;
      this.rrwebPlayer = new rrwebPlayer({
        target: this.rrweb.nativeElement,
        props: {
          events: events,
          autoPlay: false,
          width: 800,
          height: 456,
          mouseTail: false,
          skipInactive: true
        }
      });
      this.cdr.detectChanges();
    }
  }

}
