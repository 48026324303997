import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { HandHistoryService } from 'app/pages/full-pages/hh/hh/hand-history-service';
import { PerformanceService } from 'app/pages/full-pages/performance-consume/performanceService/performance.service';
import { StorageUtils } from 'app/utils/storage-utils';
import { ChartType, ChartEvent } from "ng-chartist";

import {
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexYAxis,
  ApexGrid,
  ApexDataLabels,
  ApexStroke,
  ApexTitleSubtitle,
  ApexTooltip,
  ApexLegend,
  ApexPlotOptions,
  ApexFill,
  ApexMarkers,
  ApexTheme,
  ApexNonAxisChartSeries,
  ApexResponsive
} from "ng-apexcharts";

// apex-chart
export type ChartOptions = {
  series: ApexAxisChartSeries | ApexNonAxisChartSeries;
  colors: string[],
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis | ApexYAxis[],
  title: ApexTitleSubtitle;
  dataLabels: ApexDataLabels,
  stroke: ApexStroke,
  grid: ApexGrid,
  legend?: ApexLegend,
  tooltip?: ApexTooltip,
  plotOptions?: ApexPlotOptions,
  labels?: string[],
  fill: ApexFill,
  markers?: ApexMarkers,
  theme: ApexTheme,
  responsive: ApexResponsive[]
};


export interface Chart {
  type: ChartType;
  data: Chartist.IChartistData;
  options?: any;
  responsiveOptions?: any;
  events?: ChartEvent;
  // plugins?: any;
}

declare var require: any;
let percent: any = 100
const data: any = {
  series: [
    {
      name: 'done',
      className: 'ct-done',
      value: 0,
    },
    {
      name: 'progress',
      className: 'ct-progress',
      value: 100,
    },
  ],
};

@Component({
  selector: 'app-chart-donut',
  templateUrl: './chart-donut.component.html',
  styleUrls: ['./chart-donut.component.scss'],
})
export class ChartDonutComponent implements OnInit {

  @Input('dataDonutValues') dataDonutValues?: any


  // @Input('dataChart') dataChart?: any = [];
  // @Input('dataXCategoriesChart') dataXCategoriesChart?: any = [];
  // @Input('themeColors') themeColors?: any = [];
  // @Input('distributedBar') distributedBar?: boolean = false;
  // @Input('showLegend') showLegend?: boolean = false;

  // DonutChart: Partial<ChartOptions>;

  // Donut chart configuration Starts
  DonutChart: Chart = {
    type: 'Pie',
    data: data,
    options: {
      donut: true,
      startAngle: 0,
      labelInterpolationFnc: function (value) {
        return 0 + '%';
      }
    },
    events: {
      draw(data: any): void {
        if (data.type === 'label') {
          if (data.index === 0) {
            data.element.attr({
              dx: data.element.root().width() / 2,
              dy: data.element.root().height() / 2
            });
          } else {
            data.element.remove();
          }
        }

      }
    }
  };
  // Donut chart configuration Ends

  constructor() {

    this.DonutChart = {
      type: 'Pie',
      data: this.dataDonutValues,
      options: {
        donut: true,
        startAngle: 0,
        labelInterpolationFnc: function (value) {
          return percent + '%';
        }
      },
      events: {
        draw(data: any): void {
          if (data.type === 'label') {
            if (data.index === 0) {
              data.element.attr({
                dx: data.element.root().width() / 2,
                dy: data.element.root().height() / 2
              });
            } else {
              data.element.remove();
            }
          }

        }
      }
    };
  }

  ngOnInit(): void {
    console.log('iniciando graficos');
    this.updateSeriesChart();
    percent = parseInt(this.dataDonutValues.series[0].value)
  }

  private updateSeriesChart(): any {
    const seriesData = [
      {
        name: 'done',
        className: 'ct-done',
        value: 0,
      },
      {
        name: 'progress',
        className: 'ct-progress',
        value: 100,
      },
    ];
    return this.DonutChart.data = this.dataDonutValues;
  }
}


