import { HttpClient } from '@angular/common/http';
import { not } from '@angular/compiler/src/output/output_ast';
import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
// import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { id } from '@swimlane/ngx-charts';
import { GroupService } from 'app/pages/full-pages/imports-api-group/GroupService/group.service';
import { GroupUser } from 'app/pages/full-pages/imports-api-group/Model/GroupUser';
import { MyGroup } from 'app/pages/full-pages/imports-api-group/Model/MyGroup';
import { UserCard } from 'app/pages/full-pages/imports-api-group/Model/UserCard';
import { StorageUtils } from 'app/utils/storage-utils';
import { Group } from 'ng2-dragula';
import { email } from 'ngx-custom-validators/src/app/email/validator';
import Swal from 'sweetalert2';
// import Swal, { SweetAlertOptions } from 'sweetalert2';
import swal, { SweetAlertOptions } from 'sweetalert2';


@Component({
  selector: 'app-add-new-participant-group',
  templateUrl: './add-new-participant-group.component.html',
  styleUrls: ['./add-new-participant-group.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddNewParticipantGroupComponent implements OnInit {

  @Output('eventChange') eventChange?= new EventEmitter();

  private _listParticipantsToAdd = [];


  private _emailParticipantValue: any = '';

  private _showAddUSers: boolean = true;
  private _showInviteEmailBox: boolean = false;

  private _showParticipantListItems: any = [];

  private _showModalMemberNotFound: boolean = false;

  form: FormGroup;

  items = [];
  listGroupAdd: MyGroup = new MyGroup
  searchResp: UserCard[]
  group: MyGroup = new MyGroup;
  groupAdd: MyGroup = new MyGroup;

  groupUserList : GroupUser[]


  newInvitesList = [];
  userSearch: UserCard;
  myGroup: MyGroup = new MyGroup;
  groupUser: GroupUser = new GroupUser;
  groupsList = [];

  constructor(
    private http: HttpClient,
    private storage: StorageUtils,
    private groupService: GroupService,
    private cdRef: ChangeDetectorRef,
    // public activeModal: NgbActiveModal,
    private _router: Router) {
    this.form = new FormBuilder().group({
      chips: [['chip'], []]
    });
  }

  ngOnInit(): void {
    // this._listParticipantsToAdd.push({});
    this.loadData();
  }

  get showModalNotFound(): boolean {
    return this._showModalMemberNotFound;
  }

  get participantsListResult(): any {
    return this._showParticipantListItems;
  }

  get isParticipantEmailFounded(): boolean {

    return this._showParticipantListItems.length > 0;

  }

  get groupVerificationEmail(): boolean {
    return this._listParticipantsToAdd.length > 0;
  }

  get groupVerificationId(): boolean {
    return this.group.id != null;
  }
  get isShowInviteBoxUser(): boolean {
    return this._showInviteEmailBox === true;
  }

  get isShowAddUsersBox(): boolean {
    return this._showAddUSers === true;
  }

  get mockAnyParticipantsSelect(): any {
    return this._listParticipantsToAdd;
  }

  get hasAnyParticipantAdded(): boolean {
    return this._listParticipantsToAdd.length > 0;
  }

  get emailSearchValue(): any {
    return this._emailParticipantValue;
  }

 

  public onSelect(event) {
    //part
    this.groupUser = new GroupUser();
    this.groupUser.email = event
    console.log(this.groupUser);
    
    this.newInvitesList.push(event)
    console.log(this.newInvitesList);
    console.log(event);
    if (this.group.participants == null) {
      this.group.participants = [];
    }
    this.group.participants.push(this.groupUser)
    console.log(this.group);
    
    
  }
  
  // public log(){
  //   console.log(this.newInvitesList);
  //   console.log(this.group);
  //   console.log(this.groupUser);
  // }

  public searchEmailParticipant(event) {

    const currentEmailToSearch = event.target.value;
    this._emailParticipantValue = currentEmailToSearch;
    console.log(event);



    console.log(currentEmailToSearch);
    console.log(this._emailParticipantValue);

    if (currentEmailToSearch.length > 5) {
      this._callAPISearch(currentEmailToSearch);
    } else {
      this._showModalMemberNotFound = true;
      return;
    }
  }

  public addEmailsToInvite(event) {

    console.log(event);

    // if(eventTag.length > 0){
    //   setTimeout(()=>{
    //     this._callAPISearch(eventTag);
    //   },3000)
    // }

  }


  public getGroupId(group) {
    this.group.id = group.id;
    console.log(group);
  }

  public submitMemberToGroup(): any {
    console.log(this.group);

    this.groupService.addUser((this.group), (resp, result) => {
      console.log(this.group);

      console.log(resp);
      console.log(result);
    });

    return this.eventChange.emit(true);
  }

  public deleteMemberItem(index: number) {
    console.log('acao para delete: ', index);
    this._listParticipantsToAdd.splice(index, 1);
    this.group.participants.splice(index, 1);
    // this.listGroupAdd.splice(index, 1);


  }

  // METODO ADD ORIGINAL

  public addMember(UserName, UserEmail, UserAvatar, UserId) {
    console.log(UserEmail);
    console.log(UserId);
    this.groupUser = new GroupUser();
    this.groupUser.name = UserName
    this.groupUser.email = UserEmail
    this.groupUser.idUser = UserId

    if (this.group.id != null) {

      if (this.group.participants == null) {
        this.group.participants = [];
      }
      
      this.group.participants.push(this.groupUser);
      console.log(this.group);


      this._listParticipantsToAdd.push(this.groupUser);
      this.items.push(this.groupUser);
      console.log(this._listParticipantsToAdd);

      this._emailParticipantValue = '';

    } else {
      const options: SweetAlertOptions = {
        title: '',
        text: 'Selecione um grupo antes de adicionar um participante.',
        confirmButtonText: 'Ok',
        customClass: {
          container: 'swal2-container-custom',

        },

      }
      Swal.fire(options);
    }

  }


  public loadData(): any {
    this.groupService.listByUser(this.storage.getIdUser(), (response, resp) => {
      this.groupsList = resp.list
      this.cdRef.detectChanges();
      console.log(this.groupsList)

    })
  }

  public showConfirmAddParticipant(): any {

  }

  public showBoxInvite(): any {
    console.log("12 + ");
    
    // this._emailParticipantValue = '';
    this._showAddUSers = false;
    this._showInviteEmailBox = true;
    console.log(this._emailParticipantValue);
  }

  public showAddUser(): any {
    this._emailParticipantValue = '';
    this._showAddUSers = true;
    this._showInviteEmailBox = false;
    console.log(this._showAddUSers);
    console.log("teste botao de enviar");


  }

  // publish() {
  //   console.log(this.groupAdd);
  //   this.activeModal.close(this.groupAdd);
  //   console.log(this.);
  //   // this.activeModal.close(this.post);

  // }

  
  public confirmEmailInvite(): any {

    // const currentEmailToSearch = event.target.value;
    // this._emailParticipantValue = currentEmailToSearch;
    // console.log(event);
    if(this.group.participants == null){
      this.group.participants = []
      this.groupUser.email = this.newInvitesList.toString()
      this.group.participants.push(this.groupUser)
    }
    if(this.group.id != null){
    this.groupService.inviteUser((this.group), (resp, result) => {
      console.log(this.group);

      console.log(resp);
      console.log(result);
    });

    let options: SweetAlertOptions = {
      title: '',
      text: 'Convite para o usuário enviado com sucesso!',
      icon: 'success',
      confirmButtonText: 'Adicionar mais participantes',
      customClass: {
        container: 'swal2-container-custom',
        // title: 'swal-title-custom',
        // confirmButton: 'swal-actions-custom'
      },
      onClose: (() => {
        this.showAddUser();
        const obj = {
          showUser: this._showAddUSers,
          showInviteBoxEmail: this._showInviteEmailBox,
        };
        console.log('valor apos clique: ', obj);
      }),
      didDestroy: (() => {
        this.showAddUser();
        const obj = {
          showUser: this._showAddUSers,
          showInviteBoxEmail: this._showInviteEmailBox,
        };
        console.log('valor apos clique: ', obj);
      }),
    };

    swal.fire(options);

    // Swal.fire(options).then((result)=>{
    //     console.log(result);
    //     this.showAddUser();

    //     const obj = {
    //       showUser: this._showAddUSers,
    //       showInviteBoxEmail: this._showInviteEmailBox,
    //     };
    //     console.log('valor apos clique: ', obj);
    // });
  }else{
    const options: SweetAlertOptions = {
      title: '',
      text: 'Selecione um grupo antes de adicionar um participante.',
      confirmButtonText: 'Ok',
      customClass: {
        container: 'swal2-container-custom',

      },

    }
    Swal.fire(options);
  }
}

  public modalChangeVisible(event): any {
    this._emailParticipantValue = '';
    this._showModalMemberNotFound = event;

  }

  public modalEventConfirm(event): any {
    if (event) {
      this._showModalMemberNotFound = false;
      this.showBoxInvite();
    }
  }

  private _callAPISearch(emailValue) {

    console.log('email capturado para API: ', emailValue);

    if (emailValue.length <= 0) {
      console.log('email vazio');
      return;
    } else if (emailValue === null) {
      console.log('email não encontrado');
      this._showModalMemberNotFound = true;
      return;
    } else {
      this.myGroup.emailUser = emailValue
      this.groupService.byEmail(this.myGroup, (resp, result) => {
        this.searchResp = [result]
        this._showParticipantListItems = this.searchResp;

        console.log(this.searchResp);
        console.log(this._showParticipantListItems);

      })
    }
  }
}

