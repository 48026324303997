import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OverlayModule } from '@angular/cdk/overlay';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { ClickOutsideModule } from 'ng-click-outside';

import { AutocompleteModule } from './components/autocomplete/autocomplete.module';
import { PipeModule } from 'app/shared/pipes/pipe.module';

//COMPONENTS
import { FooterComponent } from "./footer/footer.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { HorizontalMenuComponent } from './horizontal-menu/horizontal-menu.component';
import { VerticalMenuComponent } from "./vertical-menu/vertical-menu.component";
import { CustomizerComponent } from './customizer/customizer.component';
import { NotificationSidebarComponent } from './notification-sidebar/notification-sidebar.component';

//DIRECTIVES
import { ToggleFullscreenDirective } from "./directives/toggle-fullscreen.directive";
import { SidebarLinkDirective } from './directives/sidebar-link.directive';
import { SidebarDropdownDirective } from './directives/sidebar-dropdown.directive';
import { SidebarAnchorToggleDirective } from './directives/sidebar-anchor-toggle.directive';
import { SidebarDirective } from './directives/sidebar.directive';
import { TopMenuDirective } from './directives/topmenu.directive';
import { TopMenuLinkDirective } from './directives/topmenu-link.directive';
import { TopMenuDropdownDirective } from './directives/topmenu-dropdown.directive';
import { TopMenuAnchorToggleDirective } from './directives/topmenu-anchor-toggle.directive';
import { NotificationsCommunityComponent } from './custom/notifications-community/notifications-community.component';
import { PostVideoCommunityComponent } from './custom/post-video-community/post-video-community.component';
import { UploadImportsPageComponent } from './custom/upload-imports-page/upload-imports-page.component';
import { FileUploadModule } from 'ng2-file-upload';
import { NgApexchartsModule } from 'ng-apexcharts';
import { ChartImportPageComponent } from './custom/chart-import-page/chart-import-page.component';
import { ChartistModule } from 'ng-chartist';
import { ModalDeleteParticipantGroupComponent } from './custom/modals/modal-delete-participant-group/modal-delete-participant-group.component';
import { AddNewParticipantGroupComponent } from './custom/add-new-participant-group/add-new-participant-group.component';
import { TagInputModule } from 'ngx-chips';
import { ModalMemberNotFoundComponent } from './custom/modals/modal-member-not-found/modal-member-not-found.component';
import { ModalConfirmInviteMembersComponent } from './custom/modals/modal-confirm-invite-members/modal-confirm-invite-members.component';
import { ChartDonutComponent } from './custom/chart-donut/chart-donut.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxGaugeModule } from 'ngx-gauge';
import { ModalWatchVideoComponent } from './custom/modals/modal-watch-video/modal-watch-video.component';
import { ModalShareComponent } from './custom/modals/modal-share/modal-share.component';
import { ModalFeedbackComponent } from './custom/modals/modal-feedback/modal-feedback.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { ModalConfirmCloseStudySessionComponent } from './custom/modals/modal-confirm-close-study-session/modal-confirm-close-study-session.component';
import { ModalWaitGenerateVideoStudySessionComponent } from './custom/modals/modal-wait-generate-video-study-session/modal-wait-generate-video-study-session.component';

import { EmojiModule } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { ReplayerTableModule } from './components/replayer-table/replayer-table.module';
import { ModalContentSharedComponent } from './components/modal-content-shared/modal-content-shared.component';
import { AfterIfDirective } from './directives/after-if.directive';
import { RrwebReplayerComponent } from './components/rrweb-replayer/rrweb-replayer.component';
import { PopupLanguageComponent } from './components/popup-language/popup-language.component';
import { ButtonSpinnerComponent } from './components/button-spinner/button-spinner.component';


// import { ModalConfirmInviteMembersComponent } from './modals/modal-confirm-invite-members/modal-confirm-invite-members.component';
// import { PostVideoCommunityComponent } from './shared/custom/post-video-community/post-video-community.component';
// import { NotificationsCommunityComponent } from './shared/custom/notifications-community/notifications-community.component';


@NgModule({
    exports: [
        CommonModule,
        FooterComponent,
        NavbarComponent,
        VerticalMenuComponent,
        HorizontalMenuComponent,
        CustomizerComponent,
        NotificationSidebarComponent,
        PostVideoCommunityComponent,
        NotificationsCommunityComponent,
        UploadImportsPageComponent,
        ModalDeleteParticipantGroupComponent,
        ModalMemberNotFoundComponent,
        ModalConfirmInviteMembersComponent,
        ModalWatchVideoComponent,
        ModalShareComponent,
        ModalFeedbackComponent,
        ModalConfirmCloseStudySessionComponent,
        ModalWaitGenerateVideoStudySessionComponent,
        AddNewParticipantGroupComponent,
        ChartImportPageComponent,
        ChartDonutComponent,
        ToggleFullscreenDirective,
        SidebarDirective,
        TopMenuDirective,
        AfterIfDirective,
        NgbModule,
        TranslateModule,
        ReplayerTableModule,
        ModalContentSharedComponent,
        RrwebReplayerComponent,
        PopupLanguageComponent,
        ButtonSpinnerComponent,
    ],
    imports: [
        RouterModule,
        CommonModule,
        NgbModule,
        TranslateModule,
        FormsModule,
        FileUploadModule,
        OverlayModule,
        ReactiveFormsModule ,
        PerfectScrollbarModule,
        ClickOutsideModule,
        AutocompleteModule,
        PipeModule,
        NgApexchartsModule,
        ChartistModule,
        TagInputModule,
        NgxGaugeModule,
        NgxIntlTelInputModule,
        PickerModule,
        EmojiModule,
        ReplayerTableModule,
        // NgxDatatableModule,
    ],
    declarations: [
        FooterComponent,
        NavbarComponent,
        VerticalMenuComponent,
        HorizontalMenuComponent,
        CustomizerComponent,
        NotificationSidebarComponent,
        ToggleFullscreenDirective,
        SidebarLinkDirective,
        SidebarDropdownDirective,
        SidebarAnchorToggleDirective,
        SidebarDirective,
        TopMenuLinkDirective,
        TopMenuDropdownDirective,
        TopMenuAnchorToggleDirective,
        TopMenuDirective,
        AfterIfDirective,
        PostVideoCommunityComponent,
        NotificationsCommunityComponent,
        UploadImportsPageComponent,
        ChartImportPageComponent,
        ModalDeleteParticipantGroupComponent,
        AddNewParticipantGroupComponent,
        ModalMemberNotFoundComponent,
        ModalConfirmInviteMembersComponent,
        ChartDonutComponent,
        ModalWatchVideoComponent,
        ModalShareComponent,
        ModalFeedbackComponent,
        ModalConfirmCloseStudySessionComponent,
        ModalWaitGenerateVideoStudySessionComponent,
        ModalContentSharedComponent,
        RrwebReplayerComponent,
        PopupLanguageComponent,
        ButtonSpinnerComponent,
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ],
})
export class SharedModule { }
