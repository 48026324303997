import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SwalPortalTargets } from '@sweetalert2/ngx-sweetalert2';
import { PlanService } from 'app/pages/plan-api/plan-service';
import { NgxSpinnerService } from 'ngx-spinner';

import { StorageUtils } from '../../../utils/storage-utils';
import { User } from '../../msk-user/user/user';
import { UserService } from '../../msk-user/user/user-service';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
  providers: [
    UserService,
    PlanService,
    StorageUtils
  ],
})

export class LoginPageComponent {

  loginFormSubmitted = false;
  isLoginFailed = false;
  emailError = '';

  loginForm = new FormGroup({
    username: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required]),
    rememberMe: new FormControl(true),
  });
  constructor(private router: Router,

    private spinner: NgxSpinnerService,

    public readonly swalTargets: SwalPortalTargets,
    private route: ActivatedRoute,
    private userService: UserService,
    private planService: PlanService,
    private storage: StorageUtils,
    private translate: TranslateService) {

    this.route.queryParams.subscribe(params => {
      const expired = params['expired'];
      if (expired) {
        this.emailError = 'Sessão Expirada';
      }
    });
  }

  get lf() {
    return this.loginForm.controls;
  }

  // On submit button click
  public onSubmit() {

    this.loginFormSubmitted = true;
    if (this.loginForm.invalid) {
      return;
    }

    this.userService.setCurrentUser(null);
    const language = localStorage.getItem("language");
    localStorage.clear();
    localStorage.setItem("language", language)

    const userB = new User();
    userB.email = this.loginForm.value.username;
    userB.password = this.loginForm.value.password;

    this.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });

    console.log(this.spinner.show);


    this.emailError = '';
    this.userService.login(userB, (result, user) => {
      this.spinner.hide();
      if (result.success) {
        this.storage.storeToken(user.token);
        this.storage.idUser(user.id);
        this.storage.nameUser(user.name);
        if (user.info.firstLogin != null) {
          this.storage.firstLogin(user.info.firstLogin);
        } else {
          this.storage.firstLogin('false');
        }

        this.storage.urlImage(user.urlImage);
        this.storage.email(user.email);
        if (user.info.nickname != null && user.info.nickname != "") {
          console.log('setting name' + user.info.nickname);
          this.storage.storeUserNickName(user.info.nickname);
        } else {
          console.log('setting name');
          var nameSplited = user.name.split(" ", 2);
          this.storage.storeUserNickName(nameSplited[0]);
        }


        if (this.loginForm.value.rememberMe) {
          this.storage.storeAutoLogin(user);
        }
        this.userService.setCurrentUser(user);
        this.planService.searchPlan(user.info.plan, (result, plan) => {
          console.log(plan);
          this.storage.storeUserPlan(plan);
        });

        this.router.navigate(['pages/home']);
      } else {
        this.isLoginFailed = true;
        this.translate.get(result.desc).subscribe((res: string) => {
          this.emailError = res;
        });
      }
    });
  }
}
