import { Component, Input, OnInit } from '@angular/core';
import * as Chartist from 'chartist';
import { ChartType, ChartEvent } from "ng-chartist";
import ChartistTooltip from 'chartist-plugin-tooltips-updated';

import {
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexYAxis,
  ApexGrid,
  ApexDataLabels,
  ApexStroke,
  ApexTitleSubtitle,
  ApexTooltip,
  ApexLegend,
  ApexPlotOptions,
  ApexFill,
  ApexMarkers,
  ApexTheme,
  ApexNonAxisChartSeries,
  ApexResponsive
} from "ng-apexcharts";

// apex-chart
export type ChartOptions = {
  series: ApexAxisChartSeries | ApexNonAxisChartSeries;
  colors: string[],
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis | ApexYAxis[],
  title: ApexTitleSubtitle;
  dataLabels: ApexDataLabels,
  stroke: ApexStroke,
  grid: ApexGrid,
  legend?: ApexLegend,
  tooltip?: ApexTooltip,
  plotOptions?: ApexPlotOptions,
  labels?: string[],
  fill: ApexFill,
  markers?: ApexMarkers,
  theme: ApexTheme,
  responsive: ApexResponsive[]
};

//Interface
export interface Chart {
  type: ChartType;
  data: Chartist.IChartistData;
  options?: any;
  responsiveOptions?: any;
  events?: ChartEvent;
  plugins?: any;
}

const data = {
  "Bar": {
    "labels": [
      'GGPoker', 'PartyPoker', '888Poker', 'Badog', 'PokeStars'
    ],
    "series": [
      50,
      70,
      120,
      200,
      180,
    ]
  },
};

var $ggPoker = "#891B1D",
  $partyPoker = "#FDB813",
  $eightPoker = "#1D291F",
  $badog = "#E84407",
  $pokeStars = "#56915D",
  $label_color_light = "#E6EAEE";

var themeColors = [$ggPoker, $partyPoker, $eightPoker, $badog, $pokeStars];

//Declarations
// declare var require: any;
// const data: any = require('../../../shared/data/chartjs.json');

@Component({
  selector: 'app-chart-import-page',
  templateUrl: './chart-import-page.component.html',
  styleUrls: ['./chart-import-page.component.scss']
})

export class ChartImportPageComponent implements OnInit {

  @Input('dataChart') dataChart?: any = [];
  @Input('dataXCategoriesChart') dataXCategoriesChart?: any = [];
  @Input('themeColors') themeColors?: any = [];
  @Input('distributedBar') distributedBar?: boolean = false;
  @Input('showLegend') showLegend?: boolean = false;



  columnChartOptions: Partial<ChartOptions>;

  // Bar Chart Starts
  barChart: Chart = {
    type: 'Bar',
    data: data['Bar'],
    options: {
      height: 400,
      distributeSeries: true,
      // width: '50%',
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)'
    ],
      seriesBarDistance: 21,
      axisX: {
        showGrid: false, offset: 100
      },
      axisY: {
        scaleMinSpace: 30,
      },
      events: {
        created(data: any): void {
          var defs = data.svg.elem('defs');
          defs.elem('linearGradient', {
            id: 'gradient4',
            x1: 0,
            y1: 1,
            x2: 0,
            y2: 0
          }).elem('stop', {
            offset: 0,
            'stop-color': '#000'
          }).parent().elem('stop', {
            offset: 1,
            'stop-color': '#000'
          });
          defs.elem('linearGradient', {
            id: 'gradient5',
            x1: 0,
            y1: 1,
            x2: 0,
            y2: 0
          }).elem('stop', {
            offset: 0,
            'stop-color': '#1750A5'
          }).parent().elem('stop', {
            offset: 1,
            'stop-color': '#40C057'
          });

          defs.elem('linearGradient', {
            id: 'gradient6',
            x1: 0,
            y1: 1,
            x2: 0,
            y2: 0
          }).elem('stop', {
            offset: 0,
            'stop-color': '#3B1C93'
          }).parent().elem('stop', {
            offset: 1,
            'stop-color': '#60AFF0'
          });
          defs.elem('linearGradient', {
            id: 'gradient7',
            x1: 0,
            y1: 1,
            x2: 0,
            y2: 0
          }).elem('stop', {
            offset: 0,
            'stop-color': '#562DB7'
          }).parent().elem('stop', {
            offset: 1,
            'stop-color': '#F55252'
          });

        },
        draw(data: any): void {
          var barHorizontalCenter, barVerticalCenter, label, value;
          if (data.type === 'bar') {

            data.element.attr({
              y1: 195,
              x1: data.x1 + 0.001
            });

          }
        }
      },
      plugins: [
        ChartistTooltip({
          appendToBody: true,
          currency: '',
          pointClass: 'ct-point-circle'
        })
      ],
    },
  };

  constructor() {

    this.columnChartOptions = { // this setup for use
      chart: {
        height: 450,
        type: 'bar',
        fontFamily: 'Montserrat, Arial, sans-serif',
      },
      colors: this.themeColors,
      // theme: {
      //   palette: 'palette1' // upto palette10
      // },
      plotOptions: {
        bar: {
          horizontal: false,
          endingShape: 'flat',
          columnWidth: '25%',
          distributed: this.distributedBar,
          // colors: {backgroundBarColors: ['#000CCC', '#000', '#000CCC', '#000CCC', '#000CCC',]}
          // colors: {backgroundBarColors: ['#FFF','#000', '#CCC', '#555FFDD', '#555FFDD']}
        },
      },
      dataLabels: {
        enabled: false,
        style: {
          fontWeight: 'bold',
          fontFamily: 'Montserrat, Arial, sans-serif',
          // fontSize: '',
        }
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      markers: {
        strokeColors: '#fff',
      },
      // grid: {
      //   row: {
      //     colors: ['#F44336', '#E91E63', '#9C27B0']
      //   },
      //   column: {
      //     colors: ['#F44336', '#E91E63', '#9C27B0']
      //   }
      // },
      series: this.dataChart,
      legend: {
        offsetY: 5,
        show: false,
        showForNullSeries: false,
        fontSize: '14px',
        fontWeight: 500,
        fontFamily: 'Montserrat, Arial, sans-serif',
        // width: 30,
        // height: 20,
        itemMargin: {
          // horizontal: 15,
          // vertical: 100
        },
        markers: {
          width: 10,
          height: 10,
          radius: 100,
        }
      },
      xaxis: {
        categories: this.dataXCategoriesChart,
      },
      yaxis: {
        title: {
          text: '',
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        x:{
          show: false,
        },
        y: {
          formatter: function (val) {
            return '' + val;
          }
        },
        items: {
          // display: 'block',
       },
       marker: {
        show: false,
       },
        fixed: {
          enabled: false,
          position: 'topRight',
          // offsetX: -100,
          // offsetY: -50,
      },
      }
    }

  }

  ngOnInit(): void {
    // console.log(this.barChart);
    console.log('iniciando graficos');
    this.updateSeriesChart();
    this.getXCategoriesChart();
    this._getThemeColors();
    this._isDistributedBars();
    this._showLegend();
  }

  private _showLegend(): boolean {
    return this.columnChartOptions.legend.show = this.showLegend;
  }

  private _isDistributedBars(): boolean {
    return this.columnChartOptions.plotOptions.bar.distributed = this.distributedBar;
  }

  private _getThemeColors(): any {
    return this.columnChartOptions.colors = this.themeColors;
  }

  private updateSeriesChart(): any {

    const seriesData = [
      {
        name: '',
        data: [29, 89, 135, 200, 300],
      },
      // {
      //   name: 'Revenue',
      //   data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
      // },
    ];
    return this.columnChartOptions.series = this.dataChart;
  }

  private getXCategoriesChart(): any {
    // const categories = ['GGPoker', 'PartyPoker', '888Poker', 'Badog', 'PokeStars'];
    // this.dataXCategoriesChart = categories;
    return this.columnChartOptions.xaxis.categories = this.dataXCategoriesChart;
  }

}
