import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { StudyService } from 'app/pages/study-api/study-service/study-service';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { StudySession } from 'app/pages/study-api/study-model/StudySession';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-modal-confirm-close-study-session',
  templateUrl: './modal-confirm-close-study-session.component.html',
  styleUrls: ['./modal-confirm-close-study-session.component.scss'],
  providers: [StudyService],
})
export class ModalConfirmCloseStudySessionComponent implements OnInit, OnChanges {

  @Input('visible') visible: boolean;
  @Output('visibleChange') visibleChange: any = new EventEmitter();
  @Output('confirmModalEvent') confirmModalEvent?: any = new EventEmitter();
  @Input('studyId') studyId: string | null = null;

  private readonly _HTML_BODY = document.querySelector("body");

  constructor(private translateService: TranslateService, private cdr: ChangeDetectorRef, private studyService: StudyService,private _deviceDetectorService: DeviceDetectorService, private router: Router) { }

  ngOnInit(): void {
    this.visible = false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.visible?.currentValue) {
      this.visible = changes?.visible?.currentValue;
      this.cdr.detectChanges();
    }
  }

  get isVisible(): boolean {
    this._enableCustomClass();
    return this.visible === true;
  }

  public closeModal(): any {
    this.visible = false;
    this._HTML_BODY.classList.remove('custom-z-index-modal');
    this.visibleChange.emit(this.visible);
  }

  public confirmOptionModal(user): any {

      var listSession = new StudySession();
      listSession.id = this.studyId
      this.studyService.closeSessionQueue(listSession, async (response, resp) => {
        const optionsFound: SweetAlertOptions = {
          title: this.translateService.instant("modal.encerramento_estudo_solicitado_com_sucesso"),
          text: this.translateService.instant("modal.esse_processo_pode_demorar_um_pouco_siga_navegando_na_plataforma_normalmente"),
          icon: 'success',
          customClass: {
            container: 'swal2-container-custom',
          },
        };
        Swal.fire(optionsFound).then(() => {
          this.router.navigateByUrl("/pages/library");
        });
        setTimeout(() => {
          this.router.navigateByUrl("/pages/library");
        }, 200);
      });
  }

  private _enableCustomClass(): any {

    if (this.visible && this.visible === true) {
      this._scrollBotoomOnlyMobile();
      this._HTML_BODY.classList.add('custom-z-index-modal');
    } else {
      this._HTML_BODY.classList.remove('custom-z-index-modal');
    }
  }

  private _scrollBotoomOnlyMobile() {
    const userAgentIphone = window.navigator.userAgent.includes('Mac');
    if (this._deviceDetectorService.isMobile() && userAgentIphone) {
      this._HTML_BODY.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }
  }


}
