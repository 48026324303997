import { environment } from "environments/environment";


export class Constants {

  public static readonly version = require('../../../package.json').version;

  public static readonly DATE_FMT = 'dd/MM/yyyy';
  public static readonly DATE_TIME_FMT = `${Constants.DATE_FMT} hh:mm:ss`;

  // Local
  public static get SERVICE_URL(): string {
      return  environment.SERVICE_URL;

   };

  public static get WS_URL(): string {
      return environment.WS_URL;
   };


  public static get SERVICE_PROJECT(): string {
    return environment.SERVICE_PROJECT;
  };

  public static get SALES_PROJECT(): string {
    return environment.SALES_PROJECT;
  };

  public static get VIDEO_PT_URL(): string {
     return environment.VIDEO_PT_URL;

  };


}
