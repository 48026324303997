<div class="post-video-community" *ngFor="let postItem of postList; let i = index">
  <div class="post-video-community" *ngIf="filterPostByDate(postItem.creationDate)">
    <div class="post-header">
      <span>{{hasTitle ? postItem.title : 'Sem Título'}}</span>
    </div>

    <div *ngIf="postList[i].type == 'VIDEO'" class="post-media" [style.background-image]="'url(' + postList[i].thumbUrl + ')'">
      <!-- <video controls="controls" width="100%" height="100%" preload="metadata" poster="{{videoThumb}}" crossorigin> -->
      <video (play)="expandedVideo($event)" *ngIf="postList[i].urlVideo != null && postList[i].type == 'VIDEO'"  preload="metadata" poster="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" controls controlsList="nodownload" width="100%"
        height="100%" >
        <source src="{{postList[i].urlVideo}}" title="{{postItem.urlVideo}}" type="video/mp4">
        Seu navegador não suporta arquivos de vídeo.
      </video>
    </div>
    <div *ngIf="postList[i].type == 'STUDY'" (after-if)="eventVideo(postList[i])">
      <app-rrweb-replayer class="shrink" [events]="events"></app-rrweb-replayer>
    </div>




    <div class="post-details">
      <div class="content">

        <div class="content-left-post">

          <div class="avatar-post" [style.background-image]="'url(' + postItem.userCreator?.urlImage + ')'"></div>

          <div class="description">

            <span class="name d-flex">
              {{postItem?.userCreator?.name}}
              <span class="country ml-2"><img src="{{postItem?.userCreator?.cpf}}" width="20px" alt="PT"> </span>
            </span>

            <span class="views hide-mobile">
             {{ 'homepage-timeline.postado_a' | translate }} {{calDate(postItem)}}
            </span>

            <span class="views hide-desktop">
              {{ 'homepage-timeline.postado_a' | translate }} <br> {{calDate(postItem)}}
            </span>
          </div>

        </div>

        <div class="content-right-post">

          <div class="shared-and-liked-posts">

            <div class="liked" *ngIf="postItem.fgLiked == true">

              <span class="liked-icon">
                <input class="button-like" type="button" (click)="dislikePost(postItem, $event.target.checked)"></span>
              <span class="total-liked-number">{{postItem.likes}}</span>

            </div>

            <div class="liked" *ngIf="postItem.fgLiked == false">

              <span class="liked-icon">
                <input class="button-dislike" type="button" (click)="likePost(postItem, $event.target.checked)"></span>
              <span class="total-liked-number">{{postItem.likes}}</span>

            </div>

          </div>

        </div>

      </div>
    </div>

    <div class="px-3">
      <hr>
    </div>

    <div class="post-comments">

      <div class="post-item">
        <ul class="list-group">
          <li class="list-group-item" *ngFor="let currentComment of postItem.lastComments;let i = index">




            <div class="post-item">
              <div class="avatar-post" [style.background-image]="'url(' + currentComment.info?.urlImage + ')'"></div>
              <div class="comment">
                <div class="data-user">
                  <span class="name">{{currentComment.nameUser}}</span>
                  <span class="country"><img src="{{currentComment.info?.countryFlag}}"> </span>
                </div>
                <p [innerHtml]="currentComment.text">
                  {{currentComment.text}}
                </p>
              </div>

              <div ngbDropdown placement="bottom-right" *ngIf="currentComment.idUser ==  userID">

                <div class="dropdown-arrow-desc" id="dropdownBasic1" ngbDropdownToggle>
                  <img src="/assets/img/icons/three-dots-vertical.svg" alt="" srcset="">
                </div>

                <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                  <button class="dropdown-item" (click)="deleteComment(currentComment)"><i class="fa fa-trash"></i>
                    Excluir
                  </button>
                </div>
              </div>

            </div>

            <div class="reply-link" (click)="showOrHideReply() "><span></span></div>

            <!--<div class="sub-comments" *ngIf="hasReplyActive">

              <ul class="list-group">

                <li class="list-group-item" *ngFor="let currentReply of currentPost.reply">

                  <div class="post-item reply-item">
                    <div class="avatar-post" [style.background-image]="'url(' + currentReply.user.avatar + ')'">
                      <ng-container *ngIf="!currentReply.user.avatar || currentReply.user.avatar.length <= 0">
                        <h3>G</h3>
                      </ng-container>
                    </div>
                    <div class="comment">
                      <div class="data-user">
                        <span class="name">{{currentReply.user.name}}</span>
                        <span class="country" *ngIf="currentReply.user.country === 'PT'"><img src="../../../../assets/img/flags/pt.png" alt="PT"> Portugal </span>
                        <span class="country" *ngIf="currentReply.user.country === 'USA'"><img src="../../../../assets/img/flags/us.png" alt="USA"> Estados Unidos</span>
                        <span class="country" *ngIf="currentReply.user.country === 'ESP'"><img src="../../../../assets/img/flags/es.png" alt="ESP"> Espanha </span>
                        <span class="country" *ngIf="currentReply.user.country === 'GER'"><img src="../../../../assets/img/flags/de.png" alt="DE"> Alemanha </span>
                      </div>
                      <p>
                        {{currentReply.comment}}
                      </p>

                    </div>
                  </div>
                </li>

              </ul>

              <div class="post-input-container">
                <div class="avatar-post" [style.background-image]="'url(' + currentUserImage + ')'">
                  <ng-container *ngIf="!currentUserImage">
                    <h3>G</h3>
                  </ng-container>
                </div>

                <div class="chat-input">
                  <form class="form">
                    <div class="form-group" contentEditable>
                      <textarea name="" id="" cols="10" rows="1" class="form-control form-control-comment" placeholder="Escreva um comentário"></textarea>

                    </div>
                  </form>
                </div>

                <button class="btn btn-send-post"><i class="fa fa-paper-plane"></i></button>

              </div>

            </div>-->
          </li>

        </ul>
      </div>
    </div>

    <div class="post-input-container">
      <!-- <div class="avatar-post" [style.background-image]="'url(' + currentUserImage + ')'">
      <ng-container *ngIf="!currentUserImage">
        <h3>G</h3>
      </ng-container>
    </div> -->

      <div class="chat-input">
        <!-- <textarea name="" id="" cols="10" rows="1" class="form-control form-control-comment" placeholder="Escreva um comentário"></textarea> -->
        <input type="text" name="iconLeft4" class="form-control form-control-comment" id="iconLeft4"
          placeholder="{{ 'homepage-timeline.escreva_seu_comentario' | translate }}" [(ngModel)]="newMessage" #it="ngModel" >

        <!-- <ng-container class="send-message">
        <textarea rows="4" name="iconLeft4" class="form-control chat-message-send"
          id="iconLeft4" placeholder="Escreva seu comentário" (keydown.enter)="onAddMessage(); $event.preventDefault()"
          [(ngModel)]="newMessage" #it="ngModel">
          </textarea>
        <button type="button" class="btn btn-custom btn-send-message d-lg-flex align-items-center send-message-absolute"
          (click)="onAddMessage()">
          <i class="fas fa-paper-plane"></i>
          <span class="d-none d-lg-block ml-1">Send</span>
        </button>
      </ng-container> -->

        <!-- <button type="button" class="btn btn-custom btn-send-audio mr-1">
        <i class="fas fa-microphone"></i>
      </button> -->
      </div>
      <button type="button" class="btn btn-send-post" (click)="onAddMessage(postItem)">
        <i class="fas fa-paper-plane"></i>
      </button>
    </div>

  </div>
</div>


<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Seja Bem Vindo</h4>
  </div>
  <div class="modal-body">
    <video id="video_pt" width="100%" height="100%" controls="false" autoplay style="margin:10px 0">
      <source src="{{videoSrc}}" poster="assets/imagavatares/.png" type="video/mp4">
    </video>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
  </div>
</ng-template>


<button #buttonVideo class="btn btn-outline-primary mb-2 me-2" (click)="openVerticallyCentered(content)">

</button>
