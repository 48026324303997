import { Injectable } from '@angular/core';
import { Plan } from 'app/pages/plan-api/plan-model';
import { User } from '../pages/msk-user/user/user';

@Injectable({
  providedIn: 'root'
})
export class StorageUtils {

  public clear(){
    localStorage.clear;
  }

  public storeToken(token: string) {
    localStorage.setItem('token', token);
  }

  public getToken() {
    return localStorage.token;
  }

  public idUser(idUser: string) {
    localStorage.setItem('idUser', idUser);
  }

  public getIdUser() {
    return localStorage.idUser;
  }

  public nameUser(nameUser: string) {
    localStorage.setItem('nameUser', nameUser);
  }

  public firstLogin(firstLogin: string) {
    localStorage.setItem('firstLogin', firstLogin);
  }


  public getNameUser() {
    return localStorage.nameUser;
  }

  public idWorkspace(idWorkspace: string) {
    localStorage.setItem('idWorkspace', idWorkspace);
  }

  public getIdWorkspace() {
    return localStorage.idWorkspace;
  }

  public getFirstLogin() {
    return localStorage.firstLogin;
  }

  public urlHome(urlHome: string) {
    localStorage.setItem('urlHome', urlHome);
  }

  public getUrlHome() {
    return localStorage.urlHome;
  }

  public idEvent(idEvent: string) {
    localStorage.setItem('idEvent', idEvent);
  }

  public getIdEvent() {
    return localStorage.idEvent;
  }

  public idCompany(idCompany: string) {
    localStorage.setItem('idCompany', idCompany);
  }

  public getIdCompany() {
    return localStorage.idCompany;
  }

  public roleId(roleId: string) {
    localStorage.setItem('roleId', roleId);
  }

  public getRoleId() {
    return localStorage.roleId;
  }

  public urlImage(urlImage: string) {
    localStorage.setItem('urlImage', urlImage);
  }

  public getUrlImage() {
    return localStorage.urlImage;
  }

  public email(email: string) {
    localStorage.setItem('email', email);
  }

  public getEmail() {
    return localStorage.email;
  }


  public storeAutoLogin(user: User) {
    if (user == null) {
      localStorage.setItem('autoLogin', null);
    }
    else {
      localStorage.setItem('autoLogin', JSON.stringify(user));
    }
  }

  public getAutoLogin(): User {
    if (localStorage.autoLogin == null) {
      return null;
    }
    return JSON.parse(localStorage.autoLogin);
  }

  public storeUserPlan(userPlan: Plan) {
    localStorage.setItem('userPlan', JSON.stringify(userPlan));
  }

  public getUserPlan() {

    if (localStorage.userPlan == null) {
      return null;
    }
    return JSON.parse(localStorage.userPlan);
  }

  public storeUserNickName(userNickName: string) {
    localStorage.setItem('userNickName', userNickName);
  }

  public getUserNickName() {
    return localStorage.userNickName;
  }
}
