import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { SectionStudyService } from 'app/pages/full-pages/section-study/section-study.service';

import { HandleHistory, Player, Round } from '../types/desk-type';

@Component({
  selector: "app-controllers",
  templateUrl: "./controllers.component.html",
  styleUrls: ["./controllers.component.scss"],
})
export class ControllersComponent implements OnChanges {
  @Input() handleHistory: HandleHistory[] = [];
  @Input() studyTitle: string = "";
  @Input() showCloseStudySession: boolean = false;
  @Output() buttonSkip: EventEmitter<"skipNext" | "skipPrev"> = new EventEmitter();
  @Output() modalChangeVisible: EventEmitter<"confirm" | "wait"> = new EventEmitter();
  decimalPlaces: number = 2;

  community_cards: any = document.getElementsByClassName("flip-cardDesk");
  //Player box
  player_box: any = document.getElementsByClassName("player");
  //Mão do player
  player_hand: any = document.getElementsByClassName("player-hand");
  //Area de ações do player
  area_item: any = document.getElementsByClassName("area-item");
  //Usado para numerar os blinds
  blind_number: any = document.getElementsByClassName("blind-number");

  //Round Index Init - Usado geralmente quando não se passa um parametro Round Control na função
  indexRound: any = -1;

  //Round Control - Usado como parametro Round Controll em Play, Pause, Previous e Next
  rControl = -1;

  //Array do replay
  currentArray = 0;

  /*== ACTION TABLE end ==*/

  play: any = document.getElementById("play"); // Botão play
  pause: any = document.getElementById("pause"); // Botão pause
  next: any = document.getElementById("next"); // Botão next
  prev: any = document.getElementById("prev"); // Botão prev

  stats = 0;
  playStart = 0;
  handleHistoryCurrent: HandleHistory = {} as HandleHistory;
  round: any = [];
  roundRevisao: Round = {} as Round;

  constructor(private cdRef: ChangeDetectorRef, private sectionStudyService: SectionStudyService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.handleHistory) {
      if (this.handleHistory.length > 0) {
        this.reloadProps();
      }
    }
  }

  activeMenu(tab: string) {
    var navbar = document.getElementById("section-study-navbar");
    navbar.classList?.add("active");
    var navbarCustom = document.getElementById("navbar-custom");
    navbarCustom.style.display = "none";
    if (tab == "tab-chat") {
      navbar.setAttribute("menu-active", "2")
      this.sectionStudyService.ngbNavItem = 2;
    }
    if (tab == "tab-annotation") {
      navbar.setAttribute("menu-active", "1")
      this.sectionStudyService.ngbNavItem = 1;
    }
  }

  reloadProps() {
    this.community_cards = document.getElementsByClassName("flip-cardDesk");
    this.player_box = document.getElementsByClassName("player");
    this.player_hand = document.getElementsByClassName("player-hand");
    this.area_item = document.getElementsByClassName("area-item");
    this.blind_number = document.getElementsByClassName("blind-number");
    this.play = document.getElementById("play"); // Botão play
    this.pause = document.getElementById("pause"); // Botão pause
    this.next = document.getElementById("next"); // Botão next
    this.prev = document.getElementById("prev"); // Botão prev
    // console.log(this.player_box = document.getElementsByClassName('player'))
    this.prev.disabled = true;
    this.next.disabled = false;
    this.play.disabled = false;
    document.getElementById("todas-revisoes")!.innerHTML = String(
      this.handleHistory[this.currentArray].rounds.length + 1
    );
    document.getElementById("revisao-atual")!.innerHTML = "0";
    this.indexRound = -1;
    this.rControl = -1;
    this.stats = 0;
    this.playStart = 0;
    let indexCalculate = this.handleHistory[this.currentArray].rounds.findIndex(round => round.action == "CALCULATE");
    switch (this.handleHistory[this.currentArray].rounds[indexCalculate - 1].step) {
      case "RIVER":
        this.handleHistory[this.currentArray].rounds[indexCalculate].step = this.handleHistory[this.currentArray].rounds[indexCalculate - 1].step;
        this.handleHistory[this.currentArray].rounds[indexCalculate].stepOld = "CALCULATE";
        break;
      case "FLOP":
        this.handleHistory[this.currentArray].rounds[indexCalculate].step = this.handleHistory[this.currentArray].rounds[indexCalculate - 1].step;
        this.handleHistory[this.currentArray].rounds[indexCalculate].stepOld = "CALCULATE";
        break;
      case "TURN":
        this.handleHistory[this.currentArray].rounds[indexCalculate].step = this.handleHistory[this.currentArray].rounds[indexCalculate - 1].step;
        this.handleHistory[this.currentArray].rounds[indexCalculate].stepOld = "CALCULATE";
        break;
      default:
        this.handleHistory[this.currentArray].rounds[indexCalculate].step = this.handleHistory[this.currentArray].rounds[indexCalculate - 1].step;
        this.handleHistory[this.currentArray].rounds[indexCalculate].stepOld = this.handleHistory[this.currentArray].rounds[indexCalculate - 1].step
        break;
    }
    this.handleHistory[this.currentArray].rounds.map(round => {
      round.total_pot = null;
    })
    this.round = this.handleHistory[this.currentArray].rounds[0];
    this.roundRevisao = this.handleHistory[this.currentArray].rounds[0];
    this.handleHistoryCurrent = this.deepCopy(this.handleHistory[this.currentArray]);
    this.sectionStudyService.round$.next(this.handleHistory[this.currentArray].rounds[0]);
    console.log(this.handleHistory[this.currentArray]);
    this.cdRef.detectChanges();
    if (this.handleHistory[this.currentArray].platform == "GG_NETWORK") {
      this.decimalPlaces = 3;
    } else {
      this.decimalPlaces = 2;
    }
  }

  cleanAreaItem() {
    let areaItemClone = document.querySelectorAll('.area-item-clone');
    let elementsItem: string[] = [];

    let areaItemCloneLength = areaItemClone.length;
    for (let index = 0; index < areaItemCloneLength; index++) {
      const elem = areaItemClone[index] as HTMLDivElement;
      let totalChipsValue = elem.querySelectorAll(".total-chips-value")[0];
      let item: string = totalChipsValue.getAttribute("data-value") + elem.getAttribute("data-player") + elem.getAttribute("data-step") + elem.style.opacity;
      if (elementsItem.includes(item)) {
        elem.remove();
      } else {
        elementsItem.push(item);
      }
    }
  }

  waitforme(ms: number) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve("");
      }, ms);
    });
  }

  waitformeClick() {
    this.prev!.disabled = true;
    this.next!.disabled = true;
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve("");
        this.prev!.disabled = false;
        this.next!.disabled = false;
      }, 400);
    });
  }

  pauser(pause: any = this.pause, play: any = this.play, stats: any = this.stats) {
    return new Promise((resolve) => {
      let playbuttonclick = function () {
        pause.removeAttribute("disabled");
        play.setAttribute("disabled", "true");
        play.removeEventListener("click", playbuttonclick);
        stats = 0;
        resolve("resolved");
      };
      this.stats = 0;
      this.play.addEventListener("click", playbuttonclick);
    });
  }

  /*== CONTROLLERS ==*/
  onClickPause() {
    this.stats = 1;
    this.play.style.display = "unset";
    this.pause.style.display = "none";
    this.pause.setAttribute("disabled", "true");
    this.play.disabled = false;
  }
  async onClickNext() {
    await this.waitformeClick();
    console.log("Next Click:" + this.indexRound);
    this.actionNext();
  }
  async onclickPrev() {
    await this.waitformeClick();
    console.log("Prev Click:" + this.indexRound);
    this.rControl = this.indexRound - 2;
    this.actionPrev();
  }
  onClickPlay() {
    // this.playStart = 0;
    // this.stats = 0;
    this.play!.style.display = "none";
    this.play!.disabled = true;
    this.pause!.style.display = "unset";
    if (this.playStart == 0) {
      this.go();
      this.playStart = 1;
    }
  }
  /*== CONTROLLERS end ==*/

  //let second;
  async go() {
    var rounds = this.handleHistory[this.currentArray].rounds;
    console.log("rControl:  ", this.rControl);
    for (this.rControl; this.rControl < rounds.length; this.rControl++) {
      //actionNext(allRounds[currentArray]);
      this.next.click();
      await this.waitforme(1000);
      if (this.stats == 1) await this.pauser();
    }

    if (this.indexRound == rounds.length) {
      this.play.disabled = true;
      this.pause.disabled = true;
      this.stats = 1;
      this.play.style.display = "unset";
      this.pause.style.display = "none";
    }
  }

  /*== ACTIONS ==*/
  actionNext(Actionindex = null) {
    let handleHistory = this.handleHistory[this.currentArray];
    this.round = handleHistory.rounds[0];
    this.roundRevisao = handleHistory.rounds[0];
    this.cdRef.detectChanges();

    if (Actionindex != null) {
      this.indexRound = Actionindex;
    }

    if (this.indexRound == -1) {
      this.hole_cards();
    }

    if (this.indexRound == handleHistory.rounds.length) {
      this.next!.disabled = true;
    }

    this.round.type = "NEXT";

    if (this.indexRound <= handleHistory.rounds.length && this.indexRound != -1) {
      // console.log('chegou aqui index é: ' + this.indexRound);
      this.round = handleHistory.rounds[this.indexRound];
      this.roundRevisao = handleHistory.rounds[this.indexRound];
      this.cdRef.detectChanges();
      this.round.type = "NEXT";

      if (this.roundRevisao?.step != handleHistory.rounds[this.indexRound - 1]?.step) {
        for (var i = 0; i < this.player_box.length; ++i) {
          var item = this.player_box[i];
          this.player_action_reverse(item, "");
        }
      }

      switch (this.round.action) {
        case "FOLDS":
          this.folds(this.round.player);
          break;
        case "CALLS":
          this.createBlind(this.indexRound, this.round.player, this.round.step);
          setTimeout(() => {
            this.blindCallBetRaise(this.indexRound, this.round.index, this.round.player, this.round.value, "CALLS", this.round.step);
          }, 1);
          break;
        case "RAISES":
          let valueRound = this.getPlayerValueDesk(this.round.player, this.round.step, this.round.value);
          let playerRound = this.getPlayerByName(this.round.player);
          if (this.round.fgAllIn == true && (playerRound.smallBlind || playerRound.smallBlind)) {
            this.handleHistoryCurrent.rounds[this.indexRound].value = valueRound;
          } else if (this.round.fgAllIn == true) {
            this.handleHistoryCurrent.rounds[this.indexRound].value = valueRound;
          } else if (playerRound.smallBlind || playerRound.smallBlind) {
            this.handleHistoryCurrent.rounds[this.indexRound].value = valueRound;
          } else {
            this.handleHistoryCurrent.rounds[this.indexRound].value = valueRound;
          };
          // } else {
          //   if (this.round.fgAllIn == true && (playerRound.smallBlind || playerRound.smallBlind)) {
          //     this.handleHistoryCurrent.rounds[this.indexRound].value = valueRound;
          //   } else if (this.round.fgAllIn == true) {
          //     this.handleHistoryCurrent.rounds[this.indexRound].value = valueRound;
          //   } else if (playerRound.smallBlind || playerRound.smallBlind) {
          //     this.handleHistoryCurrent.rounds[this.indexRound].value = valueRound;
          //   } else {
          //     this.handleHistoryCurrent.rounds[this.indexRound].value = valueRound;
          //   };
          // }
          this.createBlind(this.indexRound, this.round.player, this.round.step);
          setTimeout(() => {
            this.blindCallBetRaise(this.indexRound, this.round.index, this.round.player, valueRound, "RAISES", this.round.step);
          }, 5);
          break;
        case "FLOP":
          if (this.round.total_pot != null && this.round.total_pot != undefined) {
            this.pot_calculate("HOLE_CARDS", this.round.step, this.round.total_pot);
          } else {
            this.pot_calculate("HOLE_CARDS", this.round.step);
          }
          this.flopTurnRiver(["1", "2", "3"], this.round.cards);
          var audio = new Audio("../assets/sound/holeCards.wav");
          audio.play();
          break;
        case "CHECKS":
          for (var i = 0; i < this.player_box.length; ++i) {
            if (this.player_box[i].getAttribute("data-player") == this.round.player) {
              var item = this.player_box[i];
              this.player_action(item, "CHECKS");
            }
          }
          break;
        case "BETS":
          this.createBlind(this.indexRound, this.round.player, this.round.step);
          setTimeout(() => {
            this.blindCallBetRaise(this.indexRound, this.round.index, this.round.player, this.round.value, "BETS", this.round.step);
          }, 1);
          break;
        case "TURN":
          if (this.round.total_pot != null && this.round.total_pot != undefined) {
            this.pot_calculate("FLOP", this.round.step, this.round.total_pot);
          } else {
            this.pot_calculate("FLOP", this.round.step);
          }
          this.flopTurnRiver(["4"], this.round.cards);
          var audio = new Audio("../assets/sound/single_card.wav");
          audio.play();
          break;

        case "CALCULATE":
          if (this.round.total_pot != null && this.round.total_pot != undefined) {
            this.pot_calculate(this.round.stepOld, this.round.stepOld, this.round.total_pot);
          } else {
            this.pot_calculate(this.round.stepOld, this.round.stepOld);
          }
          var audio = new Audio("../assets/sound/single_card.wav");
          audio.play();
          break;

        case "RIVER":
          if (this.round.total_pot != null && this.round.total_pot != undefined) {
            this.pot_calculate("TURN", this.round.step, this.round.total_pot);
          } else {
            this.pot_calculate("TURN", this.round.step);
          }
          this.flopTurnRiver(["5"], this.round.cards);
          var audio = new Audio("../assets/sound/single_card.wav");
          audio.play();
          break;
        case "UNCALLED_BET":
          this.blindCallBetRaise_reverse(this.indexRound, this.round.actionIndex, this.round.player, this.round.value, "BETS", this.round.step);
          break;
        case "COLLECTED_FROM_POT":
          var potPosition = document.getElementById("pot-position");
          potPosition.style.transition = "none";
          potPosition.style.left = " 49%";
          potPosition.style.top = "59%";
          potPosition.style.marginTop = "-100px";

          let total_pot_current = document.getElementById("pot-totals")!.getAttribute("data-total").replace(",", "");
          if (this.handleHistory[this.currentArray].categoryGame == "TOURNAMENT") {
            this.pot_calculate("RIVER", this.round.step, this.round.value);
          } else {
            if (handleHistory.rounds[handleHistory.rounds.length - 1].index == this.round.index && handleHistory.totalPot != this.round.value) {
              this.pot_calculate("RIVER", this.round.step);
            } else {
              this.pot_calculate("RIVER", this.round.step);
            }
          }
          // this.foldsOthers(this.round.player);
          setTimeout(() => {
            this.collected_pot(this.round.player, this.round.value);
            for (var i = 0; i < this.player_box.length; ++i) {
              if (this.player_box[i].getAttribute("data-player") == this.round.player) {
                var item = this.player_box[i];
                this.player_action(item, "WINS", this.round.value);
                this.player_action_desc(item, "WINS", this.round);
              }
            }
          }, 300);
          break;
        case "CASHED_OUT":
          this.pot_calculate("RIVER", this.round.step);
          // this.foldsOthers(this.round.player);
          setTimeout(() => {
            this.collected_pot(this.round.player, this.round.value);
            for (var i = 0; i < this.player_box.length; ++i) {
              if (this.player_box[i].getAttribute("data-player") == this.round.player) {
                var item = this.player_box[i];
                this.player_action(item, "WINS", this.round.value);
              }
            }
          }, 500);
          break;
        case "DOENST_SHOW_HAND":
          for (var i = 0; i < this.player_box.length; ++i) {
            if (this.player_box[i].getAttribute("data-player") == this.round.player) {
              var item = this.player_box[i];
              this.player_action(item, "DOENST_SHOW_HAND");
            }
          }
          break;
        case "SHOWS":
          this.cards_shows(this.round.player, this.round.cards);
          setTimeout(() => {
            for (var i = 0; i < this.player_box.length; ++i) {
              if (this.player_box[i].getAttribute("data-player") == this.round.player) {
                var item = this.player_box[i];
                this.player_action_desc(item, "CHECKS", this.round);
              }
            }
          }, 300);
          break;
        case "MUCKED":
          this.cards_shows(this.round.player, this.round.cards);
          break;
        default:
      }
    }
    this.checkStatistics(this.round);

    setTimeout(() => {
      this.setTotalPotRound(this.round.index);
    }, 50);

    this.cleanAreaItem();
    this.sectionStudyService.round$.next(this.roundRevisao);


    if (Actionindex == null) {
      this.indexRound++;
      document.getElementById("revisao-atual")!.innerHTML = this.indexRound + 1;
    }

    if (this.indexRound < handleHistory.rounds.length) {
      this.next.disabled = false;
    } else {
      this.next.disabled = true;
      console.log(this.handleHistoryCurrent)
    }

    if (this.indexRound >= 0) {
      this.prev.disabled = false;
    } else {
      this.prev.disabled = true;
      console.log(this.handleHistoryCurrent)
    }

    console.log(this.round)
    console.log("INDEX-NEXT = " + this.indexRound);
  }

  actionPrev(Actionindex = null) {
    let handleHistory = this.handleHistoryCurrent;
    this.round = handleHistory.rounds[handleHistory.rounds.length];
    this.roundRevisao = handleHistory.rounds[handleHistory.rounds.length];
    this.cdRef.detectChanges();

    if (Actionindex != null) {
      this.indexRound = Actionindex;
    }

    if (Actionindex == null) {
      this.indexRound--;
      document.getElementById("revisao-atual")!.innerHTML = this.indexRound + 1;
    }

    if (Actionindex == null) {
      this.roundRevisao = handleHistory.rounds[this.indexRound - 1];
      if (this.indexRound - 1 == -1) {
        this.roundRevisao = handleHistory.rounds[1];
      }
      if (this.indexRound - 1 == -2) {
        this.roundRevisao = handleHistory.rounds[0];
      }
      this.cdRef.detectChanges();
    }

    if (this.indexRound < 1) {
      this.removeElementsByClass("area-item-clone");
      for (var i = 0; i < this.player_box.length; ++i) {
        var item = this.player_box[i];
        this.player_action_reverse(item, "");
      }
      this.validateIfInitValues(handleHistory);
    }

    if (this.indexRound == -1) {
      this.hole_cards_reverse();
    }

    if (this.indexRound < handleHistory.rounds.length) {
      this.next.disabled = false;
      this.play.disabled = false;
      this.playStart = 0;
      this.stats = 0;
    } else {
      this.next.disabled = true;
    }

    if (this.indexRound >= 0) {
      this.prev.disabled = false;
    } else {
      this.prev.disabled = true;
    }

    console.log("INDEX-PREV = " + this.indexRound);

    if (this.indexRound != -1) {
      this.round = handleHistory.rounds[this.indexRound];
      this.cdRef.detectChanges();
      this.round.type = "PREV";

      switch (this.round.action) {
        case "FOLDS":
          this.folds_reverse(this.round.player);
          for (var i = 0; i < this.player_box.length; ++i) {
            if (this.player_box[i].getAttribute("data-player") == this.round.player) {
              var item = this.player_box[i];
              console.log(this.round)
              this.player_action(item, "FOLDS");
            }
          }
          break;
        case "CALLS":
          this.blindCallBetRaise_reverse(this.indexRound, this.round.index, this.round.player, this.round.value, "CALLS", this.round.step);
          setTimeout(() => {
            this.deleteBlind(this.indexRound, this.round.player, this.round.step);
          }, 300);
          //document.getElementById("prev").click();
          break;
        case "RAISES":
          let valueRound = this.round.value;
          if (this.handleHistory[this.currentArray].categoryGame == "TOURNAMENT") {
            valueRound = (this.round.value);
          } else {
            valueRound = this.round.value;
          }
          console.warn(valueRound, this.round.value)
          this.blindCallBetRaise_reverse(this.indexRound, this.round.index, this.round.player, valueRound, "RAISES", this.round.step);
          setTimeout(() => {
            this.deleteBlind(this.indexRound, this.round.player, this.round.step);
          }, 300);
          break;
        case "FLOP":
          this.flopTurnRiver_reverse(["1", "2", "3"], this.round.cards);
          setTimeout(() => {
            this.pot_calculate_reverse("HOLE_CARDS", this.round.step, this.round.total_pot);

            //VALIDAR
            document.getElementById("pot-position")!.style.opacity = "1";
            document.getElementById("pot-position")!.style.marginTop = "-100px";
          }, 5);
          var audio = new Audio("../assets/sound/holeCards.wav");
          audio.play();
          break;
        case "CHECKS":
          for (var i = 0; i < this.player_box.length; ++i) {
            if (this.player_box[i].getAttribute("data-player") == this.round.player) {
              var item = this.player_box[i];
              this.player_action_reverse(item, "CHECKS");
            }
          }
          //document.getElementById("prev").click();
          break;
        case "BETS":
          this.blindCallBetRaise_reverse(this.indexRound, this.round.index, this.round.player, this.round.value, "BETS", this.round.step, true);
          setTimeout(() => {
            this.deleteBlind(this.indexRound, this.round.player, this.round.step);
          }, 300);
          break;
        case "TURN":
          this.flopTurnRiver_reverse(["4"], this.round.cards);
          setTimeout(() => {
            this.pot_calculate_reverse("FLOP", this.round.step, this.round.total_pot);
            document.getElementById("pot-position")!.style.opacity = "1";
            document.getElementById("pot-position")!.style.marginTop = "-100px";
          }, 5);
          var audio = new Audio("../assets/sound/single_card.wav");
          audio.play();
          break;
        case "RIVER":
          this.flopTurnRiver_reverse(["5"], this.round.cards);
          setTimeout(() => {
            this.pot_calculate_reverse("TURN", this.round.step, this.round.total_pot);
            document.getElementById("pot-position")!.style.opacity = "1";
            document.getElementById("pot-position")!.style.marginTop = "-100px";
          }, 5);
          var audio = new Audio("../assets/sound/single_card.wav");
          audio.play();
          break;
        case "UNCALLED_BET":
          this.blindCallBetRaise(this.indexRound, this.round.actionIndex, this.round.player, this.round.value, "BETS", this.round.step);
          this.folds_reverse(this.round.player);
          break;

        case "CALCULATE":
          this.pot_calculate_reverse(this.round.step, this.round.step, this.round.total_pot);
          var audio = new Audio("../assets/sound/single_card.wav");
          audio.play();
          break;

        case "COLLECTED_FROM_POT":
          var audio = new Audio("../assets/sound/winReverse.wav");
          audio.play();

          if (this.handleHistory[this.currentArray].categoryGame == "TOURNAMENT") {
            this.pot_calculate_reverse("RIVER", this.round.step, this.round.value);
            this.collected_pot_reverse(this.round.player, String(this.round.value));
          } else {
            if (handleHistory.rounds[handleHistory.rounds.length - 1].index == this.round.index &&
              handleHistory.rounds[handleHistory.rounds.length - 1].step == this.round.step) {
              this.pot_calculate_reverse("RIVER", this.round.step);
              this.collected_pot_reverse(this.round.player, String(this.round.value));
            } else {
              this.pot_calculate_reverse("RIVER", this.round.step);
              this.collected_pot_reverse(this.round.player, String(this.round.value));
            }
          }

          this.foldsOthers_reverse(this.round.player);
          setTimeout(() => {
            for (var i = 0; i < this.player_box.length; ++i) {
              if (this.player_box[i].getAttribute("data-player") == this.round.player) {
                var item = this.player_box[i];
                this.player_action_reverse(item, "");
                // this.player_action_desc(item, "CHECKS", this.round);
              }
            }
          }, 300);
          setTimeout(function () {
            //pot_calculate_reverse('RIVER', round.step);
            document.getElementById("pot-position")!.style.opacity = "1";
            document.getElementById("pot-position")!.style.marginTop = "-100px";
          }, 500);
          break;
        case "CASHED_OUT":
          var audio = new Audio("../assets/sound/winReverse.wav");
          audio.play();
          this.pot_calculate_reverse("RIVER", this.round.step);
          this.collected_pot_reverse(this.round.player, this.round.value);
          this.foldsOthers_reverse(this.round.player);
          setTimeout(function () {
            //pot_calculate_reverse('RIVER', round.step);
            document.getElementById("pot-position")!.style.opacity = "1";
            document.getElementById("pot-position")!.style.marginTop = "-100px";
          }, 500);
          break;
        case "DOENST_SHOW_HAND":
          this.folds_reverse(this.round.player);
          break;
        case "SHOWS":
          this.cards_shows_reverse(this.round.player, this.round.cards);
          setTimeout(() => {
            for (var i = 0; i < this.player_box.length; ++i) {
              if (this.player_box[i].getAttribute("data-player") == this.round.player) {
                var item = this.player_box[i];
                this.player_action_desc_reverse(item);
              }
            }
          }, 300);
          //folds_reverse(round.player);
          break;
        case "MUCKED":
          this.cards_shows_reverse(this.round.player, this.round.cards);
          setTimeout(() => {
            for (var i = 0; i < this.player_box.length; ++i) {
              if (this.player_box[i].getAttribute("data-player") == this.round.player) {
                var item = this.player_box[i];
                this.player_action_desc_reverse(item);
              }
            }
          }, 300);
          break;
        default:
      }

      /// TRIGGER POT INICIAL
      if (this.round.total_pot) {
        this.resetPot(Number(this.round.total_pot));
      }
      // if (handleHistory.rounds[this.indexRound + 1]?.action == "COLLECTED_FROM_POT" &&
      //   handleHistory.rounds.some(round => round.action == "FLOP") == false) {
      //   let current_value_pot = 0;
      //   if (this.handleHistory[this.currentArray].categoryGame == "TOURNAMENT") {

      //     handleHistory.players.map(player => {
      //       current_value_pot += player?.ante;
      //     })
      //     this.resetPot(current_value_pot);

      //   } else {
      //     handleHistory.players.map(player => {
      //       current_value_pot += player?.ante;
      //     })
      //     this.resetPot(current_value_pot);
      //   }
      // }

      /// RECALCULANDO POT TOTAL
      // var quantityCollected = handleHistory.rounds.filter(round => round.action === "COLLECTED_FROM_POT").length;
      // if (quantityCollected > 1 && handleHistory.rounds[this.indexRound + 1]?.action == "COLLECTED_FROM_POT") {
      //   let current_value = 0;
      //   handleHistory.rounds.map((round) => {
      //     if (round.action == "COLLECTED_FROM_POT") {
      //       current_value += round?.value;
      //     }
      //   })
      //   this.resetPot(current_value);
      // }

      /// TRIGGER POT INICIAL FLOP
      // let firstFlop = handleHistory.rounds.find(round => { return round.action == "FLOP" });
      // if (firstFlop && handleHistory.rounds[this.indexRound + 1]?.index == firstFlop?.index) {
      //   let current_value_pot_ante = 0;
      //   handleHistory.players.map(player => {
      //     current_value_pot_ante += player?.ante;
      //   })
      //   setTimeout(() => {
      //     this.resetPot(current_value_pot_ante);
      //   }, 200);
      // }
    }

    this.checkStatistics(this.round);
    this.cleanAreaItem();
    this.sectionStudyService.round$.next(this.roundRevisao);

    console.log(this.round);

    // Prev Actions
    for (var i = 0; i < this.player_box.length; ++i) {
      const roundPrev = handleHistory.rounds[this.indexRound];
      const roundPrevOne = handleHistory.rounds[this.indexRound - 1];
      if (this.player_box[i].getAttribute("data-player") == roundPrev?.player) {
        var item = this.player_box[i];
        this.player_action_reverse(item, "");
        this.player_action_desc(item, "CALLS", roundPrev);
      }
      if (this.player_box[i].getAttribute("data-player") == roundPrevOne?.player) {
        var item = this.player_box[i];
        var action: string = roundPrevOne.action;
        if (action == 'TURN') {
          action = 'FLOP';
        }
        if (action == 'RIVER') {
          action = 'TURN';
        }
        if (action == 'UNCALLED_BET') {
          action = '';
        }
        if (action == 'COLLECTED_FROM_POT') {
          action = 'WINS';
        }
        if (action == 'CASHED_OUT') {
          action = 'WINS';
        }
        if (action == 'DOENST_SHOW_HAND') {
          action = 'DOENST_SHOW_HAND';
        }
        if (action == 'SHOWS') {
          action = '';
        }
        if (action == 'MUCKED') {
          action = '';
        }
        if (action.length > 2) {
          if (roundPrevOne.value && action == "WINS") {
            this.player_action(item, action, roundPrevOne.value);
          } else {
            this.player_action(item, action);
          }
        } else {
          this.player_action_reverse(item, "CALLS");
        }
      }
    }
  }

  checkStatistics(round: Round) {
    let player_statistics = document.querySelectorAll(".player-statistics ul li");
    let street =  this.roundRevisao?.step == 'HOLE_CARDS' ?  "PRE_FLOP" : this.roundRevisao?.step  == "SHOW_DOWN" ? "RIVER" : this.roundRevisao?.step
    for (let index = 0; index < player_statistics.length; index++) {
      const element = player_statistics[index] as HTMLDivElement;
      if (street == element.getAttribute("data-street")) {
        element.style.display = "inline-block";
      } else {
        element.style.display = "none";
      }
    }
  }

  setTotalPotRound(roundIndex: number) {
    let pot_totals = document.getElementById("pot-totals")!.getAttribute("data-total").replace(",", "");
    let roundIndexFind = this.handleHistory[this.currentArray].rounds.findIndex(round => round.index == roundIndex);
    if (this.handleHistory[this.currentArray].rounds[roundIndexFind]?.total_pot == null) {
      switch (this.handleHistory[this.currentArray].rounds[roundIndexFind]?.action) {
        case "RIVER":
          this.handleHistory[this.currentArray].rounds[roundIndexFind].total_pot = this.handleHistoryCurrent.rounds[roundIndexFind].total_pot;
          this.handleHistoryCurrent.rounds[roundIndexFind].total_pot = this.handleHistoryCurrent.rounds[roundIndexFind].total_pot;
          break;
        case "FLOP":
          this.handleHistory[this.currentArray].rounds[roundIndexFind].total_pot = this.handleHistoryCurrent.rounds[roundIndexFind].total_pot;
          this.handleHistoryCurrent.rounds[roundIndexFind].total_pot = this.handleHistoryCurrent.rounds[roundIndexFind].total_pot;
          break;
        case "TURN":
          this.handleHistory[this.currentArray].rounds[roundIndexFind].total_pot = this.handleHistoryCurrent.rounds[roundIndexFind].total_pot;
          this.handleHistoryCurrent.rounds[roundIndexFind].total_pot = this.handleHistoryCurrent.rounds[roundIndexFind].total_pot;
          break;
        case "CALCULATE":
          this.handleHistory[this.currentArray].rounds[roundIndexFind].total_pot = this.handleHistoryCurrent.rounds[roundIndexFind - 1].total_pot;
          this.handleHistoryCurrent.rounds[roundIndexFind].total_pot = this.handleHistoryCurrent.rounds[roundIndexFind - 1].total_pot;
          break;
        default:
          this.handleHistory[this.currentArray].rounds[roundIndexFind].total_pot = Number(pot_totals);
          this.handleHistoryCurrent.rounds[roundIndexFind].total_pot = Number(pot_totals);
          break;
      }
    }
  }

  setPlayerTotalValueMap(value: number) {
    this.handleHistoryCurrent.rounds.map(round => {
      if (round.index == this.round.index) {
        round.player_total_value = value;
      }
    });
  }

  deepCopy(obj) {
    var copy;

    // Handle the 3 simple types, and null or undefined
    if (null == obj || "object" != typeof obj) return obj;

    // Handle Date
    if (obj instanceof Date) {
      copy = new Date();
      copy.setTime(obj.getTime());
      return copy;
    }

    // Handle Array
    if (obj instanceof Array) {
      copy = [];
      for (var i = 0, len = obj.length; i < len; i++) {
        copy[i] = this.deepCopy(obj[i]);
      }
      return copy;
    }

    // Handle Object
    if (obj instanceof Object) {
      copy = {};
      for (var attr in obj) {
        if (obj.hasOwnProperty(attr)) copy[attr] = this.deepCopy(obj[attr]);
      }
      return copy;
    }

    throw new Error("Unable to copy obj! Its type isn't supported.");
  }

  removeElementsByClass(className) {
    const elements = document.getElementsByClassName(className);
    while (elements.length > 0) {
      elements[0].parentNode.removeChild(elements[0]);
    }
  }

  resetPot(value_pot) {
    document.getElementById("pot-totals")!.innerHTML = this.toFixedIfNecessary(value_pot);
    document.getElementById("pot-totals")!.setAttribute("data-total", this.toFixedIfNecessary(value_pot));
  }

  getPlayerByName(PlayerName: string) {
    let playerCurrent: Player = {} as Player;
    this.handleHistory[this.currentArray].players.map(player => {
      if (PlayerName == player.nickname) {
        playerCurrent = player;
      }
    });
    return playerCurrent;
  }

  validateIfInitValues(handleHistory: HandleHistory) {
    handleHistory.players.map((playerName) => {
      if (playerName.smallBlind || playerName.bigBlind) {
        var area_item = document.getElementsByClassName("area-item");
        for (var k = 0; k < area_item.length; ++k) {
          if (area_item[k].getAttribute("data-player") == playerName.nickname) {
            let itemOfArea: any = area_item[k];
            let valueOfPlayer: number = Number(itemOfArea.querySelectorAll(".total-chips-value")[0]?.getAttribute("data-value")?.replace(/[,\s]+|[,\s]+/g, ""));
            console.log("valueOfPlayer", valueOfPlayer);
            if (valueOfPlayer == playerName.smallBlind || valueOfPlayer == playerName.bigBlind && itemOfArea.style.opacity == '0') {
              itemOfArea.style.opacity = "1";
              console.log(itemOfArea)
            }
          }
        }
      }
    })
    if (handleHistory.hhPlayer.smallBlind || handleHistory.hhPlayer.bigBlind) {
      var area_item = document.getElementsByClassName("area-item");
      for (var k = 0; k < area_item.length; ++k) {
        if (area_item[k].getAttribute("data-player") == handleHistory.hhPlayer.nickname) {
          let itemOfArea: any = area_item[k];
          let valueOfPlayer: number = Number(itemOfArea.querySelectorAll(".total-chips-value")[0]?.getAttribute("data-value")?.replace(/[,\s]+|[,\s]+/g, ""));
          console.log("valueOfPlayer", valueOfPlayer);
          if (valueOfPlayer == handleHistory.hhPlayer.smallBlind || valueOfPlayer == handleHistory.hhPlayer.bigBlind && itemOfArea.style.opacity == '0') {
            itemOfArea.style.opacity = "1";
            console.log(itemOfArea)
          }
        }
      }
    }
  }
  /*== ACTIONS end ==*/

  /*== HOLE CARDS ==*/
  hole_cards() {
    var audio = new Audio("../assets/sound/holeCards.wav");
    audio.play();
    setTimeout(() => {
      for (var i = 0; i < this.player_hand.length; ++i) {
        var item = this.player_hand[i];
        item.classList.add("hole-cardDesk");
        item.classList.remove("hole-cardDesk-reverse");
      }
    }, 150);
  }

  hole_cards_reverse() {
    var audio = new Audio("../assets/sound/holeCards.wav");
    audio.play();
    for (var i = 0; i < this.player_hand.length; ++i) {
      var item = this.player_hand[i];
      item.classList.remove("hole-cardDesk");
      item.classList.add("hole-cardDesk-reverse");
    }
  }
  /*== HOLE CARDS end ==*/

  /*== FOLDS ==*/
  folds(playerName: string) {
    for (var i = 0; i < this.player_box.length; ++i) {
      if (this.player_box[i].getAttribute("data-player") == playerName) {
        var item = this.player_box[i];
        item.style.opacity = "0.2";
        this.player_action(item, "FOLDS");
      }
    }

    for (var i = 0; i < this.player_hand.length; ++i) {
      if (this.player_hand[i].getAttribute("data-player") == playerName) {
        var item = this.player_hand[i];
        item.style.opacity = "0.05";
      }
    }
  }

  foldsOthers(playerName: string) {
    for (var i = 0; i < this.player_box.length; ++i) {
      if (
        this.player_box[i].getAttribute("data-player") != playerName &&
        this.player_box[i].style.opacity != "0.2"
      ) {
        var item = this.player_box[i];
        item.setAttribute("folds-other", "FOLD_COLLECTED");
        item.style.opacity = "0.2";
        this.player_action(item, "FOLDS");
      }
    }

    for (var i = 0; i < this.player_hand.length; ++i) {
      if (
        this.player_hand[i].getAttribute("data-player") != playerName &&
        this.player_hand[i].style.opacity != "0.05"
      ) {
        var item = this.player_hand[i];
        item.setAttribute("folds-other", "FOLD_COLLECTED");
        item.style.opacity = "0.05";
      }
    }
  }

  foldsOthers_reverse(playerName: string) {
    for (var i = 0; i < this.player_box.length; ++i) {
      if (
        this.player_box[i].getAttribute("data-player") != playerName &&
        this.player_box[i].getAttribute("folds-other") === "FOLD_COLLECTED"
      ) {
        var item = this.player_box[i];
        item.setAttribute("folds-other", "FOLD_COLLECTED_REMOVED");
        item.style.opacity = "1";
        //player_action(item, 'FOLDS');
      }
    }

    for (var i = 0; i < this.player_hand.length; ++i) {
      if (
        this.player_hand[i].getAttribute("data-player") != playerName &&
        this.player_hand[i].getAttribute("folds-other") === "FOLD_COLLECTED"
      ) {
        var item = this.player_hand[i];
        item.setAttribute("folds-other", "FOLD_COLLECTED_REMOVED");
        item.style.opacity = "1";
      }
    }
  }

  folds_reverse(playerName: string) {
    var audio = new Audio("../assets/sound/foldsReverse.wav");
    audio.play();
    for (var i = 0; i < this.player_box.length; ++i) {
      if (this.player_box[i].getAttribute("data-player") == playerName) {
        var item = this.player_box[i];
        item.style.opacity = "1";
        this.player_action_reverse(item, "FOLDS");
      }
    }

    for (var i = 0; i < this.player_hand.length; ++i) {
      if (this.player_hand[i].getAttribute("data-player") == playerName) {
        var item = this.player_hand[i];
        item.style.opacity = "1";
      }
    }
  }
  /*== FOLDS end ==*/

  /*== AREA ACTION ==*/
  createBlind(blindNumber = "1", playerName: string, currentStep: string) {
    let player = playerName;
    var area_item = document.getElementsByClassName("area-item");

    for (var i = 0; i < area_item.length; i++) {
      if (
        area_item[i].getAttribute("data-player") == player &&
        area_item[i].getAttribute("data-step") == currentStep
      ) {
        var item = area_item[i];
        var element = document.createElement("div");
        element.classList.add("blind-number");
        element.setAttribute("data-blind", blindNumber);
        element.innerHTML = '<div class="area-chips">';
        element.innerHTML += "</div>";
        item.appendChild(element);
      }
    }
  }

  deleteBlind(blindNumber = "1", playerName: string, currentStep: string) {
    let player = playerName;
    var area_item = document.getElementsByClassName("area-item");
    const area_item_length = area_item.length;

    for (var i = 0; i < area_item_length; ++i) {
      var itemFold = area_item[i] as HTMLDivElement;

      if (
        area_item[i].getAttribute("data-player") == player &&
        area_item[i].getAttribute("data-step") == currentStep
      ) {
        var item = area_item[i] as HTMLDivElement;
        var blind = item.querySelectorAll(".blind-number");
        // (<any>item).style.opacity = "0";
        for (var j = 0; j < blind.length; ++j) {
          if (blind[j].getAttribute("data-blind") == blindNumber) {
            blind[j].remove();
          }
        }
      }
      if (itemFold.getAttribute("data-player") == player && itemFold.childNodes.length == 1) {
        itemFold.style.opacity = "0";
      }
    }
  }

  getPlayerValueDesk(playerName: string, currentStep: string, actionValue: number) {
    var area_item = document.getElementsByClassName("area-item");
    var value_on_table = 0;
    for (var i = 0; i < area_item.length; ++i) {
      if (area_item[i].getAttribute("data-player") == playerName && area_item[i].getAttribute("data-step") == currentStep) {
        var item: any = area_item[i];
        var current_player_val = 0;
        current_player_val = item.querySelectorAll(".total-chips-value")[0].getAttribute("data-value").replace(",", "");
        value_on_table = (actionValue - current_player_val);
      }
    }
    return value_on_table;
  }

  getSmallBlindBigBlindPlayer(handleHistory: HandleHistory, playerName: string): number {
    let player_SmallBlindBigBlind = 0;
    handleHistory.players.map((player) => {
      if (playerName == player.nickname) {
        if (player.bigBlind) {
          player_SmallBlindBigBlind = player.bigBlind;
        }
        if (player.smallBlind) {
          player_SmallBlindBigBlind = player.smallBlind;
        }
      }
    })
    return player_SmallBlindBigBlind;
  }

  blindCallBetRaise(index: number, blindNumber = "1", playerName: string, actionValue: number, playerAction = "CALLS", currentStep: string) {
    var area_item = document.getElementsByClassName("area-item");
    let player = playerName;
    let value = 0;
    let index_action: any = 0;
    index_action = index + "-" + player;
    var current_player_val: any = 0;
    var cblind;
    var blind_number;

    var offSetTopPlayerBox = 0;
    var offSetLeftPlayerBox = 0;
    var timeStep = 1;

    if (currentStep != "HOLE_CARDS") {
      timeStep = 500;
    }

    for (var i = 0; i < this.player_box.length; i++) {
      if (this.player_box[i].getAttribute("data-player") == player) {
        var item = this.player_box[i];
        offSetTopPlayerBox = item.offsetTop;
        offSetLeftPlayerBox = item.offsetLeft;
      }
    }

    //Adiciona 25% a posição
    offSetLeftPlayerBox = offSetLeftPlayerBox + (parseFloat("25") * parseFloat(String(offSetLeftPlayerBox))) / 100;

    setTimeout(() => {
      for (var i = 0; i < area_item.length; ++i) {
        var item: any = area_item[i];
        if (area_item[i].getAttribute("data-player") == player && area_item[i].getAttribute("data-step") == currentStep &&
          item.querySelectorAll(".blind-number").length > 0) {
          item.style.opacity = "1";

          current_player_val = item.querySelectorAll(".total-chips-value")[0].getAttribute("data-value").replace(",", "");

          var current_player;
          this.handleHistory[this.currentArray].players.map(playerFiltered => {
            if (playerFiltered.nickname == player) current_player = playerFiltered;
          })
          if (actionValue + parseFloat(current_player_val) > (current_player.chips + current_player.ante)) {
            value = (current_player.chips - current_player.ante);
          } else {
            value = actionValue + parseFloat(current_player_val);
          }
          // if (playerAction == "RAISES") {
          //   value = actionValue;
          // }
          item.querySelectorAll(".total-chips-value")[0].setAttribute("data-value", this.toFixedIfNecessary(value));
          item.querySelectorAll(".total-chips-value")[0].innerHTML = this.toFixedIfNecessary(value);
          if (value > 0) {
            item.querySelectorAll(".total-chips-value")[0].classList.remove("calls-fade-reverse");
            item.querySelectorAll(".total-chips-value")[0].classList.add("calls-fade");
          }

          blind_number = item.querySelectorAll(".blind-number");

          blind_number.forEach((itemBlind: any) => {
            if (itemBlind.getAttribute("data-blind") == blindNumber) {
              itemBlind.classList.remove("calls-fade-reverse");
              itemBlind.classList.add("calls-fade");

              this.addChips(itemBlind.querySelectorAll(".area-chips")[0], index_action);

              cblind = itemBlind.querySelectorAll(".blind");
              cblind.forEach((cItem: any) => {
                cItem.classList.add("calls-fade");
                cItem.classList.remove("calls-fade-reverse");
              });
            }
          });
        }
      }
    }, 1);

    for (var k = 0; k < this.player_box.length; ++k) {
      if (this.player_box[k].getAttribute("data-player") == player) {
        var item = this.player_box[k];
        this.action_calculation(item, actionValue);
        this.player_action(item, playerAction);
      }
    }
  }

  blindCallBetRaise_reverse(index: number, blindNumber = "1", playerNumber: string, actionValue: number, playerAction = "CALLS", currentStep: string, reverse?: boolean) {
    let player = playerNumber;
    var value = actionValue;
    let index_action: any = 0;
    index_action = index + "-" + player;
    var current_player_val: any = 0;

    for (var k = 0; k < this.player_box.length; ++k) {
      if (this.player_box[k].getAttribute("data-player") == player) {
        var item = this.player_box[k];
        if (value == 0 && playerAction == "RAISES") {
          this.handleHistory[this.currentArray].players.map(player => {
            if (player.nickname == playerNumber) {
              if (player.bigBlind) {
                value = actionValue - player.bigBlind;
              }
              if (player.smallBlind) {
                value = actionValue - player.smallBlind;
              }
              this.action_calculation_reverse(item, value);
            }
          })
        } else if (playerAction == "BETS" && reverse) {
          this.action_calculation_reverse(item, actionValue);
        } else {
          this.action_calculation_reverse(item, actionValue);
        }
        if (playerAction == "BETS") {
          this.player_action_reverse(item, playerAction);
        }
      }
    }
    setTimeout(() => {
      for (var i = 0; i < this.area_item.length; ++i) {
        if (this.area_item[i].getAttribute("data-player") == player && this.area_item[i].getAttribute("data-step") == currentStep) {
          var item = this.area_item[i];

          current_player_val = item.querySelectorAll(".total-chips-value")[0].getAttribute("data-value").replace(",", "");

          value = parseFloat(current_player_val) - actionValue < 0 ? 0 : parseFloat(current_player_val) - actionValue;

          console.log(value, playerNumber, playerAction)
          if (value == 0 && playerAction == "RAISES") {
            this.handleHistory[this.currentArray].players.map(player => {
              if (player.nickname == playerNumber) {
                if (player.smallBlind) {
                  value = player.smallBlind;
                }
                if (player.bigBlind) {
                  value = player.bigBlind;
                }
              }
            })
          }

          item.querySelectorAll(".total-chips-value")[0].setAttribute("data-value", this.toFixedIfNecessary(value));
          item.querySelectorAll(".total-chips-value")[0].innerHTML = this.toFixedIfNecessary(value);

          current_player_val = item.querySelectorAll(".total-chips-value")[0].getAttribute("data-value").replace(",", "");

          if (value <= parseFloat("0")) {
            item.style.opacity = "0";
            item.querySelectorAll(".total-chips-value")[0].classList.add("calls-fade-reverse");
            item.querySelectorAll(".total-chips-value")[0].classList.remove("calls-fade");
          }

          this.blind_number = item.querySelectorAll(".blind-number");
          // console.log(this.blind_number)
          this.blind_number.forEach((itemBlind: any) => {
            if (itemBlind.getAttribute("data-blind") == blindNumber) {
              // itemBlind.classList.remove('calls-fade');
              // itemBlind.classList.add('calls-fade-reverse');
              // this.addChips_remove(itemBlind);
              // VERIFICAR
            }
          });
        }
      }
    }, 5);

    for (var k = 0; k < this.player_box.length; ++k) {
      if (this.player_box[k].getAttribute("data-player") == player) {
        var item = this.player_box[k];
        if (playerAction != "BETS") {
          // this.player_action(item, playerAction);
        }
      }
    }
  }

  action_calculation(elementPlayer: any, actionValue: any) {
    var current_val: any = 0, total = 0;
    if (typeof (elementPlayer.querySelectorAll(".player-total-value")[0] != "undefined" &&
      elementPlayer.querySelectorAll(".player-total-value")[0] != null)) {
      current_val = elementPlayer.querySelectorAll(".player-total-value")[0].innerText.replace(",", "");
      total = parseFloat(current_val) - parseFloat(actionValue);
    }
    if (total < 0) total = 0;
    console.log("total = " + total + " / " + current_val)
    elementPlayer.querySelectorAll(".player-total-value")[0].innerText = this.toFixedIfNecessary(total);
    this.validateReplace(elementPlayer, current_val);
  }

  action_calculation_reverse(elementPlayer: any, actionValue: any) {
    var current_val: any = 0, total = 0;
    current_val = parseFloat(elementPlayer.querySelectorAll(".player-total-value")[0].innerText.replace(",", ""));
    total = parseFloat(current_val) + parseFloat(actionValue);
    elementPlayer.querySelectorAll(".player-total-value")[0].innerText = this.toFixedIfNecessary(total);

    this.validateReplace(elementPlayer, total)
  }

  validateReplace(elementPlayer: any, actionValue: any) {
    if ('player_total_value' in this.round == false && this.round.type == "NEXT") {
      this.setPlayerTotalValueMap(Number(actionValue));
    }
    // if ('player_total_value' in this.round == true) {
    //   elementPlayer.querySelectorAll(".player-total-value")[0].innerText = this.toFixedIfNecessary(this.round.player_total_value);
    // }
  }

  player_action(elementPlayer: any, action = "CALLS", value?: number) {
    var playerActionElement = elementPlayer.querySelectorAll(".player-action")[0];
    if (elementPlayer) {
      playerActionElement.classList.remove("action-show-reverse");
      if (playerActionElement?.getAttribute("data-player") == this.handleHistory[this.currentArray]?.rounds[this.indexRound - 1]?.player
        && this.handleHistory[this.currentArray]?.rounds[this.indexRound - 1]?.fgAllIn) {
        playerActionElement.innerHTML = `ALL IN - ${action}`;
      } else {
        playerActionElement.innerHTML = action;
      }
      if (value) {
        playerActionElement.innerHTML = `${action} ${this.toFixedIfNecessary(value)}`;
      }
      playerActionElement.classList.add("action-show");
      // this.player_action_desc(elementPlayer, action);
      if (action == "CALLS") {
        playerActionElement.style.zoom = "1.1";
        playerActionElement.style.transition = "ease 0.3s all";
        playerActionElement.style.background = "#56915d";
        playerActionElement.style.color = "#fff";
        playerActionElement.style.borderColor = "#56915d";
        setTimeout(function () {
          playerActionElement.style.zoom = "1";
        }, 200);
        var audio = new Audio("../assets/sound/blind.wav");
        audio.play();
      } else if (action == "BETS") {
        playerActionElement.style.zoom = "1.1";
        playerActionElement.style.transition = "ease 0.3s all";
        playerActionElement.style.background = "#e84407";
        playerActionElement.style.color = "#fff";
        playerActionElement.style.borderColor = "#e84407";
        setTimeout(function () {
          playerActionElement.style.zoom = "1";
        }, 200);
        var audio = new Audio("../assets/sound/blind.wav");
        audio.play();
      } else if (action == "CHECKS") {
        playerActionElement.style.zoom = "1.1";
        playerActionElement.style.transition = "ease 0.3s all";
        playerActionElement.style.background = "#fbb03b";
        playerActionElement.style.color = "#fff";
        playerActionElement.style.borderColor = "#fbb03b";
        setTimeout(function () {
          playerActionElement.style.zoom = "1";
        }, 200);
        var audio = new Audio("../assets/sound/checks.wav");
        audio.play();
      } else if (action == "FOLDS") {
        playerActionElement.style.zoom = "1.1";
        playerActionElement.style.transition = "ease 0.3s all";
        playerActionElement.style.background = "transparent";
        playerActionElement.style.color = "#6d7177";
        playerActionElement.style.borderColor = "#6d7177";
        setTimeout(function () {
          playerActionElement.style.zoom = "1";
        }, 200);
        var audio = new Audio("../assets/sound/folds.wav");
        audio.play();
      } else if (action == "RAISES") {
        playerActionElement.style.zoom = "1.1";
        playerActionElement.style.transition = "ease 0.3s all";
        playerActionElement.style.background = "#d82337";
        playerActionElement.style.color = "#fff";
        playerActionElement.style.borderColor = "#d82337";
        setTimeout(function () {
          playerActionElement.style.zoom = "1";
        }, 200);
        var audio = new Audio("../assets/sound/raises.wav");
        audio.play();
      } else if (action == "WINS") {
        playerActionElement.style.zoom = "1.1";
        playerActionElement.style.transition = "ease 0.3s all";
        playerActionElement.style.background = "#56915d";
        playerActionElement.style.color = "#fff";
        playerActionElement.style.borderColor = "#56915d";
        setTimeout(function () {
          playerActionElement.style.zoom = "1";
        }, 200);
        var audio = new Audio("../assets/sound/win.wav");
        audio.play();
      } else if (action == "DOENST_SHOW_HAND") {
        playerActionElement.style.zoom = "1.1";
        playerActionElement.style.transition = "ease 0.3s all";
        playerActionElement.style.background = "#fbb03b";
        playerActionElement.style.color = "#fff";
        playerActionElement.style.borderColor = "#fbb03b";
        setTimeout(function () {
          playerActionElement.style.zoom = "1";
        }, 200);
        var audio = new Audio("../assets/sound/checks.wav");
        audio.play();
      }
    }
  }

  player_action_reverse(elementPlayer: any, action = "CALLS") {
    var audio = new Audio("../assets/sound/foldsReverse.wav");
    audio.play();
    var playerActionElement = elementPlayer.querySelectorAll(".player-action")[0];
    playerActionElement.classList.add("action-show-reverse");
    if (this.handleHistory[this.currentArray]?.rounds[this.indexRound - 1]?.fgAllIn) {
      playerActionElement.innerHTML = `ALL IN - ${action}`;
    } else {
      playerActionElement.innerHTML = action;
    }
    playerActionElement.classList.remove("action-show");
    // this.player_action_desc_reverse(elementPlayer);
  }

  player_action_desc(elementPlayer: any, action = "CALLS", round: Round) {
    var playerActionDescElement = elementPlayer.querySelectorAll(".player-action-desc")[0];
    if (round?.desc) {
      playerActionDescElement.classList.remove("action-show-reverse");
      playerActionDescElement.innerHTML = round?.desc;
      playerActionDescElement.classList.add("action-show");
      playerActionDescElement.style.zoom = "1.1";
      playerActionDescElement.style.transition = "ease 0.3s all";
      playerActionDescElement.style.background = "#fbb03b";
      playerActionDescElement.style.color = "#fff";
      playerActionDescElement.style.borderColor = "#fbb03b";
      setTimeout(function () {
        playerActionDescElement.style.zoom = "1";
      }, 200);
    }
  }

  player_action_desc_reverse(elementPlayer: any) {
    var playerActionDescElement = elementPlayer.querySelectorAll(".player-action-desc")[0];
    playerActionDescElement.classList.add("action-show-reverse");
    if (this.handleHistory[this.currentArray]?.rounds[this.indexRound - 1]?.desc) {
      playerActionDescElement.innerHTML = this.handleHistory[this.currentArray]?.rounds[this.indexRound - 1]?.desc;
    } else {
      playerActionDescElement.innerHTML = "";
    }
    playerActionDescElement.classList.remove("action-show");
  }

  addChips(element: any, playerName: string) {
    var content = document.createElement("div");
    content.id = playerName;
    content.classList.add("index-item");
    content.innerHTML =
      '<div class="blind small-blind"><div class="chip chip-blue"></div><div class="chip chip-red"></div><div class="chip chip-red"></div><div class="chip chip-dark"></div><div class="chip chip-dark"></div><div class="chip chip-dark"></div></div>';
    element.appendChild(content);
  }

  addChips_remove(element: any) {
    //id = '#' + index
    var audio = new Audio("../assets/sound/blind.wav");
    audio.play();
    setTimeout(function () {
      element.remove();
    }, 300);
  }
  /*== AREA ACTION end ==*/

  /*== ACTION TABLE ==*/
  flopTurnRiver(index: string[], cards: string[]) {
    var k = 0;
    document.getElementById("pot-position")!.style.marginTop = "-100px";

    for (var i = 0; i < this.community_cards.length; ++i) {
      if (
        index.includes(this.community_cards[i].getAttribute("data-cardDesk"))
      ) {
        var item = this.community_cards[i];
        item.classList.add("action-flip");
        item.classList.remove("action-flip-reverse");
        item.querySelectorAll(".flip-cardDesk-back")[0].querySelectorAll(".cardDesk")[0].classList.add("cardDesk-" + cards[k].replace(" ", ""));
        k++;
      }
    }
  }
  flopTurnRiver_reverse(index: string[], cards: string[]) {
    var k = 0;
    document.getElementById("pot-position")!.style.marginTop = "-100px";
    document.getElementById("pot-position")!.style.opacity = "1";

    for (var i = 0; i < this.community_cards.length; ++i) {
      if (
        index.includes(this.community_cards[i].getAttribute("data-cardDesk"))
      ) {
        var item = this.community_cards[i];
        item.classList.remove("action-flip");
        item.classList.add("action-flip-reverse");
        item.querySelectorAll(".flip-cardDesk-back")[0].querySelectorAll(".cardDesk")[0].classList.add("cardDesk-" + cards[k].replace(" ", ""));
        k++;
      }
    }
  }
  /*== ACTION TABLE end ==*/

  /*== POT CALC==*/
  pot_calculate(oldStep: string, newStep: string, playerValue?) {
    var audio = new Audio("../assets/sound/pot_calc.wav");
    audio.play();

    let total_val: any = 0;
    let pot_top = document.getElementById("pot-position")!.offsetTop;
    let pot_left = document.getElementById("pot-position")!.offsetLeft;
    var current_player_val: any = 0;
    var newId;
    let clone: HTMLDivElement;
    var current_total: any = 0;

    const area_item_length = this.area_item.length;
    for (var i = 0; i < area_item_length; ++i) {
      var item = this.area_item[i];

      if (
        typeof item.querySelectorAll(".total-chips-value")[0] != "undefined" &&
        item.querySelectorAll(".total-chips-value")[0] != null &&
        item.querySelectorAll(".total-chips-value")[0] != 0 &&
        item.style.opacity == "1"
      ) {

        newId = item.getAttribute("id") + "-clone-" + item.getAttribute("data-player");
        if (document.getElementById(newId)) {
          document.getElementById(newId)!.remove();
        }
        clone = item.cloneNode(true);

        // Change the id attribute of the newly created element:
        clone.setAttribute("id", newId);
        clone.classList.add("area-item-clone")
        clone.setAttribute("data-step", newStep);
        clone.setAttribute("data-clone", "clone");
        clone.innerHTML = '<div class="total-chips-value" data-value="0"></div>';
        //append new element clone
        document.getElementById("players")!.querySelectorAll(".t-row")[0].appendChild(clone);
        current_player_val = item.querySelectorAll(".total-chips-value")[0].getAttribute("data-value").replace(",", "");
        total_val = parseFloat(total_val) + parseFloat(item.querySelectorAll(".total-chips-value")[0].getAttribute("data-value").replace(",", ""));

        // item.style.top = 'calc(' + pot_top + 'px - 35px)';
        // item.style.left = 'calc(' + pot_left + 'px - 35px)';
        item.style.margin = 0;
        item.style.opacity = "0";
      }
    }

    // this.cleanAreaItem();

    current_total = document.getElementById("pot-totals")!.getAttribute("data-total").replace(",", "");
    total_val = parseFloat(total_val) + parseFloat(current_total);
    if (playerValue == undefined || parseInt(playerValue) < 0) {
      document.getElementById("pot-totals")!.innerHTML = this.toFixedIfNecessary(total_val < 0 ? 0 : total_val);
      document.getElementById("pot-totals")!.setAttribute("data-total", this.toFixedIfNecessary(total_val < 0 ? 0 : total_val));
      document.getElementById("pot-position")!.style.opacity = "1";
    } else {
      document.getElementById("pot-totals")!.innerHTML = this.toFixedIfNecessary(playerValue < 0 ? 0 : playerValue);
      document.getElementById("pot-totals")!.setAttribute("data-total", this.toFixedIfNecessary(playerValue < 0 ? 0 : playerValue));
      document.getElementById("pot-position")!.style.opacity = "1";
      setTimeout(() => {
        document.getElementById("pot-totals")!.innerHTML = this.toFixedIfNecessary(total_val < 0 ? 0 : total_val);
        document.getElementById("pot-totals")!.setAttribute("data-total", this.toFixedIfNecessary(total_val < 0 ? 0 : total_val));
      }, 380);

    }
  }

  pot_calculate_reverse(oldStep: string, newStep: string, value_round?: number) {
    // alert('OLD:' + oldStep + ' NEW:' + newStep)

    var audio = new Audio("../assets/sound/pot_calc.wav");
    audio.play();

    var area_item = document.getElementsByClassName("area-item");

    var total_val: any = 0;
    var current_player_val: any = 0;
    var current_total: any = 0;

    for (var i = 0; i < area_item.length; ++i) {
      var item: any = area_item[i];

      if (
        typeof item.querySelectorAll(".total-chips-value")[0] != "undefined" &&
        item.querySelectorAll(".total-chips-value")[0] != null &&
        item.querySelectorAll(".total-chips-value")[0] != 0 &&
        item.getAttribute("data-step") === oldStep
      ) {
        current_player_val = item.querySelectorAll(".total-chips-value")[0].getAttribute("data-value").replace(",", "");
        total_val = total_val + parseFloat(current_player_val);

        item.style.top = item.getAttribute("data-start-top");
        item.style.left = item.getAttribute("data-start-left");
        item.style.marginLeft = item.getAttribute("data-start-mleft");
        item.style.marginRight = item.getAttribute("data-start-mright");
        item.style.opacity = "1";
      }
    }

    current_total = document.getElementById("pot-totals")!.getAttribute("data-total").replace(",", "");
    total_val = parseFloat(current_total) - parseFloat(total_val);
    if (value_round) {
      total_val = value_round;
    }
    document.getElementById("pot-totals")!.innerHTML = this.toFixedIfNecessary(total_val < 0 ? 0 : total_val);
    document.getElementById("pot-totals")!.setAttribute("data-total", this.toFixedIfNecessary(total_val < 0 ? 0 : total_val));
    //document.getElementById('pot-position').removeAttribute("style");
  }
  /*== POT CALC end ==*/

  /*== COLLECTED_FROM_POT==*/
  collected_pot(playerName: string, value: any) {
    var audio = new Audio("../assets/sound/pot_calc.wav");
    audio.play();
    var potPosition = document.getElementById("pot-position");
    potPosition.style.transition = " all .5s ease";


    var player_box = document.getElementsByClassName("player");

    for (var i = 0; i < player_box.length; ++i) {
      if (player_box[i].getAttribute("data-player") == playerName) {
        var item: any = player_box[i];
        var potPosition = document.getElementById("pot-position");

        this.action_calculation_reverse(item, value);

        var playerTopPosition = item.offsetTop;
        var playerLeftPosition = item.offsetLeft;

        potPosition.style.opacity = "0";
        potPosition.style.top = playerTopPosition + "px";
        potPosition.style.left = playerLeftPosition + "px";
        potPosition.style.margin = "0";
      }
    }
  }

  collected_pot_reverse(playerName: string, value: any) {
    var audio = new Audio("../assets/sound/pot_calc.wav");
    audio.play();

    var player_box = document.getElementsByClassName("player");

    for (var i = 0; i < player_box.length; ++i) {
      if (player_box[i].getAttribute("data-player") == playerName) {
        var item = player_box[i];

        this.action_calculation(item, value);

        var potPosition = document.getElementById("pot-position");
        potPosition!.removeAttribute("style");
        potPosition!.style.marginTop = "-100px";
      }
    }
  }

  toFixedIfNecessary(value): string {
    if (Number.isInteger(value) && this.decimalPlaces == 3) {
      return value.toLocaleString("en-US");
    } else if (Number.isInteger(value)) {
      return value;
    } else {
      return parseFloat(value).toFixed(2);
    }
  }
  /*== COLLECTED_FROM_POT end==*/

  /*== SHOWS ==*/
  cards_shows(playerName: string, cards: string[]) {
    var audio = new Audio("../assets/sound/single_card.wav");

    audio.play();
    setTimeout(function () {
      audio.pause();
      audio.play();
    }, 150);

    playerName = playerName?.replace(" (big blind)", "")?.replace(" (small blind)", "")?.replace(" (button)", "");
    let card1 = "cardDesk-" + cards[0]?.replace(" ", "");
    let card2 = "cardDesk-" + cards[1]?.replace(" ", "");


    var player_hand = document.getElementsByClassName("player-hand");

    for (var i = 0; i < player_hand.length; ++i) {
      var item = player_hand[i];

      if (item.getAttribute("data-player") == playerName) {
        item.querySelectorAll(".flip-cardDesk")[0].classList.add("action-flip");
        item.querySelectorAll(".flip-cardDesk")[0].querySelectorAll(".flip-cardDesk-back")[0]
          .querySelectorAll(".cardDesk")[0].classList.add(card1);

        item.querySelectorAll(".flip-cardDesk")[1].classList.add("action-flip");
        item.querySelectorAll(".flip-cardDesk")[1].querySelectorAll(".flip-cardDesk-back")[0]
          .querySelectorAll(".cardDesk")[0].classList.add(card2);
      }
    }
  }

  cards_shows_reverse(playerName: string, cards: string[]) {
    var audio = new Audio("../assets/sound/single_card.wav");

    audio.play();
    setTimeout(function () {
      audio.pause();
      audio.play();
    }, 150);

    playerName = playerName?.replace(" (big blind)", "")?.replace(" (small blind)", "")?.replace(" (button)", "");
    let card1 = "cardDesk-" + cards[0].replace(" ", "");
    let card2 = "cardDesk-" + cards[1].replace(" ", "");

    var player_hand = document.getElementsByClassName("player-hand");

    for (var i = 0; i < player_hand.length; ++i) {
      var item = player_hand[i];
      if (item.getAttribute("data-player") == playerName && item.getAttribute("id") != "cards-1") {
        item.querySelectorAll(".flip-cardDesk")[0].classList.remove("action-flip");
        item.querySelectorAll(".flip-cardDesk")[0].querySelectorAll(".flip-cardDesk-back")[0]
          .querySelectorAll(".cardDesk")[0].classList.remove(card1);

        item.querySelectorAll(".flip-cardDesk")[1].classList.remove("action-flip");
        item.querySelectorAll(".flip-cardDesk")[1].querySelectorAll(".flip-cardDesk-back")[0]
          .querySelectorAll(".cardDesk")[0].classList.remove(card2);
      }
    }
  }
  /*== SHOWS end ==*/

  revisaoAtual() {
    var revisao = Number(document.getElementById("revisao-atual")?.textContent);
    if (revisao != 0) revisao = revisao - 1;
    return revisao;
  }
}
