import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MyGroup } from 'app/pages/full-pages/imports-api-group/Model/MyGroup';
import { UserCard } from 'app/pages/full-pages/imports-api-group/Model/UserCard';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-modal-delete-participant-group',
  templateUrl: './modal-delete-participant-group.component.html',
  styleUrls: ['./modal-delete-participant-group.component.scss']
})
export class ModalDeleteParticipantGroupComponent implements OnInit {

  @Input('visible') visible: boolean;
  @Output('visibleChange') visibleChange: any = new EventEmitter();
  @Input('participantData') participantData?: any;
  @Output('confirmModalEvent') confirmModalEvent?: any = new EventEmitter();

  userName: UserCard = new UserCard

  private readonly _HTML_BODY = document.querySelector("body");

  constructor(private _deviceDetectorService: DeviceDetectorService) { }

  ngOnInit(): void {
    this.visible = false;
  }

  get isVisible(): boolean {
    this._enableCustomClass();
    return this.visible === true;
  }

  public closeModal(): any {
    this.visible = false;
    this._HTML_BODY.classList.remove('custom-z-index-modal');
    this.visibleChange.emit(this.visible);
  }

  public confirmOptionModal(user): any {
    this._HTML_BODY.classList.remove('custom-z-index-modal');
    this.confirmModalEvent.emit(true);
    console.log(user);
    this.userName.name
    console.log(this.userName.name);
    
  }

  private _enableCustomClass(): any {

    if(this.visible && this.visible === true){
      this._scrollBotoomOnlyMobile();
      this._HTML_BODY.classList.add('custom-z-index-modal');
    }else{
      this._HTML_BODY.classList.remove('custom-z-index-modal')
    }
  }

  private _scrollBotoomOnlyMobile(){
    const userAgentIphone = window.navigator.userAgent.includes('Mac');
    if(this._deviceDetectorService.isMobile() && userAgentIphone){
      this._HTML_BODY.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
    }
  }


}
