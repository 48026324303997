import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { notification, userNotification } from 'app/pages/full-pages/homepage-timeline/userNotification';
import { NotificationService } from 'app/shared/shared/notification-service';
import { StorageUtils } from 'app/utils/storage-utils';
import { DatePipe } from '@angular/common';
import { isNullOrUndefined } from '@swimlane/ngx-datatable';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-notifications-community',
  templateUrl: './notifications-community.component.html',
  styleUrls: ['./notifications-community.component.scss'],
  providers: [
    NotificationService,DatePipe]
})
export class NotificationsCommunityComponent implements OnInit {

  @Input('notificationToRead') notificationToRead?: notification[];
  @Input('notificationRead') notificationRead?: notification[];
  @Input('qtdNotification') qtdNotification?: Number;

  constructor(
    private notificationService: NotificationService,
    private storage: StorageUtils,
    public datepipe: DatePipe,
    private cdRef: ChangeDetectorRef,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.cdRef.detectChanges()

  }

  ngOnDestroy() {

  }

  dateFormated(date): any{
    const d = new Date(Date.parse(date));
    const newD = d.setTime(d.getTime() - 3*60*60*1000);
    // console.log('ees2', newD, d.getTime(), (new Date().getHours() - new Date().getUTCHours())*60*60*1000);
    return this.datepipe.transform(newD, 'dd/MM/yyyy HH:mm');
    // return this.datepipe.transform(date, 'dd/MM/yyyy HH:mm')
  }



  mountTranslate(message: string) {
    try {
      const tmpMessage = JSON.parse(message);
      if(isNullOrUndefined(tmpMessage.key)) return message;
      return this.translateService.instant(tmpMessage.key, tmpMessage.interpolateParams);
    } catch (error) {
      return message;
    }
  }
}
