<div class="modal-container-custom" *ngIf="isVisible">
  <div class="modal-body-custom animate__animated" [ngClass]="{'animate__bounceIn': isVisible, 'animate__bounceOut': !isVisible}">
    <!-- <div class="modal-header-custom">
      <p>Não encontramos nenhum usuário registrado no e-mail <span>{{memberDataValue}}</span></p>
    </div> -->
    <div class="modal-content-custom text-center">

      <div class="post-media share-options-container">

           <div class="share-room-details">
            <p>
              {{ 'deseja_compartilhar_esse_estudo' | translate }} <b>{{ 'agora' | translate }}</b>
            </p>
          </div>

          <div  class="share-options-content">
            <button *ngIf="!isIndividual" class="btn btn-custom btn-cta" (click)="setUploadCapa(false)">{{ 'para_o_grupo' | translate }}
              <br> <span>{{ 'compartilhar_o_conteudo_para_o_grupo_de_estudos' | translate }}</span>
            </button>
            <button class="btn btn-custom btn-cta" (click)="setUploadCapa(true)">{{ 'publico' | translate }} <br>
              <span>{{ 'compartilhar_o_conteudo_estudado_no_feed' | translate }}</span>
            </button><br>
              <button *ngIf="!showUploadCapa && sharedType == 'GRUPO' "  class="btn btn-custom btn-cta" (click)="confirmOptionModal('GRUPO')">{{ 'compartilhar' | translate }} <br>
              </button>
            <!-- <button class="btn btn-custom btn-cta" (click)="confirmOptionModal('Privado')">Privado <br>
              <span>Compartilhar o conteúdo apenas na sua biblioteca</span>
            </button> -->
          </div>
              <div  *ngIf="showUploadCapa && sharedType == 'PÚBLICO' "  class="share-options-content">
                <span>{{ 'selecione_uma_imagem_de_capa_para_o_seu_post' | translate }}</span>
                <input  mat-raised-button   accept="image/png, image/jpeg"  type="file" id="files"   class="btn btn-custom btn-cta" (change)="onFileSelected($event)"/><br>
                <button class="btn btn-custom btn-cta " (click)="confirmOptionModal('PÚBLICO')">{{ 'postar' | translate }} <br>
                </button>
            </div>
      </div>


    </div>
    <div class="modal-footer-custom text-center">
      <button *ngIf="originCall=='library'" class="btn btn-custom btn-modal-cancel mr-3" (click)="closeModal()"> {{ 'fechar' | translate }} </button>
      <button *ngIf="originCall!='library'" class="btn btn-custom btn-modal-confirm" (click)="confirmOptionModal('Privado')"> {{ 'compartilhar_depois' | translate }}</button>
    </div>
  </div>

</div>
