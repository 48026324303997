import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Plan } from 'app/pages/plan-api/plan-model';
import { Constants } from 'app/utils/constants';
import { StorageUtils } from 'app/utils/storage-utils';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subscription } from 'rxjs';

import { customAnimations } from '../animations/custom-animations';
import { HROUTES } from '../horizontal-menu/navigation-routes.config';
import { ConfigService, ITemplateConfig } from '../services/config.service';
import { LayoutService } from '../services/layout.service';
import { ROUTES } from './vertical-menu-routes.config';

@Component({
  selector: "app-sidebar",
  templateUrl: "./vertical-menu.component.html",
  animations: customAnimations
})
export class VerticalMenuComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('toggleIcon') toggleIcon: ElementRef;
  public menuItems: any[];
  level: number = 0;
  // logoUrl = 'assets/img/logo.png';
  // public readonly defaultLogoSmall = 'assets/img/logos/logo-good-poker-small.png';
  // public readonly defaultLogo = 'assets/img/logos/logo-good-poker.png';
  @Input() logoUrl;
  public config: ITemplateConfig = {} as ITemplateConfig;
  protected innerWidth: any;
  layoutSub: Subscription;
  getHistoryPlan: '';
  configSub: Subscription;
  perfectScrollbarEnable = true;
  collapseSidebar = false;
  resizeTimeout;
  plan: any;
  urlSalesBase = Constants.SALES_PROJECT;

  private getEmail: string;

  constructor(
    private router: Router,
    public translate: TranslateService,
    private layoutService: LayoutService,
    private storage: StorageUtils,
    private configService: ConfigService,
    private cdr: ChangeDetectorRef,
    private deviceService: DeviceDetectorService
  ) {
    this.config = this.configService.templateConf;
    this.innerWidth = window.innerWidth;
    this.isTouchDevice();
  }


  ngOnInit() {
    this.menuItems = ROUTES;

    // this.activeCompactMenu();

  }


  ngAfterViewInit() {
    this.configSub = this.configService.templateConf$.subscribe((templateConf) => {
      console.log(templateConf);
      if (templateConf) {
        // this.config = templateConf;
        this.config = Object.assign({}, templateConf);
      }
      // if (!this.deepEqualObject(templateConf, this.config)) {
      if (JSON.stringify(templateConf)!==JSON.stringify(this.config)) {
        this.activeCompactMenuForRoutes();
        this.loadLayout();
      }
      this.cdr.markForCheck();
    });

    this.layoutSub = this.layoutService.overlaySidebarToggle$.subscribe(
      collapse => {
        if (this.config.layout.menuPosition === "Side") {
          this.collapseSidebar = collapse;
        }
      });

    setTimeout(() => {
      this.cdr.detectChanges();
    }, 500);

  }


  @HostListener('window:resize', ['$event'])
  onWindowResize(event) {
    if (this.resizeTimeout) {
      clearTimeout(this.resizeTimeout);
    }
    this.resizeTimeout = setTimeout((() => {
      this.innerWidth = event.target.innerWidth;
      this.loadLayout();
    }).bind(this), 500);
  }

  activeCompactMenuForRoutes(): any {

    let conf = this.config;
    // conf.layout.sidebar.collapsed = true;
    // console.log(this.router.url);
    this.router.url === '/pages/home/compact-menu' ? conf.layout.sidebar.collapsed = true : conf.layout.sidebar.collapsed = false;
    this.configService.applyTemplateConfigChange({ layout: conf.layout });

  }

  loadLayout() {

    if (this.config.layout.menuPosition === "Top") { // Horizontal Menu
      if (this.innerWidth < 1200) { // Screen size < 1200
        this.menuItems = HROUTES;
      }
    }
    else if (this.config.layout.menuPosition === "Side") { // Vertical Menu{
      this.menuItems = ROUTES;
    }




    if (this.config.layout.sidebar.backgroundColor === 'white') {
      this.logoUrl = 'assets/img/logo-dark.png';
    }
    else {
      // this.logoUrl = 'assets/img/logo.png'
      // this.logoUrl = this.defaultLogo;

    }

    if (this.config.layout.sidebar.collapsed) {
      this.collapseSidebar = true;
      // this.logoUrl = this.defaultLogo;
    }
    else {
      this.collapseSidebar = false;
      // this.logoUrl = this.defaultLogo;
    }
  }

  toggleSidebar() {
    let conf = this.config;
    conf.layout.sidebar.collapsed = !this.config.layout.sidebar.collapsed;
    if (conf.layout.sidebar.collapsed) {
      document.getElementById("sidebar-full-layout").classList.add("app-sidebarW60");
      setTimeout(() => {
        document.getElementById("sidebar-full-layout").classList.remove("app-sidebarW60");
      }, 500);
    }
    this.configService.applyTemplateConfigChange({ layout: conf.layout });

    setTimeout(() => {
      this.fireRefreshEventOnWindow();
    }, 300);
  }


  deepEqualObject(x, y) {
    return (x && y && typeof x === 'object' && typeof y === 'object') ?
      (Object.keys(x).length === Object.keys(y).length) &&
      Object.keys(x).reduce(function (isEqual, key) {
        return isEqual && this.deepEqual(x[key], y[key]);
      }, true) : (x === y);
  }

  fireRefreshEventOnWindow = function () {
    const evt = document.createEvent("HTMLEvents");
    evt.initEvent("resize", true, false);
    window.dispatchEvent(evt);
  };

  CloseSidebar() {
    this.layoutService.toggleSidebarSmallScreen(false);
  }

  isTouchDevice() {

    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();

    if (isMobile || isTablet) {
      this.perfectScrollbarEnable = false;
    }
    else {
      this.perfectScrollbarEnable = true;
    }

  }


  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
    if (this.configSub) {
      this.configSub.unsubscribe();
    }

  }

  navigateByUrl(url: string) {
    const urlBase = '/pages/';
    return this.router.navigateByUrl(urlBase + url);
  }
  goToUrlUpgradePlan() {
    var url = Constants.SALES_PROJECT + '?emailuser=';
    window.open(url + `${this.storage.getEmail()}`);
  }

  get currentUserPlan(): Plan {
    return this.storage.getUserPlan();

  }

}
