import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-modal-member-not-found',
  templateUrl: './modal-member-not-found.component.html',
  styleUrls: ['./modal-member-not-found.component.scss']
})
export class ModalMemberNotFoundComponent implements OnInit {

  @Input('visible') visible: boolean;
  @Output('visibleChange') visibleChange: any = new EventEmitter();
  @Input('memberData') memberData?: any;
  @Output('confirmModalEvent') confirmModalEvent?: any = new EventEmitter();

  private readonly _HTML_BODY = document.querySelector("body");

  constructor(private _deviceDetectorService: DeviceDetectorService) { }

  ngOnInit(): void {
    this.visible = false;
    this._enableCustomClass();
  }

  get memberDataValue(): any {
    return this.memberData || '';
  }

  get isVisible(): boolean {
    this._enableCustomClass();
    return this.visible === true;
  }

  public closeModal(): any {
    this.visible = false;
    this._HTML_BODY.classList.remove('custom-z-index-modal');
    this.visibleChange.emit(this.visible);
  }

  public confirmOptionModal(): any {
    this._HTML_BODY.classList.remove('custom-z-index-modal');
    this.confirmModalEvent.emit(true);
  }

  private _enableCustomClass(): any {

    if(this.visible && this.visible === true){
      this._HTML_BODY.classList.add('custom-z-index-modal');
    }else{
      this._HTML_BODY.classList.remove('custom-z-index-modal')
    }
  }

  private _scrollBotoomOnlyMobile(){
    const userAgentIphone = window.navigator.userAgent.includes('Mac');
    if(this._deviceDetectorService.isMobile() && userAgentIphone){
      this._HTML_BODY.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
    }
  }

  // private _enableCustomClass(): any {
  //   return this.visible && this.visible === true ? this._HTML_BODY.classList.add('custom-z-index-modal') : this._HTML_BODY.classList.remove('custom-z-index-modal');
  // }

}
