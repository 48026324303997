import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JsonContainer } from 'app/utils/json-container';
import { RestService } from 'app/utils/rest-service';
import { StorageUtils } from 'app/utils/storage-utils';
import { VideoEvent } from '../model/VideoEvent';

@Injectable({
  providedIn: 'root'
})
export class VideoService extends RestService {

  constructor(private client: HttpClient, private storageUtils: StorageUtils) {
    super(client, 'videoEvents', storageUtils);
  }

  save(videoEvent: VideoEvent, callback: (result: JsonContainer, video: any) => void) {
    this.executePost('save', videoEvent, callback);
  }

  load(id: string, callback: (result: JsonContainer, video: any) => void) {
     this.executeGet('load/' + id, callback);
  }

}
