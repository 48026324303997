import { HandHistory } from "app/pages/full-pages/hh/hh/hand-history";
import { MyGroup } from "app/pages/full-pages/imports-api-group/Model/MyGroup";
import { Notes } from "./Notes";

export class StudySession {
  id: string;

  studyTitle: string;

  status: string; //verificar com o tonico

  hhStudy: HandHistory[];

  idUser: string;

  nameUser: string;

  createDate: Date;

  deadline: string;

  startline: string;

  endline: string;

  groupStudy: MyGroup;

  downloadAvaible: boolean;

  studyNotes: Array<Notes>;

  idChat: string;

  videoUrl: string;

  individualStudy: boolean;

  sharedType: string;
}
