import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { SectionStudyService } from 'app/pages/full-pages/section-study/section-study.service';

import { ControllersComponent } from './controllers/controllers.component';
import { DeskComponent } from './desk/desk.component';
import { HandleHistory } from './types/desk-type';

@Component({
  selector: "app-replayer-table",
  templateUrl: "./replayer-table.component.html",
  styleUrls: ["./replayer-table.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ReplayerTableComponent implements OnChanges {
  @Input() handleHistory: HandleHistory[] = [];
  @Input() studyTitle: string = "";
  @Input() showCloseStudySession: boolean = false;
  @ViewChild(DeskComponent) deskComponent: DeskComponent;
  @ViewChild(ControllersComponent) controllersComponent: ControllersComponent;
  isVisible: boolean = true;

  constructor(private router: Router, private cdRef: ChangeDetectorRef, public sectionStudyService: SectionStudyService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.handleHistory) {
      this.cdRef.markForCheck();
      if (this.handleHistory.length > 0) {
        this.cdRef.markForCheck();
      }
    }
  }

  modalChangeVisible(typeModal: "confirm" | "wait"): void {
    this.sectionStudyService.showModalConfirm = true;
    this.cdRef.markForCheck();

    // if(typeModal=='confirm')
    //   this.sectionStudyService.showModalConfirm = true;
    // if(typeModal=='wait')
    //   this.sectionStudyService.showModalWait = true;
    // console.log(this.sectionStudyService.showModalConfirm, this.sectionStudyService.showModalWait);
  }

  deleteList(typeSkyp: "skipNext" | "skipPrev"): void {
    this.resetChildForm();
    if (typeSkyp == "skipNext") {
      this.sectionStudyService.currentIndex += 1;
      this.sectionStudyService.currentIndexBehavior.next(this.sectionStudyService.currentIndex);
    } else {
      this.sectionStudyService.currentIndex -= 1;
      this.sectionStudyService.currentIndexBehavior.next(this.sectionStudyService.currentIndex);
    }
    this.deskComponent.destroyList();
    this.restartTable();
    setTimeout(() => {
      this.deskComponent.getListPosition();
      this.controllersComponent.reloadProps();
    }, 600);
  }
  resetChildForm() {
    this.isVisible = false;
    setTimeout(() => {
      this.isVisible = true;
      this.cdRef.markForCheck();
    }, 100);
  }
  restartTable() {
    setTimeout(() => {
      this.deskComponent.currentIndex = this.sectionStudyService.currentIndex;
      this.controllersComponent.currentArray = this.sectionStudyService.currentIndex;
      this.cdRef.markForCheck();
    }, 100);
  }

  toFixedIfNecessary(value): string {
    if (Number.isInteger(value) && this.handleHistory[this.sectionStudyService.currentIndex].platform == "GG_NETWORK") {
      return value.toLocaleString("en-US");
    } else if (Number.isInteger(value)) {
      return value;
    } else {
      return parseFloat(value).toFixed(2);
    }
  }
}
