<div class="modal-container-custom" *ngIf="isVisible">
  <div class="modal-body-custom animate__animated"
    [ngClass]="{'animate__bounceIn': isVisible, 'animate__bounceOut': !isVisible}">
    <!-- <div class="modal-header-custom">
      <p>Não encontramos nenhum usuário registrado no e-mail <span>{{memberDataValue}}</span></p>
    </div> -->
    <div class="modal-content-custom text-center">

      <div class="post-media" >
        <video id="watchVideoModal" (play)="expandedVideo()" oncontextmenu="return false;" controls="controls" width="100%" height="100%" controls="controls" controlsList="nodownload"  preload="metadata"  >

          <source src="{{videoData?.urlVideo}}" type="video/mp4">
          Seu navegador não suporta arquivos de vídeo.
        </video>
      </div>

      <!-- <p>
        Não encontramos nenhum uruário  <span>Danilo Soares</span> participante do grupo <span>GoodPoker Help</span> já fez:
      </p> -->

      <!-- <p>
       Se você gostaria de ter esse usuário na flataforma, você precisa enviar um convite primeiro:
      </p> -->

      <!-- <div class="member-actions">
        <div class="member-action-item">
          <img src="/assets/img/icons/comments-group-icon.svg" alt="comentários no chat" srcset="">
          <div class="content">
            <span class="description">Comentários no chat: </span>
            <span class="value">405</span>
          </div>
        </div>

        <div class="member-action-item">
          <img src="/assets/img/icons/review-group-icon.svg" alt="revisões de estudos" srcset="">
          <div class="content">
            <span class="description">Revisões de Estudos: </span>
            <span class="value">8</span>
          </div>
        </div>
      </div> -->

    </div>
    <div class="modal-footer-custom text-center">
      <button class="btn btn-custom btn-modal-cancel mr-3" (click)="closeModal()"> fechar </button>
      <!-- <button class="btn btn-custom btn-modal-confirm" (click)="confirmOptionModal()"> Compartilhar </button> -->
    </div>
  </div>

</div>
