export interface Date {
  $date: any;
}

export interface HhPlayer {
  bigBlind?: any;
  chips: number;
  desc?: string;
  gamePosition: string;
  hand: string[];
  nickname: string;
  position: number;
  smallBlind?: any;
  ante?: any;
  blind100?: any;
  blind?: any;
  blindInvest?: any;
  blindWin?: any;
  sumAnte?: any;
}

export interface Player {
  bigBlind?: any;
  chips: number;
  desc?: string;
  gamePosition: string;
  hand?: string[];
  nickname: string;
  position: number;
  smallBlind?: any;
  ante?: any;
  blind?: any;
  blind100?: any;
  blindInvest?: any;
  blindWin?: any;
  sumAnte?: any;
}

export interface Round {
  action: RoundAction;
  index: number;
  player?: string;
  step: any;
  value?: number;
  cards?: string[];
  desc?: string;
  fgAllIn: boolean;
  total_pot?: number;
  total_chips_value?: number;
  player_total_value?: number;
  stepOld?: string;
}
export enum RoundAction {
  FOLDS = "FOLDS",
  CALLS = "CALLS",
  RAISES = "RAISES",
  FLOP = "FLOP",
  CHECKS = "CHECKS",
  BETS = "BETS",
  TURN = "TURN",
  RIVER = "RIVER",
  UNCALLED_BET = "UNCALLED_BET",
  DOENST_SHOW_HAND = "DOENST_SHOW_HAND",
  SHOWS = "SHOWS",
  MUCKED = "MUCKED",
  MUCKS = "MUCKS",
  CASHED_OUT = "CASHED_OUT",
  COLLECTED_FROM_POT = "COLLECTED_FROM_POT",
  CALCULATE = "CALCULATE",
  WINS = "WINS"
}


export interface HandleHistory {
  _id?: string;
  id?: any;
  buyin: number;
  cash: string;
  categoryGame: string;
  communityCards?: string[];
  currency: string;
  date: any;
  hand: string;
  hhPlayer: HhPlayer;
  idUser: string;
  level: string;
  platform: string;
  playerHand: string;
  players: Player[];
  positions: number;
  rake?: number;
  rounds: Round[];
  stepFinishGame: string;
  table: string;
  totalPot: number;
  tournament: string;
  type: string;
  win: boolean;
}
