import { NgModule } from '@angular/core';
import {CommonModule} from "@angular/common";

import { FilterPipe } from './filter.pipe';
import { SearchPipe } from './search.pipe';
import { ShortNamePipe } from './short-name.pipe';
import { ArrayFilterPipe } from './array-filter.pipe';

@NgModule({
  declarations:[FilterPipe, SearchPipe, ShortNamePipe, ArrayFilterPipe],
  imports:[CommonModule],
  exports:[FilterPipe, SearchPipe, ShortNamePipe, ArrayFilterPipe]
})

export class PipeModule{}
