import { UserCard } from "../imports-api-group/Model/UserCard"

export class Post {

    creationDate: string

    gallery: Array<Gallery>

    id: String

    idObj: String

    status: String

    title: String

    userCreator: UserCard

    lastComments: CommentPost[]

    likes: any

    cpf: String

    urlVideo: String

    type?: any

    studySession?: any

    fgLiked: boolean = false

    thumbUrl: String 

}

export class Gallery {
    id: String;

    urlFile: String;
}

export class CommentPost {
    creationDate: String

    id: String

    idPost: String

    idUser: String

    nameUser: String

    status: String

    text: String

    info:Info
}

export class Info {
  urlImage: string;
  countryFlag: string;
}


export class Like {
    idObjectLiked: string;
    typeObjectLiked: string;
    idObjectLiker: string;
    typeObjectLiker: string;
    nameObjectLiker: string;
    nameObjectLiked: string;
    creationDate: string;
  }
