import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrayFilter'
})
export class ArrayFilterPipe implements PipeTransform {

  transform(value: any[], categoryGame: string) {
    console.log(categoryGame);

    let result;
    if (categoryGame == null) {
      result = value.filter(statsHand => statsHand.stats.hand > 0)
    }
    if (categoryGame == 'CASHGAME') {
      result = value.filter(cashGameStats => cashGameStats.cashGame.hand > 0)
    }
    if (categoryGame == 'TOURNAMENT') {
      result = value.filter(tournamentStats => tournamentStats.tournament.hand > 0)
    }

    return result;

  }

}
