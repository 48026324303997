<div class="upload-area">

  <div class="progress-container mb-3" *ngIf="uploading && uploader.isUploading">
    <div class="header">
      <span>{{ 'upload.permaneca_na_pagina_ate_o_fim_do_processo_de_upload' | translate }}.</span>
      <!-- <button class="btn-cancel-upload" (click)="cancelAllUploadItems()"><i class="fa fa-times"></i></button> -->
    </div>
    <div class="progress-status">
      <span class="percent">{{uploader.progress}}%</span>
      <div class="progress">
        <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': uploader.progress + '%' }"
          aria-valuenow="{{uploader.progress}}" aria-valuemin="0" aria-valuemax="100"></div>
      </div>
    </div>
  </div>

  <form class="form form-upload-files">
    <div class="form-group form-group-custom">
      <label class="label-upload-custom" ng2FileDrop [uploader]="uploader"
        [ngClass]="{'nv-file-over': hasBaseDropZoneOver}" (fileOver)="fileOverBase($event)" for="uploadFile">
        <span class="description-upload">{{ 'upload.solte_seu_hh_aqui_ou_selecione' | translate }}</span>
        <span class="description-file-supported">{{ 'upload.apenas_arquivos_txt' | translate }} </span>
      </label>
      <input type="file" accept=".txt" ng2FileSelect multiple [uploader]="uploader" name="" id="uploadFile"
        class="form-control d-none" (onFileSelected)="fileSelected($event)">
    </div>
  </form>

  <div class="row mt-4">
    <div class="col-md-12 text-center">
      <div class="platform-available">
        <div class="description">
          <span>{{ 'upload.plataformas_suportadas' | translate }}: </span>
        </div>
        <img src="/assets/img/logos/pokerstars-logo.png" alt="" srcset="">
        <img src="/assets/img/logos/partypoker-logo.png" alt="" srcset="">
        <!-- <img src="/assets/img/logos/bodog-logo.png" alt="" srcset=""> -->
        <!-- <img class="small-img-size" src="/assets/img/logos/888poker-logo.png" alt="" srcset=""> -->
        <!-- <img class="small-img-size" src="/assets/img/logos/winamax-logo.png" alt="" srcset=""> -->
        <img src="/assets/img/logos/wpn_logo.png" alt="" srcset="">
        <!-- <img src="/assets/img/logos/ipoker-logo.png" alt="" srcset=""> -->
        <img src="/assets/img/logos/logo-ggnetwork.png" alt="" srcset="">
        <!-- <img src="/assets/img/logos/chico-poker-logo.png" alt="" srcset=""> -->
      </div>
    </div>
  </div>

  <!-- <p>Queue progress:
    <ngb-progressbar type="primary" [value]="uploader.progress"></ngb-progressbar>
  </p> -->

</div>
