import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'app/pages/msk-user/user/user-service';
import { Plan } from 'app/pages/plan-api/plan-model';
import { Subscription } from 'rxjs';

import { StorageUtils } from '../../utils/storage-utils';
import { LISTITEMS } from '../data/template-search';
import { ConfigService } from '../services/config.service';
import { LayoutService } from '../services/layout.service';
import { NotificationService } from '../shared/notification-service';

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
  providers: [
    StorageUtils, NotificationService
  ],
})
export class NavbarComponent implements OnInit, AfterViewInit, OnDestroy {
  currentLang = "en";
  selectedLanguageText = "PT";
  selectedLanguageFlag = "./assets/img/flags/pt-br.png";
  toggleClass = "ft-maximize";
  placement = "bottom-right";
  logoUrl = 'assets/img/logo.png';
  menuPosition = 'Side';
  isSmallScreen = false;
  protected innerWidth: any;
  searchOpenClass = "";
  transparentBGClass = "";
  hideSidebar: boolean = true;
  public isCollapsed = true;
  layoutSub: Subscription;
  configSub: Subscription;
  userSub: Subscription;

  private _usernameLogedIn = "";
  private _useravatarLogedIn = '';
  private testeUrlImage: string;
  private _usernamePlan = "";
  private _usernickName = "";



  @ViewChild('search') searchElement: ElementRef;
  @ViewChildren('searchResults') searchResults: QueryList<any>;

  @Output()
  toggleHideSidebar = new EventEmitter<Object>();

  @Output()
  seachTextEmpty = new EventEmitter<boolean>();

  listItems = [];
  control = new FormControl();
  plan: Plan;

  public config: any = {};

  constructor(
    public translate: TranslateService,
    private layoutService: LayoutService,
    private router: Router,
    private configService: ConfigService,
    private cdr: ChangeDetectorRef,
    private storage: StorageUtils,
    private userService: UserService) {

    this.config = this.configService.templateConf;
    this.innerWidth = window.innerWidth;

    const language = localStorage.getItem("language");
    if (language) {
      this.ChangeLanguage(language);
      this.translate.use(language);
    } else {
      this.ChangeLanguage("pt");
      this.translate.use("pt");
      localStorage.setItem("language", "pt");
    }

    this.layoutSub = layoutService.toggleSidebar$.subscribe(
      isShow => {
        this.hideSidebar = !isShow;
      });

  }

  ngOnInit() {
    this.listItems = LISTITEMS;

    if (this.innerWidth < 1200) {
      this.isSmallScreen = true;
    }
    else {
      this.isSmallScreen = false;
    }


    this.userSub = this.userService.currentUser.subscribe((user) => {
      if (user != null) {
        console.log('New user is : ' + user.name);
        this._usernameLogedIn = user.name;
        this._usernamePlan = user.info.plan;

        this._useravatarLogedIn = user.urlImage;
        if (user.info.nickname != null && user.info.nickname != "") {
          this._usernickName = user.info.nickname;
        } else {
          var nameSplited = user.name.split(" ", 2);
          this._usernickName = nameSplited[0];
        }
        this.cdr.detectChanges();
      } else {

        this._usernameLogedIn = this.storage.getNameUser();
        this.plan = this.storage.getUserPlan();
        this._usernamePlan = this.plan?.name;


        this.testeUrlImage = this.storage.getUrlImage();

        if (this.storage.getUserNickName != null) {
          this._usernickName = this.storage.getUserNickName();
        } else {
          var name: string = this.storage.getNameUser();
          var nameSplited = name.split(" ", 2);
          this._usernickName = nameSplited[0];
        }

        if (this.testeUrlImage != 'undefined') {
          this._useravatarLogedIn = this.storage.getUrlImage();
        } else {
          this._useravatarLogedIn = "assets/img/portrait/small/avatar-s-27-base.jpeg";
        }

      }
    });




  }

  ngAfterViewInit() {

    this.configSub = this.configService.templateConf$.subscribe((templateConf) => {
      if (templateConf) {
        this.config = templateConf;
      }


      this.loadLayout();
      this.cdr.markForCheck();

    });
  }

  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
    if (this.configSub) {
      this.configSub.unsubscribe();
    }
    if (this.userSub) {
      this.userSub.unsubscribe();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = event.target.innerWidth;
    if (this.innerWidth < 1200) {
      this.isSmallScreen = true;
    }
    else {
      this.isSmallScreen = false;
    }
  }


  get currentUserName(): string {
    return this._usernameLogedIn;
  }

  get currentUserNickName(): string {
    return this._usernickName;
  }

  get currentUserPlan(): string {
    return this._usernamePlan;
  }

  get currentUserImage(): string {
    return this._useravatarLogedIn;
  }

  get sidebarMenuIsOpen(): boolean {
    return this.hideSidebar === true;
  }

  loadLayout() {

    if (this.config.layout.menuPosition && this.config.layout.menuPosition.toString().trim() != "") {
      this.menuPosition = this.config.layout.menuPosition;
    }

    if (this.config.layout.variant === "Light") {
      this.logoUrl = 'assets/img/logo-dark.png';
    }
    else {
      this.logoUrl = 'assets/img/logo.png';
    }

    if (this.config.layout.variant === "Transparent") {
      this.transparentBGClass = this.config.layout.sidebar.backgroundColor;
    }
    else {
      this.transparentBGClass = "";
    }

  }

  onSearchKey(event: any) {
    if (this.searchResults && this.searchResults.length > 0) {
      this.searchResults.first.host.nativeElement.classList.add('first-active-item');
    }

    if (event.target.value === "") {
      this.seachTextEmpty.emit(true);
    }
    else {
      this.seachTextEmpty.emit(false);
    }
  }

  removeActiveClass() {
    if (this.searchResults && this.searchResults.length > 0) {
      this.searchResults.first.host.nativeElement.classList.remove('first-active-item');
    }
  }

  onEscEvent() {
    this.control.setValue("");
    this.searchOpenClass = '';
    this.seachTextEmpty.emit(true);
  }

  onEnter() {
    if (this.searchResults && this.searchResults.length > 0) {
      let url = this.searchResults.first.url;
      if (url && url != '') {
        this.control.setValue("");
        this.searchOpenClass = '';
        this.router.navigate([url]);
        this.seachTextEmpty.emit(true);
      }
    }
  }

  redirectTo(value) {
    this.router.navigate([value]);
    this.seachTextEmpty.emit(true);
  }


  ChangeLanguage(language: string, reload?: boolean) {
    this.translate.setDefaultLang(language);
    this.translate.use(language);
    localStorage.setItem("language", language);

    if (language === 'pt') {
      this.selectedLanguageText = "pt";
      this.selectedLanguageFlag = "../../../../assets/img/flags/pt-br.png";
    } else {
      this.selectedLanguageText = language;
      this.selectedLanguageFlag = `../../../../assets/img/flags/${language}.png`;
    }
    if (reload) {
      location.reload();
    }
  }

  ToggleClass() {
    if (this.toggleClass === "ft-maximize") {
      this.toggleClass = "ft-minimize";
    } else {
      this.toggleClass = "ft-maximize";
    }
  }

  toggleSearchOpenClass(display) {
    this.control.setValue("");
    if (display) {
      this.searchOpenClass = 'open';
      setTimeout(() => {
        this.searchElement.nativeElement.focus();
      }, 0);
    }
    else {
      this.searchOpenClass = '';
    }
    this.seachTextEmpty.emit(true);



  }



  toggleNotificationSidebar() {
    this.layoutService.toggleNotificationSidebar(true);
  }

  toggleSidebar() {
    console.log('hideSidebar');
    console.log(this.hideSidebar);
    this.layoutService.toggleSidebarSmallScreen(this.hideSidebar);
  }

  logout() {
    this.storage.storeAutoLogin(null);
    this.userService.setCurrentUser(null);

  }
}
