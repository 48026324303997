<div class="centralize">
  <div class="vue-container">
    <div class="tableDesk">
      <div class="cardDesk-place area-tableDesk" id="cardDesk_place">
        <div class="flip-cardDesk" data-cardDesk="1">
          <div class="flip-cardDesk-inner">
            <div class="flip-cardDesk-front">
              <div class="cardDesk"></div>
            </div>
            <div class="flip-cardDesk-back">
              <div class="cardDesk"></div>
            </div>
          </div>
        </div>
        <div class="flip-cardDesk" data-cardDesk="2">
          <div class="flip-cardDesk-inner">
            <div class="flip-cardDesk-front">
              <div class="cardDesk"></div>
            </div>
            <div class="flip-cardDesk-back">
              <div class="cardDesk"></div>
            </div>
          </div>
        </div>
        <div class="flip-cardDesk" data-cardDesk="3">
          <div class="flip-cardDesk-inner">
            <div class="flip-cardDesk-front">
              <div class="cardDesk"></div>
            </div>
            <div class="flip-cardDesk-back">
              <div class="cardDesk"></div>
            </div>
          </div>
        </div>
        <div class="flip-cardDesk" data-cardDesk="4">
          <div class="flip-cardDesk-inner">
            <div class="flip-cardDesk-front">
              <div class="cardDesk"></div>
            </div>
            <div class="flip-cardDesk-back">
              <div class="cardDesk"></div>
            </div>
          </div>
        </div>
        <div class="flip-cardDesk" data-cardDesk="5">
          <div class="flip-cardDesk-inner">
            <div class="flip-cardDesk-front">
              <div class="cardDesk"></div>
            </div>
            <div class="flip-cardDesk-back">
              <div class="cardDesk"></div>
            </div>
          </div>
        </div>
      </div>


      <div id="players"  class="players-new">

      </div>
    </div>
  </div>
</div>
