import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-modal-share',
  templateUrl: './modal-share.component.html',
  styleUrls: ['./modal-share.component.scss']
})
export class ModalShareComponent implements OnInit {

  @Input('visible') visible: boolean;
  @Output('visibleChange') visibleChange: any = new EventEmitter();
  @Input('shareData') shareData?: any;
  @Output('confirmModalEvent') confirmModalEvent?: any = new EventEmitter();


  private readonly _HTML_BODY = document.querySelector("body");

  private showUploadCapa = false;
  private sharedType = 'None'; 
  private thumbnailFile: File[];

  constructor(private _deviceDetectorService: DeviceDetectorService) { }

  ngOnInit(): void {
    this.visible = false;
    this.showUploadCapa = false;
    this._enableCustomClass();
  }

  get isVisible(): boolean {
    this._enableCustomClass();
    return this.visible === true;
  }

  public setUploadCapa(value : boolean) {
    this._enableCustomClass();
    if(value == true){
      this.sharedType = 'PÚBLICO';
    }else{
      this.sharedType = 'GRUPO';
    }  
    this.showUploadCapa = value;
    console.log('setando '+ this.showUploadCapa )
  }

  public onFileSelected(event) {

    console.log(event)
    this.thumbnailFile = event.target.files[0];
    console.log('setando o file'+this.thumbnailFile )
  }

  get isIndividual(): boolean {
    return this.shareData["individualStudy"];
  }



  get originCall(): boolean {
    return this.shareData["originCall"];
  }

  get shareType(): boolean {
    return this.shareData["shareType"];
  }

  public closeModal(): any {
    this.visible = false;
    this._HTML_BODY.classList.remove('custom-z-index-modal');
    this.visibleChange.emit(this.visible);
  }

  public confirmOptionModal(shareType: string): any {
    this._HTML_BODY.classList.remove('custom-z-index-modal'); 
    console.log('event emmit type-->'+shareType);
    console.log('event emmit file-->'+this.thumbnailFile);
    this.confirmModalEvent.emit({shareType:shareType,thumbnailFile:this.thumbnailFile});
  }

  private _enableCustomClass(): any {

    if(this.visible && this.visible === true){
      this._scrollBotoomOnlyMobile();
      this._HTML_BODY.classList.add('custom-z-index-modal');
    }else{
      this._HTML_BODY.classList.remove('custom-z-index-modal')
    }
  }

  private _scrollBotoomOnlyMobile(){
    const userAgentIphone = window.navigator.userAgent.includes('Mac');
    if(this._deviceDetectorService.isMobile() && userAgentIphone){
      this._HTML_BODY.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
    }
  }

  // private _enableCustomClass(): any {
  //   return this.visible && this.visible === true ? this._HTML_BODY.classList.add('custom-z-index-modal') : this._HTML_BODY.classList.remove('custom-z-index-modal');
  // }

}
