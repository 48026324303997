export class User {
  id: string;
  name: string;
  email: string;
  phone: string;
  password: string;
  token: string;
  urlImage: string;
  birthDate: Date;
  birthDateStr: string;
  gender: string;
  lastLogin: Date;
  lastAddress:AddressInfo;
  info:Info;
}

export class AddressInfo {
    address: string;
    street: string;
    number: string;
    complement: string;
    reference: string;
    district: string;
    city: string;
    state: string;
    zipCode: string;
    country: string;
    firstLogin: Boolean;
}


export class Info {
  country: string;
  plan: string;
  nickname: string;
  notifications: string;
  freePeriodEnd: string;
  freePeriodStart: string;
  countryFlag: string;
  firstLogin:string

}


export class phone {
  number: any;
  internationalNumber: any;
  nationalNumber: any;
  e164Number: any;
  countryCode: any;
  dialCode: any;

}