import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CardRivalService } from 'app/pages/full-pages/hh/hh/card-rival-service';
import { HandHistoryService } from 'app/pages/full-pages/hh/hh/hand-history-service';
import { CardRivalRequest, CardRivalResponse, PositionStats } from 'app/pages/full-pages/hh/hh/ListPosition';

import { HandleHistory } from '../types/desk-type';

@Component({
  selector: 'app-desk',
  templateUrl: './desk.component.html',
  styleUrls: ['./desk.component.scss'],
  providers: [HandHistoryService, CardRivalService]
})
export class DeskComponent implements OnChanges {
  @Input() handleHistory: HandleHistory[] = [];
  currentIndex: number = 0;
  decimalPlaces: number = 2;
  listPositions: CardRivalResponse[] = [];
  plataform: string;

  constructor(private handHistoryService: HandHistoryService, private cardRivalService: CardRivalService) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.handleHistory) {
      this.getListPosition();
    }
  }

  getListPosition() {
    const playerRival = [];
    this.handleHistory[this.currentIndex].players.forEach(player => {
      const cardRivalRequest: CardRivalRequest = {
        idUser: this.handleHistory[this.currentIndex].idUser,
        gamePosition: player.gamePosition,
        namePlat: this.handleHistory[this.currentIndex].platform,
        namePlayer: player.nickname
      }
      playerRival.push(cardRivalRequest)
    });
    this.cardRivalService.listByIdUserGP(playerRival, (response, resp) => {
      if (response.success) {
        this.listPositions = resp;
      }
      if (this.handleHistory[this.currentIndex].categoryGame == "TOURNAMENT") {
        this.plataform = "tournament";
      } else {
        this.plataform = "cashGame";
      }
      this.initList();
    });
  }

  initList(): void {
    var currentIndex = this.currentIndex;
    let content_positions = '';
    let id = 1;
    let positionPlayers: number[] = [];
    let pLeft = '0';
    let pRight = '0';
    let pTop = '0';
    let mLeft = '0';
    let mRight = '0';

    //Area Positions
    let aLeft = '0';
    let aRight = '0';
    let aTop = '0';
    let aBottom = '0';


    totalPlayers = this.handleHistory[currentIndex].players.length;
    document.getElementById('gp-players-total-count')!.innerHTML = String(totalPlayers);

    switch (totalPlayers) {
      case 2:
        positionPlayers = [1, 7]
        break;
      case 3:
        positionPlayers = [1, 4, 7]
        break;
      case 4:
        positionPlayers = [1, 2, 4, 7]
        break;
      case 5:
        positionPlayers = [1, 2, 4, 7, 9]
        break;
      case 6:
        positionPlayers = [1, 2, 3, 4, 7, 9]
        break;
      case 7:
        positionPlayers = [1, 2, 4, 5, 7, 8, 9]
        break;
      case 8:
        positionPlayers = [1, 2, 3, 4, 5, 7, 8, 9]
        break;
      case 9:
        positionPlayers = [1, 2, 3, 4, 5, 6, 7, 8, 9]
        break;
    }

    if (this.handleHistory[currentIndex].platform == "GG_NETWORK") {
      this.decimalPlaces = 3;
    } else {
      this.decimalPlaces = 2;
    }

    content_positions += '<div class="t-row">';
    this.handleHistory[currentIndex].players.forEach((item, index) => {
      id = positionPlayers[index];
      //Reset default values
      pLeft = '0';
      pRight = '0';
      pTop = '0';
      mLeft = '0';
      mRight = '0';

      //Reset default values
      aLeft = '0';
      aRight = '0';
      aTop = '0';
      aBottom = '0';
      switch (totalPlayers) {
        case 7:
          switch (id) {
            case 2:
              pLeft = '0';
              pTop = '5%';

              aLeft = '25%';
              aTop = 'calc(25% + 10px)';
              break;
            case 3:
              pLeft = '25%';
              pTop = '-50px';

              aLeft = '35%';
              aTop = 'calc(25% - 25px)';
              break;
            case 4:
              pLeft = '50%';
              pTop = '-50px';

              aTop = 'calc(25% - 25px)';
              aLeft = '50%';
              break;
            case 5:
              pLeft = '75%';
              pTop = '5%';

              aLeft = 'calc(50% + 130px)';
              aTop = 'calc(25% - 25px)';
              break;
            case 6:
              pLeft = 'calc(0px)';
              pTop = '35%';

              aTop = '36%';
              aLeft = 'calc(25% - 40px)';
              break;
            case 7:
              pLeft = 'calc(75%)';
              pTop = '35%';

              aTop = '36%';
              aLeft = 'calc(56% + 100px)';
              break;
            default:
          }
          break;
        case 6:
          switch (id) {
            case 2:
              pLeft = '0';
              pTop = '15%';

              aTop = '30%';
              aLeft = '25%';
              break;
            case 3:
              pLeft = '25%';
              pTop = '-50px';

              aLeft = '35%';
              aTop = 'calc(25% - 25px)';
              break;
            case 4:
              pLeft = '75%';
              pTop = '15%';

              aTop = '30%';
              aLeft = 'calc(50% + 170px)';
              break;
            case 5:
              pLeft = '0';
              pTop = '50%';

              aTop = '50%';
              aLeft = '26%';
              break;
            case 6:
              pLeft = '75%';
              pTop = '50%';

              aTop = '50%';
              aLeft = 'calc(50% + 130px)';
              break;
            default:
          }
          break;
        case 5:
          switch (id) {
            case 2:
              pLeft = '0';
              pTop = '5%';

              aLeft = '25%';
              aTop = 'calc(25% + 10px)';
              break;
            case 3:
              pLeft = '75%';
              pTop = '5%';

              aLeft = 'calc(50% + 130px)';
              aTop = 'calc(25% - 25px)';
              break;
            case 4:
              pLeft = '0';
              pTop = '40%';

              aTop = '50%';
              aLeft = '26%';
              break;
            case 5:
              pLeft = '75%';
              pTop = '40%';

              aTop = '50%';
              aLeft = 'calc(50% + 130px)';
              break;
            default:
          }
          break;
        case 4:
          switch (id) {
            case 2:
              pLeft = 'calc(0px - 100px)';
              pTop = '35%';

              aTop = '36%';
              aLeft = 'calc(25% - 40px)';
              break;
            case 3:
              pLeft = '25%';
              pTop = '-50px';

              aLeft = '35%';
              aTop = 'calc(25% - 25px)';
              break;
            case 4:
              pLeft = 'calc(75% + 100px)';
              pTop = '35%';

              aTop = '36%';
              aLeft = 'calc(56% + 90px)';
              break;
            default:
          }
          break;
        case 3:
          switch (id) {
            case 2:
              pLeft = '0';
              pTop = '5%';

              aLeft = '25%';
              aTop = 'calc(25% + 10px)';
              break;
            case 3:
              pLeft = '75%';
              pTop = '5%';

              aLeft = 'calc(50% + 130px)';
              aTop = 'calc(25% - 25px)';
              break;
            default:
          }
          break;
        case 2:
          switch (id) {
            case 2:
              pLeft = '0';
              pTop = '5%';

              aLeft = '25%';
              aTop = 'calc(25% + 10px)';
              break;
            default:
          }
          break;
        default: //BTN
          switch (id) {
            case 2:
              pLeft = '0';
              pTop = '5%';

              aLeft = '25%';
              aTop = 'calc(25% + 10px)';
              break;
            case 3:
              pLeft = '25%';
              pTop = '-50px';

              aLeft = '35%';
              aTop = 'calc(25% - 25px)';
              break;
            case 4:
              pLeft = '50%';
              pTop = '-50px';

              aTop = 'calc(25% - 25px)';
              aLeft = '50%';
              break;
            case 5:
              pLeft = '75%';
              pTop = '5%';

              aLeft = 'calc(50% + 130px)';
              aTop = 'calc(25% - 25px)';
              break;
            case 6:
              pLeft = 'calc(0px - 100px)';
              pTop = '35%';

              aTop = '36%';
              aLeft = 'calc(25% - 40px)';
              break;
            case 7:
              pLeft = 'calc(75% + 100px)';
              pTop = '35%';

              aTop = '36%';
              aLeft = 'calc(56% + 90px)';
              break;
            case 8:
              pLeft = '0';
              pTop = '65%';

              aTop = '50%';
              aLeft = '26%';
              break;
            case 9:
              pLeft = '75%';
              pTop = '65%';

              aTop = '50%';
              aLeft = 'calc(50% + 130px)';
              break;
            default:
          }
      }
      // id == 1 hh-player
      if (id == 1) {
        //position
        content_positions += '<div id="position-' + 1 + '" class="player player-' + 1 + '"></div>';

        // //cards
        content_positions += '<div id="cards-' + 1 + '" class="player-hand hh-player"></div>';

        // //area action
        content_positions += `<div id="area-${1}" class="area-item hh-player" data-total-players="${totalPlayers}" data-step="HOLE_CARDS"><div class="total-chips-value" data-value="0"></div></div>`;
      } else {
        //position
        content_positions += '<div id="position-' + id + '" class="player player-' + id + '"  ></div>';

        //cards
        content_positions += '<div id="cards-' + id + '" class="player-hand"></div>';

        //area action
        content_positions += `<div id="area-${id}" class="area-item hh-player" data-total-players="${totalPlayers}" data-step="HOLE_CARDS"><div class="total-chips-value" data-value="0"></div></div>`;
      }
    })

    //HH position
    pRight = '0';
    pLeft = 'calc(25% + 100px)';
    pTop = '75%';
    mLeft = '0';
    mRight = '0';

    //Area Positions
    aLeft = 'calc(35% + 100px)';
    aRight = '0';
    aTop = 'calc(50% - 20px)';
    aBottom = '0';


    //position
    // content_positions += '<div id="position-' + 1 + '" class="player player-' + 1 + '"></div>';

    // //cards
    // content_positions += '<div id="cards-' + 1 + '" class="player-hand hh-player"></div>';

    // //area action
    // content_positions += '<div id="area-' + 1 + '" class="area-item hh-player" data-total-players="' + totalPlayers + '" data-step="HOLE_CARDS""><div class="total-chips-value" data-value="0"></div></div>';

    var total_post = 0;
    this.handleHistory[currentIndex].players.map((item) => {
      total_post += item.ante;
    })
    console.info(total_post)
    if (this.handleHistory[currentIndex].players[1].ante != 'undefined' && this.handleHistory[currentIndex].players[1].ante != null) {
      content_positions += '<div id="pot-position" style="opacity: 1; margin-top: -100px;">';
      content_positions += '<div class="pot-tableDesk">';
      content_positions += '<div class="area-chips justify-content-center">';
      content_positions += '<div class="blind small-blind">';
      content_positions += '<div class="chip chip-pot"></div>';
      content_positions += '<div class="chip chip-pot"></div>';
      content_positions += '<div class="chip chip-pot"></div>';
      content_positions += '<div class="chip chip-pot"></div>';
      content_positions += '</div>';
      content_positions += '</div>';
      content_positions += '</div>';
      content_positions += '<div class="total-pot-value">POT:<span id="pot-totals" data-total="' + this.toFixedIfNecessary(total_post) + '">' + this.toFixedIfNecessary(total_post) + '</span></div>';
      content_positions += '</div>';
      content_positions += '</div>';
    } else {
      content_positions += '<div id="pot-position">';
      content_positions += '<div class="pot-tableDesk">';
      content_positions += '<div class="area-chips justify-content-center">';
      content_positions += '<div class="blind small-blind">';
      content_positions += '<div class="chip chip-pot"></div>';
      content_positions += '<div class="chip chip-pot"></div>';
      content_positions += '<div class="chip chip-pot"></div>';
      content_positions += '<div class="chip chip-pot"></div>';
      content_positions += '</div>';
      content_positions += '</div>';
      content_positions += '</div>';
      content_positions += '<div class="total-pot-value">POT:<span id="pot-totals" data-total="0">0</span></div>';
      content_positions += '</div>';
      content_positions += '</div>';
    }


    document.getElementById('players')!.innerHTML = content_positions;

    let isDealer = '';
    let content = '';
    let position = 0;
    let elementId = '';
    let elementAreaId = '';
    var elArea: any;
    var elPosition;
    var nameWithSpace = '';
    var totalPlayers = 0;
    var smallAndBigBlindIndex = -1;
    var contentBlind = '';

    var p0 = 0, p2 = 2, p3 = 3, p4 = 4, p5 = 5, p6 = 6, p7 = 7, p8 = 8, p9 = 9;


    //Posicionamento game-position hhplayer
    if (this.handleHistory[currentIndex].hhPlayer) {
      var hhplayer_chips = this.handleHistory[currentIndex].hhPlayer.chips;

      if (this.handleHistory[currentIndex].hhPlayer.gamePosition == 'BTN') {
        isDealer = 'dealer-button';
      } else {
        isDealer = '';
      }

      //Ajusta placar
      if (this.handleHistory[currentIndex].hhPlayer.smallBlind != undefined && this.handleHistory[currentIndex].hhPlayer.smallBlind != null && this.handleHistory[currentIndex].hhPlayer.smallBlind > 0) {
        hhplayer_chips = (this.handleHistory[currentIndex].hhPlayer.chips - this.handleHistory[currentIndex].hhPlayer.smallBlind);
      } else if (this.handleHistory[currentIndex].hhPlayer.bigBlind != undefined && this.handleHistory[currentIndex].hhPlayer.bigBlind != null && this.handleHistory[currentIndex].hhPlayer.bigBlind > 0) {
        hhplayer_chips = (this.handleHistory[currentIndex].hhPlayer.chips - this.handleHistory[currentIndex].hhPlayer.bigBlind);
      }
      if (this.handleHistory[currentIndex].hhPlayer.ante != undefined && this.handleHistory[currentIndex].hhPlayer.ante != null && this.handleHistory[currentIndex].hhPlayer.ante > 0) {
        hhplayer_chips = (hhplayer_chips - this.handleHistory[currentIndex].hhPlayer.ante);
      }


      content += '<div class="name ' + isDealer + ' ">';
      content += '<span>' + this.handleHistory[currentIndex].hhPlayer.nickname +'</span>'
      content += '<div class="game-position-value">';
      content += '<div class="player-total-value mise-value">' + this.toFixedIfNecessary(hhplayer_chips) + '</div>';
      content += '<div class="game-position ' + this.handleHistory[currentIndex].hhPlayer.gamePosition + '"></div>';
      content += '</div>';
      content += '</div>';
      content += '<input class="player-toggle-statistics" type="checkbox" id="' + this.handleHistory[currentIndex].hhPlayer.nickname + '">';
      content += '<label for="' + this.handleHistory[currentIndex].hhPlayer.nickname + '"></label>';
      // content += '<div class="player-statistics" >';
      // content += '<ul>';
      // content += '<li>';
      // content += '<div class="statistics-name">VPIP</div>';
      // content += '<div class="statistics-valeu">21</div>';
      // content += '</li>';
      // content += '<li>';
      // content += '<div class="statistics-name">PFR</div>';
      // content += '<div class="statistics-valeu">0</div>';
      // content += '</li>';
      // content += '<li>';
      // content += '<div class="statistics-name">3Fld</div>';
      // content += '<div class="statistics-valeu">-</div>';
      // content += '</li>';
      // content += '<li>';
      // content += '<div class="statistics-name">FldSd</div>';
      // content += '<div class="statistics-valeu">89</div>';
      // content += '</li>';
      // content += '<li>';
      // content += '<div class="statistics-name">BBs</div>';
      // content += '<div class="statistics-valeu">36</div>';
      // content += '</li>';
      // content += '</ul>';
      // content += '</div>';
      content += '<div class="player-action" data-player="' + this.handleHistory[currentIndex].hhPlayer.nickname + '">';
      content += '</div>';
      content += '<div class="player-action-desc" data-player="' + this.handleHistory[currentIndex].hhPlayer.nickname + '">';
      content += '</div>';

      elPosition = document.getElementById('position-1');
      elPosition!.innerHTML = content;
      elPosition!.setAttribute('data-player', this.handleHistory[currentIndex].hhPlayer.nickname);

      if (hhplayer_chips == 0) {
        this.player_action(elPosition);
      }

      elArea = document.getElementById('area-1')
      elArea!.setAttribute('data-player', this.handleHistory[currentIndex].hhPlayer.nickname);

      //Definindo o blind idenficador
      smallAndBigBlindIndex = -1;

      if ((this.handleHistory[currentIndex].hhPlayer.smallBlind != 'undefined' && this.handleHistory[currentIndex].hhPlayer.smallBlind != null)) {

        var elArea: any = document.getElementById('area-1');

        //Definindo valor do smallBlind na listagem e mostrando
        elArea!.querySelectorAll('.total-chips-value')[0].setAttribute('data-value', this.handleHistory[currentIndex].hhPlayer.smallBlind);
        elArea!.querySelectorAll('.total-chips-value')[0].innerHTML = this.handleHistory[currentIndex].hhPlayer.smallBlind;

        //delay para mostrar
        setTimeout(function () {
          elArea.style.opacity = '1';
          //elArea.querySelectorAll('.total-chips-value')[0].classList.add('calls-fade');
        }, 5);

        var element = document.createElement('div');
        element.classList.add('blind-number');
        element.setAttribute('data-blind', String(smallAndBigBlindIndex));

        contentBlind = '<div class="area-chips">'
        contentBlind += '<div id="' + smallAndBigBlindIndex + '-' + this.handleHistory[currentIndex].hhPlayer.nickname + '" class="index-item">';
        contentBlind += '<div class="blind small-blind calls-fade">';
        contentBlind += '<div class="chip chip-blue"></div>';
        contentBlind += '<div class="chip chip-red"></div>';
        contentBlind += '<div class="chip chip-red"></div>';
        contentBlind += '<div class="chip chip-dark"></div>';
        contentBlind += '<div class="chip chip-dark"></div>';
        contentBlind += '<div class="chip chip-dark"></div>';
        contentBlind += '</div></div>';
        contentBlind += '</div>';

        element.innerHTML = contentBlind;
        elArea.appendChild(element);

      } else if (this.handleHistory[currentIndex].hhPlayer.bigBlind != 'undefined' && this.handleHistory[currentIndex].hhPlayer.bigBlind != null) {

        var elArea: any = document.getElementById('area-1');
        //Definindo valor do bigBlind na listagem e mostrando
        elArea.querySelectorAll('.total-chips-value')[0].setAttribute('data-value', this.handleHistory[currentIndex].hhPlayer.bigBlind);
        elArea.querySelectorAll('.total-chips-value')[0].innerHTML = this.handleHistory[currentIndex].hhPlayer.bigBlind;


        //delay para mostrar
        setTimeout(function () {
          elArea.style.opacity = 1;
          //elArea.querySelectorAll('.total-chips-value')[0].classList.add('calls-fade');
        }, 5);

        var element = document.createElement('div');
        element.classList.add('blind-number');
        element.setAttribute('data-blind', String(smallAndBigBlindIndex));

        contentBlind = '<div class="area-chips">'
        contentBlind += '<div id="' + smallAndBigBlindIndex + '-' + this.handleHistory[currentIndex].hhPlayer.nickname + '" class="index-item">';
        contentBlind += '<div class="blind small-blind calls-fade">';
        contentBlind += '<div class="chip chip-blue"></div>';
        contentBlind += '<div class="chip chip-red"></div>';
        contentBlind += '<div class="chip chip-red"></div>';
        contentBlind += '<div class="chip chip-dark"></div>';
        contentBlind += '<div class="chip chip-dark"></div>';
        contentBlind += '<div class="chip chip-dark"></div>';
        contentBlind += '</div></div>';
        contentBlind += '</div>';

        element.innerHTML = contentBlind;
        elArea.appendChild(element);
      }

    }


    this.handleHistory[currentIndex].players.forEach((item, index) => {
      // console.log(item.nickname)
      isDealer = '';
      content = '';
      position = positionPlayers[index];
      elementId = '';
      elementAreaId = '';
      elArea = '';

      id = positionPlayers[index];
      pRight = '0';
      pLeft = '0';
      pTop = '5%';
      mLeft = '0';
      mRight = '0';

      switch (totalPlayers) {
        case 7:
          switch (id) {
            case 2:
              pLeft = '0';
              pTop = '5%';
              break;
            case 3:
              pLeft = '-25%';
              pTop = '-50px';
              break;
            case 4:
              pLeft = '-50%';
              pTop = '-50px';
              break;
            case 5:
              pLeft = '-75%';
              pTop = '5%';
              break;
            case 6:
              pLeft = '-0';
              pTop = '-25%';
              break;
            case 7:
              pLeft = '-auto';
              pTop = '-25%';
              break;
            default:
          }
          break;
        case 6:
          switch (id) {
            case 2:
              pLeft = '0';
              pTop = '5%';
              break;
            case 3:
              pLeft = '25%';
              pTop = '-50px';
              break;
            case 4:
              pLeft = '50%';
              pTop = '-50px';
              break;
            case 5:
              pLeft = '75%';
              pTop = '5%';
              break;
            case 6:
              pLeft = 'calc(0px - 100px)';
              pTop = '35%';
              break;
            default:
          }
          break;
        case 5:
          switch (id) {
            case 2:
              pLeft = '0';
              pTop = '5%';
              break;
            case 3:
              pLeft = '75%';
              pTop = '5%';
              break;
            case 4:
              pLeft = '0';
              pTop = '65%';
              break;
            case 5:
              pLeft = '75%';
              pTop = '65%';
              break;
            default:
          }
          break;
        case 4:
          switch (id) {
            case 2:
              pLeft = 'calc(0px - 100px)';
              pTop = '35%';
              break;
            case 3:
              pLeft = '25%';
              pTop = '-50px';
              break;
            case 4:
              pLeft = '75%';
              pTop = '35%';
              mRight = '-100px';
              break;
            default:
          }
          break;
        case 3:
          switch (id) {
            case 2:
              pLeft = '0';
              pTop = '5%';
              break;
            case 3:
              pLeft = '75%';
              pTop = '5%';
              break;
            default:
          }
          break;
        case 2:
          switch (id) {
            case 2:
              pLeft = '0';
              pTop = '5%';
              break;
            default:
          }
          break;
        default: //BTN
          switch (id) {
            case 2:
              pLeft = '-0';
              pTop = '-0';
              break;
            case 3:
              pLeft = '-25%';
              pTop = '0px';
              break;
            case 4:
              pLeft = '-50%';
              pTop = '-50px';
              break;
            case 5:
              pLeft = '-75%';
              pTop = '-0';
              break;
            case 6:
              pLeft = '-0';
              pTop = '-25%';
              break;
            case 7:
              pLeft = '-75%';
              pTop = '-25%';
              break;
            case 8:
              pLeft = '-0';
              pTop = '-50%';
              break;
            case 9:
              pLeft = '-75%';
              pTop = '-50%';
              break;
            default:
          }
      }

      //Posicionamento game-position players
      if (this.handleHistory[currentIndex].hhPlayer.nickname != item.nickname) {
        var player_chips = item.chips;
        if (item.gamePosition == 'BTN') {
          isDealer = 'dealer-button';
        } else {
          isDealer = '';
        }


        //Ajusta placar
        if (item.smallBlind != undefined && item.smallBlind != null && item.smallBlind > 0) {
          player_chips = (item.chips - item.smallBlind);
        } else if (item.bigBlind != undefined && item.bigBlind != null && item.bigBlind > 0) {
          player_chips = (item.chips - item.bigBlind);
        }
        if (item.ante != undefined && item.ante != null && item.ante > 0) {
          player_chips = (player_chips - item.ante);
        }

        nameWithSpace = item.nickname.split('&').join(' ');
        content += '<div class="name ' + isDealer + '">';
        content += '<span class="name_player">' + nameWithSpace;
        content += '</br>'
        this.listPositions.map(listPosition => {
          if (listPosition.gamePosition == item.gamePosition) {
            content += '<span class="name_player">' + '#' + listPosition[this.plataform].hand + '</span>';
          }
        });
        content += '</span>';
        content += '<div class="game-position-value">';
        content += '<div class="player-total-value">' + this.toFixedIfNecessary(player_chips) + '</div>';
        content += '<div class="game-position ' + item.gamePosition + '"></div>';
        content += '</div>';
        content += '</div>';
        content += '<input class="player-toggle-statistics" type="checkbox" id="' + nameWithSpace + '">';
        content += '<label for="' + nameWithSpace + '"></label>';
        this.listPositions.map(listPosition => {
          if (listPosition.gamePosition == item.gamePosition) {
            content += '<div class="player-statistics">';
            content += '<ul>';
            content += '<li data-street="PRE_FLOP">';
            content += '<div  class="statistics-name">LIMP</div>';
            content += '<div class="statistics-valeu">' + this.convertStat(listPosition[this.plataform], "limp") + '</div>';
            content += '</li>';
            content += '<li data-street="PRE_FLOP">';
            content += '<div class="statistics-name">VPIP</div>';
            content += '<div class="statistics-valeu">' + this.convertStat(listPosition[this.plataform], "vpip") + '</div>';
            content += '</li>';
            content += '<li data-street="PRE_FLOP">';
            content += '<div class="statistics-name">RFI</div>';
            content += '<div class="statistics-valeu">' + this.convertStat(listPosition[this.plataform], "rfi") + '</div>';
            content += '</li>';
            content += '<li data-street="PRE_FLOP">';
            content += '<div class="statistics-name">3BET</div>';
            content += '<div class="statistics-valeu">' + this.convertStat(listPosition[this.plataform], "bet3") + '</div>';
            content += '<br style="height: 0px;display: contents;" />'
            content += '</li>';
            content += '<li data-street="PRE_FLOP">';
            content += '<div class="statistics-name">3BET/F</div>';
            content += '<div class="statistics-valeu">' + this.convertStat(listPosition[this.plataform], "bet3Fold") + '</div>';
            content += '</li>';
            content += '</ul>';

            content += '<ul>';
            content += '<li data-street="FLOP" style="display: none;">';
            content += '<div class="statistics-name">CBET</div>';
            content += '<div class="statistics-valeu">' + this.convertStat(listPosition[this.plataform], "CBet") + '</div>';
            content += '</li>';
            content += '<li data-street="FLOP" style="display: none;">';
            content += '<div class="statistics-name">CBET/F</div>';
            content += '<div class="statistics-valeu">' + this.convertStat(listPosition[this.plataform], "CBetFold") + '</div>';
            content += '</li>';
            content += '<li data-street="FLOP" style="display: none;">';
            content += '<div class="statistics-name">DONKBET</div>';
            content += '<div class="statistics-valeu">' + this.convertStat(listPosition[this.plataform], "donkBet") + '</div>';
            content += '</li>';
            content += '<li data-street="FLOP" style="display: none;">';
            content += '<div class="statistics-name">XR</div>';
            content += '<div class="statistics-valeu">' + this.convertStat(listPosition[this.plataform], "checkRaiseFlop") + '</div>';
            content += '</li>';
            content += '</ul>'

            content += '<ul>';
            content += '<li data-street="TURN" style="display: none;">';
            content += '<div class="statistics-name">CBET</div>';
            content += '<div class="statistics-valeu">' + this.convertStat(listPosition[this.plataform], "CBetTurn") + '</div>';
            content += '</li>';
            content += '<li data-street="TURN" style="display: none;">';
            content += '<div class="statistics-name">CBET/F</div>';
            content += '<div class="statistics-valeu">' + this.convertStat(listPosition[this.plataform], "CBetTurnFold") + '</div>';
            content += '</li>';
            content += '<li data-street="TURN" style="display: none;">';
            content += '<div class="statistics-name">PROBE</div>';
            content += '<div class="statistics-valeu">' + this.convertStat(listPosition[this.plataform], "probeTurn") + '</div>';
            content += '</li>';
            content += '<li data-street="TURN" style="display: none;">';
            content += '<div class="statistics-name">XR</div>';
            content += '<div class="statistics-valeu">' + this.convertStat(listPosition[this.plataform], "checkRaiseTurn") + '</div>';
            content += '</li>';
            content += '</ul>';

            content += '</div>';

          }
        })
        content += '<div class="player-action" data-player="' + nameWithSpace + '">';
        content += '</div>';
        content += '<div class="player-action-desc" data-player="' + nameWithSpace + '">';
        content += '</div>';

        elementId = 'position-' + id;

        elPosition = document.getElementById(elementId);
        // alert(elementId);
        elPosition!.innerHTML = content;
        elPosition!.setAttribute('data-player', item.nickname);

        if (player_chips == 0) {
          this.player_action(elPosition);
        }

        elementAreaId = 'area-' + id;
        elArea = document.getElementById(elementAreaId);
        elArea!.setAttribute('data-player', item.nickname);

        //Definindo o blind idenficador
        smallAndBigBlindIndex = -1;

        if (item.smallBlind != 'undefined' && item.smallBlind != null) {

          var elArea: any = document.getElementById(elementAreaId);
          //Definindo valor do smallBlind na listagem e mostrando
          var currentElementTotal = elArea!.querySelectorAll('.total-chips-value')[0];
          currentElementTotal.setAttribute('data-value', item.smallBlind);
          currentElementTotal.innerHTML = item.smallBlind;

          //delay para mostrar
          setTimeout(function () {
            elArea!.style.opacity = '1';
            //currentElementTotal.classList.add('calls-fade');
          }, 5);

          var element = document.createElement('div');
          element.classList.add('blind-number');
          element.setAttribute('data-blind', String(smallAndBigBlindIndex));

          contentBlind = '<div class="area-chips">'
          contentBlind += '<div id="' + smallAndBigBlindIndex + '-' + item.nickname + '" class="index-item">';
          contentBlind += '<div class="blind small-blind calls-fade">';
          contentBlind += '<div class="chip chip-blue"></div>';
          contentBlind += '<div class="chip chip-red"></div>';
          contentBlind += '<div class="chip chip-red"></div>';
          contentBlind += '<div class="chip chip-dark"></div>';
          contentBlind += '<div class="chip chip-dark"></div>';
          contentBlind += '<div class="chip chip-dark"></div>';
          contentBlind += '</div></div>';
          contentBlind += '</div>';

          element.innerHTML = contentBlind;
          elArea!.appendChild(element);

        } else if (item.bigBlind != 'undefined' && item.bigBlind != null) {

          var elArea: any = document.getElementById(elementAreaId);

          //Definindo valor do bigBlind na listagem e mostrando
          var currentElementTotal = elArea!.querySelectorAll('.total-chips-value')[0];
          currentElementTotal.setAttribute('data-value', item.bigBlind);
          currentElementTotal.innerHTML = item.bigBlind;

          //delay para mostrar
          setTimeout(function () {
            elArea.style.opacity = '1';
            //currentElementTotal.classList.add('calls-fade');
          }, 5);


          var element = document.createElement('div');
          element.classList.add('blind-number');
          element.setAttribute('data-blind', String(smallAndBigBlindIndex));

          contentBlind = '<div class="area-chips">'
          contentBlind += '<div id="' + smallAndBigBlindIndex + '-' + item.nickname + '" class="index-item">';
          contentBlind += '<div class="blind small-blind calls-fade">';
          contentBlind += '<div class="chip chip-blue"></div>';
          contentBlind += '<div class="chip chip-red"></div>';
          contentBlind += '<div class="chip chip-red"></div>';
          contentBlind += '<div class="chip chip-dark"></div>';
          contentBlind += '<div class="chip chip-dark"></div>';
          contentBlind += '<div class="chip chip-dark"></div>';
          contentBlind += '</div></div>';
          contentBlind += '</div>';

          element.innerHTML = contentBlind;
          elArea!.appendChild(element);
        }
      }
    });

    // Cartas
    this.handleHistory[currentIndex].players.forEach((item, index) => {

      content = '';
      position = positionPlayers[index];
      elementId = '';

      id = positionPlayers[index];

      if (this.handleHistory[currentIndex].hhPlayer.nickname != item.nickname) {
        var r = 0;
        content += '<div class="player-cards">';
        if (item.hand) {
          r++;
          item.hand.forEach((cardDesk) => {
            r++;
            content += '<div class="flip-cardDesk" data-hand-cardDesk="' + r + '">';
            content += '<div class="flip-cardDesk-inner">';
            content += '<div class="flip-cardDesk-front">';
            content += '<div class="cardDesk"></div>';
            content += '</div>';
            content += '<div class="flip-cardDesk-back">';
            content += '<div class="cardDesk cardDesk-' + cardDesk + '"></div>';
            content += '</div>';
            content += '</div>';
            content += '</div>';
          })
        } else {
          content += '<div class="flip-cardDesk" data-hand-cardDesk="1">';
          content += '<div class="flip-cardDesk-inner">';
          content += '<div class="flip-cardDesk-front">';
          content += '<div class="cardDesk"></div>';
          content += '</div>';
          content += '<div class="flip-cardDesk-back">';
          content += '<div class="cardDesk"></div>';
          content += '</div>';
          content += '</div>';
          content += '</div>';
          content += '<div class="flip-cardDesk" data-hand-cardDesk="2">';
          content += '<div class="flip-cardDesk-inner">';
          content += '<div class="flip-cardDesk-front">';
          content += '<div class="cardDesk"></div>';
          content += '</div>';
          content += '<div class="flip-cardDesk-back">';
          content += '<div class="cardDesk"></div>';
          content += '</div>';
          content += '</div>';
          content += '</div>';
        }
        content += '</div>';


        elementId = 'cards-' + id;
        // alert(elementId);
        document.getElementById(elementId)!.innerHTML = content;
        document.getElementById(elementId)!.setAttribute('data-player', item.nickname);
      } else {
        content += '<div class="player-cards">';
        if (this.handleHistory[currentIndex].hhPlayer.hand) {
          var r = 0;
          this.handleHistory[currentIndex].hhPlayer.hand.forEach((cardDesk) => {
            r++;
            content += '<div class="flip-cardDesk action-flip" data-hand-cardDesk="' + r + '">';
            content += '<div class="flip-cardDesk-inner">';
            content += '<div class="flip-cardDesk-front">';
            content += '<div class="cardDesk"></div>';
            content += '</div>';
            content += '<div class="flip-cardDesk-back">';
            content += '<div class="cardDesk cardDesk-' + cardDesk + '"></div>';
            content += '</div>';
            content += '</div>';
            content += '</div>';
          })
        } else {
          content += '<div class="flip-cardDesk" data-hand-cardDesk="1">';
          content += '<div class="flip-cardDesk-inner">';
          content += '<div class="flip-cardDesk-front">';
          content += '<div class="cardDesk"></div>';
          content += '</div>';
          content += '<div class="flip-cardDesk-back">';
          content += '<div class="cardDesk"></div>';
          content += '</div>';
          content += '</div>';
          content += '</div>';
          content += '<div class="flip-cardDesk" data-hand-cardDesk="2">';
          content += '<div class="flip-cardDesk-inner">';
          content += '<div class="flip-cardDesk-front">';
          content += '<div class="cardDesk"></div>';
          content += '</div>';
          content += '<div class="flip-cardDesk-back">';
          content += '<div class="cardDesk"></div>';
          content += '</div>';
          content += '</div>';
          content += '</div>';
        }
        content += '</div>';

        elementId = 'cards-' + 1;
        // alert(elementId);
        document.getElementById(elementId)!.innerHTML = content;
        document.getElementById(elementId)!.setAttribute('data-player', item.nickname);
      }
    });
  }

  destroyList(): void {
    var players = document.querySelector('#players');
    while (players.firstChild) {
      players.removeChild(players.firstChild);
    }
    var cardDesk_place = document.querySelector('#cardDesk_place');
    while (cardDesk_place.firstChild) {
      cardDesk_place.removeChild(cardDesk_place.firstChild);
    }
    var flipCardDesk = "";
    for (var i = 1; i < 6; i++) {
      flipCardDesk += "<div class='flip-cardDesk' data-cardDesk=" + i + ">";
      flipCardDesk += "<div class='flip-cardDesk-inner'>";
      flipCardDesk += "<div class='flip-cardDesk-front'>";
      flipCardDesk += "<div class='cardDesk'></div>";
      flipCardDesk += "</div>";
      flipCardDesk += "<div class='flip-cardDesk-back'>";
      flipCardDesk += "<div class='cardDesk'></div>";
      flipCardDesk += "</div>";
      flipCardDesk += "</div>";
      flipCardDesk += "</div>";
      if (flipCardDesk != undefined) {
        cardDesk_place!.innerHTML = flipCardDesk;
      }
    }

  }

  player_action(elementPlayer: any, action = "CALLS") {
    setTimeout(() => {
      var playerActionElement = elementPlayer.querySelectorAll(".player-action")[0];
      if (elementPlayer) {
        playerActionElement.classList.remove("action-show-reverse");
        playerActionElement.innerHTML = "ALL IN";
        playerActionElement.classList.add("action-show");
        if (action == "CALLS") {
          playerActionElement.style.zoom = "1.1";
          playerActionElement.style.transition = "ease 0.3s all";
          playerActionElement.style.background = "#fbb03b";
          playerActionElement.style.color = "#fff";
          playerActionElement.style.borderColor = "#fbb03b";
          setTimeout(function () {
            playerActionElement.style.zoom = "1";
          }, 200);
          var audio = new Audio("../assets/sound/blind.wav");
          audio.play();
        }
      }
    }, 1000);
  }

  toFixedIfNecessary(value): string {
    if (Number.isInteger(value) && this.decimalPlaces == 3) {
      return value.toLocaleString("en-US");
    } else if (Number.isInteger(value)) {
      return value;
    } else {
      return parseFloat(value).toFixed(2);
    }
  }


  convertStat(listStats: PositionStats, stat: string): string {
    if (listStats[`${stat}OpSum`] == 0) {
      return '♣️';
    } else {
      return listStats[stat];
    };
  }

}
