<span style="display: none;" id="status_video">{{ statusVideo }}</span>
<span style="display: none;" id="video_hand_atual">{{hand_atual}}</span>
<span style="display: none;" id="video_hand_total">{{hand_total}}</span>
<app-replayer-table class="replayer-table" *ngIf="handleHistoryJSON.length > 0" [handleHistory]="handleHistoryJSON"
  [studyTitle]="studyTitle" #replayerTable>
</app-replayer-table>
<div id="wrapper"></div>
<div class="notepad">
  <div class="notifications" [@animationList]="notepad.chats?.length">

    <div class="msg right-msg active" *ngFor="let chat of notepad.chats; let i = index">
      <div class="msg-img" [style.backgroundImage]="'url(' + getImageAnnotation(chat.nameUser) + ')'"></div>

      <div class="msg-bubble">
        <div class="msg-info">
          <div class="msg-info-name">{{ chat.nameUser }}</div>
          <div class="msg-info-time"></div>
        </div>

        <div class="msg-text">
          {{chat.messages}}
        </div>

        <div class="progress active"></div>

      </div>

    </div>

    <!-- <div class="notifications-overlay"></div> -->

  </div>

</div>

<div class="logotipo" id="logotipo">
  <img src="../../../../assets/img/logos/logo-good-poker.png" />
  <h4>CONNECTING PLAYERS THROUGH STUDY</h4>
</div>
<div class="square-top" id="square-top"></div>
<div class="square-bottom" id="square-bottom"></div>
