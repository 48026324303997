import { GroupUser } from "./GroupUser";

export class MyGroup {

    id: string;

    participants: GroupUser[];

    emailUser: string;

    idUser: string;

    admin: string;

    qntPart: string;

    description: string;

    title: string;

    imagedata: void;

    imageURL: string;

}
