import {HttpClient, HttpHeaders} from '@angular/common/http';
import {JsonContainer} from './json-container';
import {Constants} from './constants';
import {StorageUtils} from './storage-utils';
import { Router } from '@angular/router';
import 'rxjs/add/operator/do';

export class RestService {
    router: any;

    constructor(private http: HttpClient, private service: string, private storage: StorageUtils) {
    }

    executePost(urlSufix: string, data: any, callback: (result: JsonContainer, data: any) => void): void {

        const url = this.getUrl(urlSufix);
        const options = this.httpOptions();
        const jc = new JsonContainer()

        this.http.post(url, data, options).subscribe(resp => {
                if (callback != null) {
                    jc.success = true
                    callback(jc, resp)
                }
            },
            error => {
                jc.success = false
                jc.desc = error.error
                this.handleAuthError(error)
                callback(jc, null)
              
            })
    }


    executePostVideo(urlSufix: string, data: any, callback: (result: JsonContainer, data: any) => void): void {

        const url = this.getUrl(urlSufix);
        const options = this.httpOptionsVideo()
        const jc = new JsonContainer()

        this.http.post(url, data, options).subscribe(resp => {
                if (callback != null) {
                    jc.success = true
                    callback(jc, resp)
                }
            },
            error => {
                jc.success = false
                jc.desc = error.error
                this.handleAuthError(error)
                callback(jc, null)
              
            })
    }

    executePostPhoto(urlSufix: string, data: any, callback: (result: JsonContainer, data: any) => void): void {

        const url = this.getUrl(urlSufix);
        const options = this.httpOptionsPicture()
        const jc = new JsonContainer()

            this.http.post(url, data, options).subscribe(resp => {
                if (callback != null) {
                    jc.success = true
                    callback(jc, resp)
                }
            },
            error => {
                jc.success = false
                jc.desc = error.error
                this.handleAuthError(error)
                callback(jc, null)
               
            })
    }

    executePostCheck(urlSufix: string, data: any, callback: (result: JsonContainer, data: any) => void): void {

        const url = this.getUrl(urlSufix);
        const options = this.httpOptionsVideo()
        const jc = new JsonContainer()

        this.http.post(url, data, options).subscribe(resp => {
                if (callback != null) {
                    jc.success = true
                    callback(jc, resp)
                }
            },
            error => {
                jc.success = false
                jc.desc = error.error
                this.handleAuthError(error)
                callback(jc, null)
               
            })
    }

    executePut(urlSufix: string, data: any, callback: (result: JsonContainer, data: any) => void): void {

        const url = this.getUrl(urlSufix);
        const options = this.httpOptions();
        const jc = new JsonContainer()

        this.http.put(url, data, options).subscribe(resp => {
                if (callback != null) {
                    jc.success = true
                    callback(jc, resp)
                }
            },
            error => {
                jc.success = false
                jc.desc = error.error
                this.handleAuthError(error)
                callback(jc, null)
               
            })
    }


    executePostCustom(urlSufix: string, data: any) {
        const url = this.getUrl(urlSufix);
        const options = this.httpOptions();
        return this.http.post(url, data, options);
    }

    executeGet(urlSufix: string, callback: (result: JsonContainer, data: any) => void): void {

        const url = this.getUrl(urlSufix);
        const options = this.httpOptions();
        const jc = new JsonContainer()

        this.http.get(url, options).subscribe(resp => {
                if (callback != null) {
                    jc.success = true
                    callback(jc, resp)
                }
            },
            error => {
                jc.success = false
                jc.desc = error.error
                this.handleAuthError(error)
                callback(jc, null)
            })
    }

    executeGetReport(urlSufix: string, callback: (data: any) => void): void {

        const url = this.getUrl(urlSufix);
        const options = this.httpOptionsReport();
        let jc: any

        this.http.get(url, options).subscribe(resp => {
                    jc = resp
                    callback(jc)
            },
            error => {
                jc = null
                callback(jc)

            })
    }


    executeDelete(urlSufix: string, callback: (result: JsonContainer, data: any) => void): void {

        const url = this.getUrl(urlSufix);
        const options = this.httpOptions();
        const jc = new JsonContainer()

        this.http.delete(url, options).subscribe(resp => {
                if (callback != null) {
                    jc.success = true
                    callback(jc, resp)
                }
            },
            error => {
                jc.success = false
                jc.desc = error.error
                this.handleAuthError(error)
                callback(jc, null)
            })
    }

    executeGetUrl(url: string, callback: (result: JsonContainer, data: any) => void): void {

        const options = this.httpOptions();
        const jc = new JsonContainer()

        this.http.get(url, options).subscribe(resp => {
                if (callback != null) {
                    jc.success = true
                    callback(jc, resp)
                }
            },
            error => {
                jc.success = false
                jc.desc = error.error
                this.handleAuthError(error)
                callback(jc, null)
            })
    }

    private httpOptions(): {} {
        return {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.storage.getToken(),
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
                'Access-Control-Allow-Headers': 'Origin, Content-Type, Access-Control-Allow-Headers, Accept, Authorization, X-Requested-With',
                'Access-Control-Allow-Credentials': 'true',
                'Accept': 'application/json'
            })
        };
    }

    private httpOptionsVideo(): {} {
        return {
            headers: new HttpHeaders({
                'Accept': 'application/json ',
                'Authorization': 'Bearer ' + this.storage.getToken(),
            })
        };
    }

    private httpOptionsReport(): {} {
        return {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + this.storage.getToken(),
                // 'Content-Type': 'text/plain'
            }),
            'responseType': 'text'
        };
    }

    private httpOptionsPicture(): {} {
        return {
          //  reportProgress:true,
          //  observe:'events',
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + this.storage.getToken(),
                // 'Content-Type': 'text/plain'
            }),
            'responseType': 'text'
        };
    }

    private getUrl(urlSufix: string): string {
        return Constants.SERVICE_URL + Constants.SERVICE_PROJECT + this.service + '/' + urlSufix;
    }

    handleAuthError(err: any) {
        if(err.status === 498 || err.status === 401) {
            this.storage.clear()
            window.location.href = '/login';
        }
    }
}
