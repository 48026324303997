import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { JsonContainer } from "app/utils/json-container";
import { RestService } from "app/utils/rest-service";
import { StorageUtils } from "app/utils/storage-utils";
import { CardRivalRequest, CardRivalResponse } from "./ListPosition";



@Injectable()
export class CardRivalService extends RestService {

  constructor(private client: HttpClient, private storageUtils: StorageUtils) {
    super(client, 'cardRival', storageUtils);
  }

  listByIdUserGP(players: CardRivalRequest[], callback: (result: JsonContainer, listPosition: CardRivalResponse[]) => void) {
    this.executePost('listByIdUserGP', players, callback)
  }

}
