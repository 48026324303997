import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from './pages/msk-user/user/user-service';
import { StorageUtils } from './utils/storage-utils';
import { PlanService } from './pages/plan-api/plan-service';
import { Plan } from './pages/plan-api/plan-model';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  providers: [
    UserService,
    PlanService,
    StorageUtils
  ],
})
export class AppComponent implements OnInit, OnDestroy {

  subscription: Subscription;

  constructor(private router: Router,
    public translate: TranslateService,
    private userService: UserService,
    private planService: PlanService,
    private titleService: Title,
    private storage: StorageUtils) {
    // const language = localStorage.getItem("language");
    // if (language) {
    //   this.translate.setDefaultLang(language);
    //   this.translate.use(language);
    // } else {
    //   this.translate.setDefaultLang('pt');
    //   this.translate.use('pt');
    //   localStorage.setItem("language", "pt");
    // }

    this.subscription = this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe(() => {
        window.scrollTo(0, 0);
        this.titleService.setTitle(this.getNestedRouteTitles().join(' | ') + (this.getNestedRouteTitles().length == 0 ? "GoodPoker" : " - GoodPoker"));
      });

  const rep = window.location.href.replace(/http(s)?:\/\/[^\/]*/g, '');
  console.log('auto login rep')
   console.log(rep)
   if (!(rep.includes('record-video'))){
      if (rep === '/') {
        const userB = this.storage.getAutoLogin();
        // console.log('auto login'+userB.email)
        if (userB != null) {
          this.userService.autoLogin(userB, (result, user) => {
            if (result.success) {
              this.storage.storeToken(user.token);
              this.storage.idUser(user.id);
              this.storage.nameUser(user.name);
              this.storage.urlImage(user.urlImage);
              this.storage.email(user.email);
              if (user.info.nickname != null) {
                this.storage.storeUserNickName(user.info.nickname);
              } else {
                var nameSplited = user.name.split(" ", 2);
                this.storage.storeUserNickName(nameSplited[0]);
              }
              this.userService.setCurrentUser(user);
              this.planService.searchPlan(user.info.plan, (result, plan) => {
                this.storage.storeUserPlan(plan);
              });
              console.log('auto login')
             this.router.navigate(['pages/home']);
            } else {
              this.router.navigate(['/login']);
            }
          });
        } else {
          this.router.navigate(['/login']);
        }
      }
    }
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  getNestedRouteTitles(): string[] {
    let currentRoute = this.router.routerState.root.firstChild;
    const titles: string[] = [];

    while (currentRoute) {
      if (currentRoute.snapshot.routeConfig.data?.title) {
        titles.push(currentRoute.snapshot.routeConfig.data.title);
      }

      currentRoute = currentRoute.firstChild;
    }
    console.log(titles);
    return titles;
  }
}
