import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { isNullOrUndefined } from "@swimlane/ngx-datatable";
import { CommentPost, Like, Post } from "app/pages/full-pages/homepage-timeline/post";
import { StudySession } from "app/pages/study-api/study-model/StudySession";
import { Study, StudyLibrary } from "app/pages/studyLibrary-api/model/StudyLibrary.model";
import { Constants } from "app/utils/constants";
import { JsonContainer } from "app/utils/json-container";
import { RestService } from "app/utils/rest-service";
import { StorageUtils } from "app/utils/storage-utils";


@Injectable({
  providedIn: 'root'
})
export class PostService extends RestService {
  private urlWSBase;
  private postClient: WebSocket;

  constructor(private client: HttpClient, private storageUtils: StorageUtils) {
    super(client, 'postExt', storageUtils);
    this.urlWSBase = Constants.WS_URL
  }

  listPost(idUser: String, callback: (result: JsonContainer, post: Post) => void) {
    this.executeGet(`listPost/${idUser}`, callback);
  }

  saveStudyPost(data: FormData, callback: (result: JsonContainer, res: any) => void) {
    this.executePostVideo('saveStudyPost', data, callback);
  }

  listComment(idPost: String, callback: (result: JsonContainer, comment: any) => void) {
    this.executeGet(`listComment/${idPost}`, callback);
  }

  updatePost(post: Post, callback: (result: JsonContainer) => void) {
    this.executePost('updatePost/', post, callback);
  }

  addComent(comment: CommentPost, callback: (result: JsonContainer, comment: any) => void) {
    this.executePost('addComment/', comment, callback);
  }

  deleteComent(comment: CommentPost, callback: (result: JsonContainer, comments: CommentPost[]) => void) {
    this.executePost('deleteComment/', comment, callback);
  }

  listLike(idPost: String, callback: (result: JsonContainer, likes: Like[]) => void) {
    this.executeGet(`listLike/${idPost}`, callback);
  }

  addLike(like: Like, callback: (result: JsonContainer, like: any) => void) {
    this.executePost('like/', like, callback);
  }

  removeLike(like: Like, callback: (result: JsonContainer, like: any) => void) {
    this.executePost('dislike/', like, callback);
  }

  savePost(file: FormData, callback: (result: JsonContainer, res: any) => void) {
    this.executePostVideo('savePost', file, callback);
  }

  openWSPost(cb: WSMessage) {
    if (this.isConnectedWSPost()) this.closeWSPost();
    this.postClient = new WebSocket(
      `${this.urlWSBase}/ws/post`
    );
    this.postClient.onmessage = cb;
  }

  closeWSPost() {
    this.postClient.close();
  }

  sendMessageWSPost(mesage: string) {
    if(this.isConnectedWSPost())
      this.postClient.send(mesage);
  }

  isConnectedWSPost() {
    if(isNullOrUndefined(this.postClient)) return false;
    if(this.postClient.readyState === this.postClient.OPEN) return true;

    return false;
  }

  listSearchPost(idUser: String, txtSearch: String, callback: (result: JsonContainer, post: Post) => void) {
    this.executeGet(`listSearchPost/${idUser}/${txtSearch}`, callback);
  }

  listPostPage(idUser: String, page: number, callback: (result: JsonContainer, posts: Post[]) => void) {
    this.executeGet(`listPostPage/${idUser}/${page}`, callback);
  }

}

interface WSMessage {
  (message: MessageEvent): void;
}
