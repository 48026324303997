import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-popup-language',
  templateUrl: './popup-language.component.html',
  styleUrls: ['./popup-language.component.scss']
})
export class PopupLanguageComponent implements OnInit {

  selectedLanguageText = "pt";
  selectedLanguageFlag = "../../../../assets/img/flags/pt-br.png";

  constructor(private translateService: TranslateService) {
    const language = localStorage.getItem("language");
    if (language) {
      this.changeLanguage(language);
      this.translateService.use(language);
    } else {
      this.changeLanguage("pt");
      this.translateService.use("pt");
      localStorage.setItem("language", "pt");
    }
  }

  ngOnInit(): void {
  }


  changeLanguage(language: string, reload?: boolean) {
    this.translateService.setDefaultLang(language);
    this.translateService.use(language);
    localStorage.setItem("language", language);

    if (language === 'pt') {
      this.selectedLanguageText = "pt";
      this.selectedLanguageFlag = "../../../../assets/img/flags/pt-br.png";
    } else {
      this.selectedLanguageText = language;
      this.selectedLanguageFlag = `../../../../assets/img/flags/${language}.png`;
    }
    if (reload) {
      // location.reload();
    }
  }
}
