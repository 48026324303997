// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

import { WeakSet } from "core-js";

export const environment = {
  production: false,
  //SERVICE_URL:  'http://localhost:8010',
  //WS_URL: 'ws://localhost:8010',

  SERVICE_URL:  'http://goodpokerlb-280013974.sa-east-1.elb.amazonaws.com:8010',
  WS_URL: 'ws://goodpokerlb-280013974.sa-east-1.elb.amazonaws.com:8010',

  SALES_PROJECT:'https://goodpoker.net/atualizar-plano',
  VIDEO_PT_URL:'https://goodpoker.s3-sa-east-1.amazonaws.com/97666adc-5145-4c4d-a6eb-e351e3ee18c7',
  SERVICE_PROJECT: '/api/'
};
