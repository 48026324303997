import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-modal-wait-generate-video-study-session',
  templateUrl: './modal-wait-generate-video-study-session.component.html',
  styleUrls: ['./modal-wait-generate-video-study-session.component.scss']
})
export class ModalWaitGenerateVideoStudySessionComponent implements OnInit {

  @Input('visible') visible: boolean;
  @Output('visibleChange') visibleChange: any = new EventEmitter();
  @Output('confirmModalEvent') confirmModalEvent?: any = new EventEmitter();

  private readonly _HTML_BODY = document.querySelector("body");

  constructor(
    private spinner: NgxSpinnerService,
    private _deviceDetectorService: DeviceDetectorService
  ) { }

  ngOnInit(): void {
    this.visible = false;
    this._enableCustomClass();
  }

  get isVisible(): boolean {
    this._enableCustomClass();
    return this.visible === true;
  }

  public closeModal(): any {
    this.visible = false;
    this._HTML_BODY.classList.remove('custom-z-index-modal');
    this.visibleChange.emit(this.visible);

  }

  public confirmOptionModal(): any {
    this._HTML_BODY.classList.remove('custom-z-index-modal');
    this.confirmModalEvent.emit(true);
  }

  private _enableCustomClass(): any {

    if(this.visible && this.visible === true){
      this._scrollBotoomOnlyMobile();
      this._HTML_BODY.classList.add('custom-z-index-modal');
    }else{
      this._HTML_BODY.classList.remove('custom-z-index-modal')
    }
  }

  private _scrollBotoomOnlyMobile(){
    const userAgentIphone = window.navigator.userAgent.includes('Mac');
    if(this._deviceDetectorService.isMobile() && userAgentIphone){
      this._HTML_BODY.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
    }
  }

  // private _enableCustomClass(): any {
  //   return this.visible && this.visible === true ? this._HTML_BODY.classList.add('custom-z-index-modal-watch-video') : this._HTML_BODY.classList.remove('custom-z-index-modal-watch-video');
  // }

}
