<div class="modal-container-custom" *ngIf="isVisible">
  <div class="modal-body-custom animate__animated" [ngClass]="{'animate__bounceIn': isVisible, 'animate__bounceOut': !isVisible}">
    <div class="modal-header-custom">
      <p>{{"voce_realmente_deseja_excluir" | translate}}</p>
    </div>
    <!-- <div class="modal-content-custom text-center">
      <p>
        O membro <span>Danilo Soares</span> participante do grupo <span>GoodPoker Help</span> já fez:
      </p>

      <div class="member-actions">
        <div class="member-action-item">
          <img src="/assets/img/icons/comments-group-icon.svg" alt="comentários no chat" srcset="">
          <div class="content">
            <span class="description">Comentários no chat: </span>
            <span class="value">405</span>
          </div>
        </div>

        <div class="member-action-item">
          <img src="/assets/img/icons/review-group-icon.svg" alt="revisões de estudos" srcset="">
          <div class="content">
            <span class="description">Revisões de Estudos: </span>
            <span class="value">8</span>
          </div>
        </div>
      </div>

    </div> -->
    <div class="modal-footer-custom text-center">
      <button class="btn btn-custom btn-modal-cancel mr-3" (click)="closeModal()"> {{"cancelar" | translate}} </button>
      <button class="btn btn-custom btn-modal-confirm" (click)="confirmOptionModal()"> {{"excluir" | translate}} </button>
    </div>
  </div>

</div>
