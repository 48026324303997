import 'rrweb-player/dist/style.css';

import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-modal-watch-video',
  templateUrl: './modal-watch-video.component.html',
  styleUrls: ['./modal-watch-video.component.scss']
})
export class ModalWatchVideoComponent implements OnInit {

  @Input('visible') visible: boolean;
  @Input('idStudy') idStudy: string;
  @Output('visibleChange') visibleChange: any = new EventEmitter();
  @Input('videoData') videoData?: any;
  @Output('confirmModalEvent') confirmModalEvent?: any = new EventEmitter();
  @ViewChild("player")
  private player: ElementRef;

  private readonly _HTML_BODY = document.querySelector("body");

  constructor(private _deviceDetectorService: DeviceDetectorService, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.visible = false;
    this._enableCustomClass();
  }

  get memberDataValue(): any {
    return this.videoData || '';
  }

  get isVisible(): boolean {
    this._enableCustomClass();
    return this.visible === true;
  }

  public closeModal(): any {
    this.visible = false;
    this._HTML_BODY.classList.remove('custom-z-index-modal-watch-video');
    this.visibleChange.emit(this.visible);
  }

  public confirmOptionModal(): any {
    this._HTML_BODY.classList.remove('custom-z-index-modal-watch-video');
    this.confirmModalEvent.emit(true);
  }

  private _enableCustomClass(): any {

    if (this.visible && this.visible === true) {
      this._scrollBotoomOnlyMobile();
      this._HTML_BODY.classList.add('custom-z-index-modal-watch-video');
    } else {
      this._HTML_BODY.classList.remove('custom-z-index-modal-watch-video');
    }
  }

  private _scrollBotoomOnlyMobile() {
    const userAgentIphone = window.navigator.userAgent.includes('Mac');
    if (this._deviceDetectorService.isMobile() && userAgentIphone) {
      this._HTML_BODY.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }
  }

  expandedVideo() {
    if (/Android|iPhone/i.test(navigator.userAgent)) {
      var elem: any = document.getElementById("watchVideoModal");
      console.log("Teste")
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      }
    }
  }

  // private _enableCustomClass(): any {
  //   return this.visible && this.visible === true ? this._HTML_BODY.classList.add('custom-z-index-modal-watch-video') : this._HTML_BODY.classList.remove('custom-z-index-modal-watch-video');
  // }

}
