<div class="notifications-timeline">
  <div class="header-notifications" >
    <span  class="title" id="notifications-title-header"> {{ 'notifications-community.notification' | translate }} <i class="fa fa-bell"></i> </span>
    <span class="badge">{{qtdNotification}} {{ 'notifications-community.news' | translate }}</span>
  </div>

  <div class="container-notifications">
    <div class="news">
      <div class="session-notifications">
        <span>{{ 'notifications-community.news' | translate | uppercase }}</span>
      </div>

      <div class="notification-item" *ngFor="let notificatonToReadItem of notificationToRead; let i = index">
        <div class="content">
          <div class="avatar-post"  *ngIf="notificatonToReadItem.typeFrom == 'ADMIN' " ><h3>G</h3></div>
          <div class="icon" *ngIf="notificatonToReadItem.typeFrom != 'ADMIN' "><i class="fa fa-star"></i></div>
          <div class="description"><span style="word-wrap: break-word;">{{ mountTranslate(notificatonToReadItem.message) }}</span></div>
        </div>
        <div class="current-date-item">
          <span>{{ dateFormated(notificatonToReadItem.creationDate)}}</span>
        </div>
      </div>

    </div>

    <div class="last">

      <div class="session-notifications">
        <span>{{ 'notifications-community.previous' | translate | uppercase }}</span>
      </div>

      <div class="notification-item" *ngFor="let notificatonToReadItem of notificationRead; let i = index">
        <div class="content">
          <div class="avatar-post"  *ngIf="notificatonToReadItem.typeFrom == 'ADMIN' " ><h3>G</h3></div>
          <div class="icon" *ngIf="notificatonToReadItem.typeFrom != 'ADMIN' "><i class="fa fa-star"></i></div>
          <div class="description"><span style="word-wrap: break-word;">{{ mountTranslate(notificatonToReadItem.message) }}</span></div>
        </div>
        <div class="current-date-item">
          <span>{{ dateFormated(notificatonToReadItem.creationDate)}}</span>
        </div>
      </div>

    </div>
  </div>
</div>
