import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { isNullOrUndefined } from "@swimlane/ngx-datatable";
import { CommentPost, Like, Post } from "app/pages/full-pages/homepage-timeline/post";
import { notification, userNotification } from "app/pages/full-pages/homepage-timeline/userNotification";
import { Constants } from "app/utils/constants";
import { JsonContainer } from "app/utils/json-container";
import { RestService } from "app/utils/rest-service";
import { StorageUtils } from "app/utils/storage-utils";


@Injectable()
export class NotificationService extends  RestService {
  private urlWSBase;
  private notificationsClient: WebSocket;

  constructor(private client: HttpClient, private storageUtils: StorageUtils) {
    super(client, 'notifications', storageUtils);
    this.urlWSBase = Constants.WS_URL
  }

  listNotificationToRead(idUser: String,callback:(result: JsonContainer, notificatins: notification[]) => void){
    this.executeGet(`notification/${idUser}`, callback)
  }

  listNotificationQtd(idUser: String,callback:(result: JsonContainer, qtd: any) => void){
    this.executeGet(`notificationQtd/${idUser}`, callback)
  }

  listNotificationRead(userNotification: notification, callback:(result: JsonContainer, notificatins: notification[]) => void){
    this.executePost('notification/', userNotification,callback)
  }

  markAsRead(idUser: String, callback:(result: JsonContainer, qtd: any) => void){
    this.executeGet(`markAsRead/${idUser}`,callback)
  }

  sendNotification(userNotification: notification, callback:(result: JsonContainer, notifications: notification[]) => void){
    this.executePost('createnotification', userNotification, callback)
  }

  openWSNotifications(cb: WSMessage) {
    if (this.isConnectedWSNotifications()) this.closeWSNotifications();
    this.notificationsClient = new WebSocket(
      `${this.urlWSBase}/ws/notifications`
    );
    this.notificationsClient.onmessage = cb;
  }

  closeWSNotifications() {
    this.notificationsClient.close();
  }

  sendMessageWSNotifications(mesage: string) {
    if(this.isConnectedWSNotifications())
      this.notificationsClient.send(mesage);
  }

  isConnectedWSNotifications() {
    if(isNullOrUndefined(this.notificationsClient)) return false;
    if(this.notificationsClient.readyState === this.notificationsClient.OPEN) return true;

    return false;
  }

}

interface WSMessage {
  (message: MessageEvent): void;
}
