import {Injectable} from '@angular/core';


import {HttpClient} from '@angular/common/http';
import {RestService} from '../../../../utils/rest-service';
import {StorageUtils} from '../../../../utils/storage-utils';
import {JsonContainer} from '../../../../utils/json-container';
import {HandHistory} from './hand-history';
import {UserPlayers} from './user-players';
import { HandHistoryPerformance } from './handHistoryPerformance';
import { HandHistorySearch } from './handHistorySearch';
import { HHSearch } from '../../select-group-study-page/hh-search-study';
import { ListPosition, ListPositionRequest } from 'app/pages/full-pages/hh/hh/ListPosition';
import { ImportSearchFileProcess } from '../../imports-api-consume/Model/ImportSearchFileProcess';

@Injectable()
export class HandHistoryService extends RestService {

  constructor(private client: HttpClient, private storageUtils: StorageUtils) {
    super(client, 'hh', storageUtils);
  }

  listByUser(idUser: string, callback: (result: JsonContainer, hhs: HandHistory[]) => void) {
    this.executeGet('listByUser/' + idUser, callback)
  }

  dropUserData(idUser: string, callback: (result: JsonContainer, hhs: HandHistory[]) => void) {
    this.executePut('dropUserData/' + idUser, null, callback)
  }

  dropHHBase(listIdHands: string[], callback: (result: JsonContainer, hh: any) => void) {
    this.executePost('dropHHBase/', listIdHands, callback);
  }

  loadUserPlayers(idUser: string, callback: (result: JsonContainer, up: UserPlayers) => void) {
    this.executeGet('loadUserPlayers/' + idUser, callback)
  }

  getPerformanceDashboard(idUser: string, search: HandHistorySearch, callback:(result: JsonContainer, hhs: HandHistoryPerformance) => void){
    this.executePost('performanceDashboard/' + idUser, search, callback)
  }

  search(search: HandHistorySearch, callback:(result: JsonContainer, hhs: HandHistoryPerformance) => void){
    this.executePost('searchHand' ,search, callback)
  }
  searchHH(search: HHSearch, callback:(result: JsonContainer, hhs: HHSearch) => void){
    this.executePost('searchHH' ,search, callback)
  }

  searchHHBase(search: HHSearch, callback:(result: JsonContainer, hhs: HHSearch) => void){
    this.executePost('searchHHBase' ,search, callback)
  }

  downloadHandTxt(id: string,  callback:(result: JsonContainer, hhs: any) => void){
    this.executeGet('downloadHandTxt/' + id,  callback)
  }

  listPosition(players: ListPositionRequest[], callback:(result: JsonContainer, listPosition: ListPosition[]) => void){
    this.executePost('listPosition' , players, callback)
  }

  searchFileProcess(search: ImportSearchFileProcess, callback: (result: JsonContainer, hhs: ImportSearchFileProcess[]) => void) {
    this.executePost('searchFileProcess', search, callback)
  }

  deleteFileProcess(ids: Array<String>, callback: (result: JsonContainer, hhs: ImportSearchFileProcess[]) => void) {
    this.executePost('deleteFileProcess', ids, callback)
  }

  startFileProcess(search: ImportSearchFileProcess, topic: string, callback: (result: JsonContainer, hhs: ImportSearchFileProcess[]) => void) {
    this.executePost(`startFileProcess/${topic}`, search, callback)
  }

  dropHHBaseQueue(listIdHands: string[], callback: (result: JsonContainer, hh: any) => void) {
    this.executePost('dropHHBaseQueue', listIdHands, callback);
  }

  dropHHBaseStatus(idUser: string, callback: (result: JsonContainer, hh: any) => void) {
    this.executeGet('dropHHBaseStatus/'+idUser, callback);
  }

}
