import 'rrweb-player/dist/style.css';

import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CommentPost, Gallery, Like, Post } from 'app/pages/full-pages/homepage-timeline/post';
import { User } from 'app/pages/msk-user/user/user';
import { UserService } from 'app/pages/msk-user/user/user-service';
import { VideoService } from 'app/pages/video-api/video-service/video-service.service';
import { HighlightService } from 'app/shared/services/highlight.service';
import { PostService } from 'app/shared/shared/post-service';
import { Constants } from 'app/utils/constants';
import { StorageUtils } from 'app/utils/storage-utils';
import * as hopscotch from 'hopscotch';
import { DeviceDetectorService } from 'ngx-device-detector';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { isNullOrUndefined } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-post-video-community',
  templateUrl: './post-video-community.component.html',
  styleUrls: ['./post-video-community.component.scss'],
  providers: [PostService,
    DatePipe
  ],
})
export class PostVideoCommunityComponent implements OnInit, OnDestroy {

  @Input('post') postList?: Post[];
  @Input('userAvatarImage') userAvatarImage?: any;
  @Input('post2') postLoad?: Post;
  @Input('update') update?: string[];
  @Input('filterDate') filterDate?: string;
  @Output('refresh') refresh = new EventEmitter<boolean>();
  @ViewChild('modal') private modal: NgbModalRef;
  @ViewChild('buttonVideo') private buttonVideo: ElementRef;

  events: any;
  private _title: boolean = true;
  private _showReply = false;
  postGallery: Gallery;
  postInterval = null;

  constructor(
    private translateService: TranslateService,
    private postService: PostService,
    private _deviceDetectorService: DeviceDetectorService,
    private highlightService: HighlightService,
    private userService: UserService,
    private cdRef: ChangeDetectorRef,
    private storage: StorageUtils,
    private modalService: NgbModal,
    private videoService: VideoService,
    private datepipe: DatePipe) { }

  commentPostInfo: CommentPost[];
  newMessage: String;
  addComment: CommentPost;
  likesAfter: number;
  userID = this.storage.getIdUser();
  userLikes?: Like[];
  videoSrc = Constants.VIDEO_PT_URL;

  ngOnInit(): void {
    // hopscotch.endTour(true);
    this.listCommentsLikes();
    clearInterval(this.postInterval);
    this.postInterval = setInterval(() => {
      if (this.update.length > 0) {
        this.updatePostCommentsLikes();
      }
    }, 10000);
  }

  ngAfterViewInit() {
    this.highlightService.highlightAll();
    // hopscotch.endTour(true);
    if (this.storage.getFirstLogin() == 'true') {
      let event = new Event('click');
      this.buttonVideo.nativeElement.dispatchEvent(event);
      this.storage.firstLogin('false');
      var user: User = this.storage.getAutoLogin();
      user.info.firstLogin = 'false';
      this.userService.update(user, (resp, result) => {

      });
    }
  }

  ngOnDestroy(): void {
    clearInterval(this.postInterval);
  }

  eventVideo(post: Post) {
    if (post.type == 'STUDY') {
      this.videoService.load(post.studySession, (response, resp) => {
        setTimeout(() => {
          this.events = resp.events;
          this.cdRef.detectChanges();
        }, 500);
      });
    }

  }

  expandedVideo(elemento: any) {
    if (/Android|iPhone/i.test(navigator.userAgent)) {
      var elem: any = elemento.target;
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      }
    }
  }

  listCommentsLikes() {
    this.postService.listLike(this.storage.getIdUser(), (resp, response) => {
      this.userLikes = response;
      this.postList.forEach(post => {
        this.postService.listComment(post.id, (resp, response) => {
          this.commentPostInfo = [];
          this.commentPostInfo = response?.listActiveComments;
          post.lastComments = response?.listActiveComments;
          post.gallery = post.gallery || [];
          post.gallery.forEach((url) => {
            post.urlVideo = url.urlFile;
            this.cdRef.detectChanges();
          });
          this.cdRef.detectChanges();
          // setTimeout(() => {
          //   this.updatelistCommentsLikes();
          // }, 10000);
        });
        post.fgLiked = false;
        this.userLikes?.forEach(obj => {
          if (obj.idObjectLiked === post.id) {
            post.fgLiked = true;
          }
        });

      });
    });
  }

  updatelistCommentsLikes() {
    clearInterval(this.postInterval);
    this.postInterval = setInterval(() => {
      this.postService.listLike(this.storage.getIdUser(), (resp, response) => {
        this.userLikes = response;
        this.postList.forEach(post => {
          this.postService.listComment(post.id, (resp, response) => {
            this.commentPostInfo = response?.listActiveComments;
            post.lastComments = response?.listActiveComments;
            this.cdRef.detectChanges();
          });
          post.fgLiked = false;
          this.userLikes?.forEach(obj => {
            if (obj.idObjectLiked === post.id) {
              post.fgLiked = true;
            }
          });
        });
      });
    }, 10000);
  }

  updatePostCommentsLikes() {
    this.postService.listLike(this.storage.getIdUser(), (resp, response) => {
      this.userLikes = response;
      this.postList.forEach(post => {
        const _f = this.update.find(i => i.startsWith(post.id.toString()));
        if (!!_f) {
          this.postService.listComment(post.id, (resp, response) => {
            this.commentPostInfo = response?.listActiveComments;
            post.lastComments = response?.listActiveComments;
            post.likes = Number(_f.split('_')[2]);
          });
          post.fgLiked = false;
          this.userLikes?.forEach(obj => {
            if (obj.idObjectLiked === post.id) {
              post.fgLiked = true;
            }
          });
          this.cdRef.detectChanges();
        }
      });
      this.refresh.emit(true);
    });
  }


  get allPosts(): any {
    return this.postList;
  }

  get commentPost(): any {

    return this.commentPostInfo;
  }

  get currentUserImage(): any {
    return this.userAvatarImage;
  }


  get videoThumb(): string {
    const externalLink = 'https://https://goodpoker-data-dev.s3-sa-east-1.amazonaws.com//post/thumbs/Function0<java.lang.String>/0cb0b0a8-ac34-4d0c-ad2f-2b89f61fa1ca.hdqwalls.com/wallpapers/poker-player-5k-cz.jpg';
    const bannerGoodPoker = '../../../../assets/img/banner/banner-login.jpg';
    return bannerGoodPoker;
  }


  get hasTitle(): any {
    return this._title === true;
  }

  get hasReplyActive(): boolean {
    return this._showReply === true;
  }

  public shareItemOnMobile(): any {

    const shareItem = {
      title: 'Compartilhar Post',
      text: 'Compartilhar Post',
      url: 'goodpoker.com.br',
    };

    if (navigator.share) {
      navigator.share(shareItem)
        .then(() => {
          alert('Compartilhado com sucesso.');
        })
        .catch((error) => console.log('Error sharing', error));
    } else {
      alert('share unvailable');
      return;
    }

  }

  public showOrHideReply(): any {

    let showOrHide: boolean = false;

    if (this._showReply === true) {
      showOrHide = false;
    }

    if (this._showReply === false) {
      showOrHide = true;
    }

    return this._showReply = showOrHide;
  }

  public deleteComment(comment) {

    const optionsDelete: SweetAlertOptions = {
      title: this.translateService.instant('homepage-timeline.atencao'),
      text: this.translateService.instant('homepage-timeline.deseja_esxcluir_o_comentario_selecionado'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      // confirmButtonText: 'Remover mais participantes',
      customClass: {
        container: 'swal2-container-custom',
        // title: 'swal-title-custom',
        // confirmButton: 'swal-actions-custom'
      },
    };

    Swal.fire(optionsDelete).then((result) => {
      if (result.value) {
        this.postService.deleteComent(comment, (result, resp) => {
          this.postList.forEach(post => {
            if (post.id == comment.idPost) {
              post.lastComments = resp;
              this.cdRef.detectChanges();
            }
          });

          this.cdRef.detectChanges();
        });
      }

    });

  }
  public onAddMessage(postItem) {

    if (this.newMessage != undefined) {
      this.addComment = new CommentPost();
      this.addComment.creationDate = this.datepipe.transform(new Date(Date.now()), 'yyyy-MM-dd hh:mm:ss');
      this.addComment.idUser = this.storage.getIdUser();
      this.addComment.nameUser = this.storage.getNameUser();
      this.addComment.text = this.newMessage;
      this.addComment.idPost = postItem.id;

      this.postService.addComent(this.addComment, (result, resp) => {
        this.postList.forEach(post => {
          if (post.id == postItem.id) {
            post.lastComments.push(resp);
          }
        });
        this.cdRef.detectChanges();
      });
      this.newMessage = '';

    }
  }

  public likePost(post, e): any {

    var likeItem = new Like();
    likeItem.idObjectLiked = post.id;
    likeItem.idObjectLiker = this.storage.getIdUser();
    likeItem.nameObjectLiker = this.storage.getNameUser();
    likeItem.nameObjectLiked = post.title;
    likeItem.typeObjectLiked = "POST";
    likeItem.typeObjectLiker = "USER";
    //  likeItem.creationDate = this.datepipe.transform(new Date(Date.now()), 'yyyy-MM-dd hh:mm:ss')
    post.fgLiked = true;
    post.likes = post.likes + 1;
    this.cdRef.detectChanges();
    this.postService.addLike(likeItem, (result, resonse) => {

    });

  }

  public dislikePost(post, e): any {
    var likeItem = new Like();
    likeItem.idObjectLiked = post.id;
    likeItem.idObjectLiker = this.storage.getIdUser();
    likeItem.nameObjectLiker = this.storage.getNameUser();
    likeItem.nameObjectLiked = post.title;
    likeItem.typeObjectLiked = "POST";
    likeItem.typeObjectLiker = "USER";
    post.fgLiked = false;
    post.likes = post.likes - 1;
    this.cdRef.detectChanges();
    this.postService.removeLike(likeItem, (result, resonse) => {

    });
  }

  public calDate(post) {
    const now = new Date();
    const past = new Date(post.creationDate);
    const diff = Math.abs(now.getTime() - past.getTime());
    const days = Math.ceil(diff / (1000 * 60 * 60 * 24));

    var result = "";
    if (days === 1) {
      result = days + " " + this.translateService.instant('homepage-timeline.dia');
    } else {
      result = days + " " + this.translateService.instant('homepage-timeline.dias');
    }
    return result;

  }


  openVerticallyCentered(content) {

    const modalRef = this.modalService.open(content, { windowClass: 'dark-modal', size: 'xl', centered: true });

    modalRef.result.then(() => {
      this.startTour();
    },
      () => {

        this.startTour();
      });


  }

  get isTabletDevice(): boolean {
    return this._deviceDetectorService.isTablet();
  }

  get isMobileDevice(): boolean {
    return this._deviceDetectorService.isMobile();
  }

  get isDesktopLargeScreen(): boolean {
    return window.innerWidth >= 1921;
  }



  startTour() {
    // Destroy running tour
    hopscotch.endTour(true);
    // Initialize new tour
    hopscotch.startTour(this._tourSteps());
  }

  private _tourSteps(): any {
    let currentTourTest = [];

    const currentWidthScreen = window.innerWidth;


    //   return {
    //    id: 'demo-tour',
    //    showPrevButton: true,
    const stepsTourDesktop = [
      {
        title: this.translateService.instant('tour.aprenda_com_a_comunidade'),
        content: this.translateService.instant('tour.publicacao_de_estudos_comentarios_e_engajamento'),
        target: "community-menu-item",
        placement: "right",
        xOffset: 15,
        arrowOffset: 10,
      },
      {
        title: this.translateService.instant('tour.estude_e_melhore_seu_jogo'),
        content: this.translateService.instant('tour.prepare_seus_estudos_comentarios_e_engajamento'),
        target: "study-menu-item",
        placement: "right",
        xOffset: 15,
        arrowOffset: 10,
      },
      {
        title: this.translateService.instant('tour.crie_e_participe_de_grupo_de_estudos'),
        content: this.translateService.instant('tour.convide_amigos_para_estudar'),
        target: "group-menu-item",
        placement: "right",
        xOffset: 15,
        arrowOffset: 10,
      },
      {
        title: this.translateService.instant('tour.importe_seus_dados'),
        content: this.translateService.instant('tour.importacao_de_hand_history_e_dashboard'),
        target: "upload-menu-item",
        placement: "right",
        xOffset: 15,
        arrowOffset: 10,
      },
      {
        title: this.translateService.instant('tour.acompanhe_o_seu_desempenho'),
        content: this.translateService.instant('tour.indicador_de_desempenho_performance_e_estatisticas'),
        target: "bankroll-menu-item",
        placement: "right",
        xOffset: 15,
        arrowOffset: 10,
      },
      {
        title: this.translateService.instant('tour.fique_por_dentro_com_as_notificacoes'),
        content: this.translateService.instant('tour.novidades_do_administrador_e_alertas_de_novas_anotacoes_sobre_os_estudos'),
        target: "notifications-title-header",
        placement: "left",
        xOffset: 0,
        arrowOffset: 0,
      }
    ];

    const stepsTourMobile = [
      {
        title: this.translateService.instant('tour.aprenda_com_a_comunidade'),
        content: this.translateService.instant('tour.publicacao_de_estudos_comentarios_e_engajamento'),
        target: "community-menu-item",
        placement: "right",
        xOffset: 10,
        arrowOffset: 30,
      },
      {
        title: this.translateService.instant('tour.fique_por_dentro_com_as_notificacoes'),
        content: this.translateService.instant('tour.acompanhe_as_novidades_do_adminitrador_e_alertas_de_novas_anotacoes_sobre_os_estudos_clicando_no_sininho_lado_direito'),
        target: "study-menu-item",
        placement: "right",
        xOffset: 50,
        yOffset: 0,
        arrowOffset: 200
      },
    ];

    const stepsTourTablet = [
      {
        title: this.translateService.instant('tour.aprenda_com_a_comunidade'),
        content: this.translateService.instant('tour.publicacao_de_estudos_comentarios_e_engajamento'),
        target: "community-menu-item",
        placement: "right",
        xOffset: 20,
        arrowOffset: 30,
      },
      {
        title: this.translateService.instant('tour.fique_por_dentro_com_as_notificacoes'),
        content: this.translateService.instant('tour.novidades_do_administrador_e_alertas_de_novas_anotacoes_sobre_os_estudos'),
        target: "study-menu-item",
        placement: "right",
        xOffset: 410,
        yOffset: currentWidthScreen >= 1024 ? 200 : 0,
        arrowOffset: 250,
      },
    ];

    if (currentWidthScreen >= 1200) {
      currentTourTest = stepsTourDesktop;
    }

    if (currentWidthScreen <= 767) {
      currentTourTest = stepsTourMobile;
    }

    if (currentWidthScreen >= 767 && currentWidthScreen <= 1199) {
      currentTourTest = stepsTourTablet;
    }

    const objTourSetup = {
      id: 'demo-tour',
      showPrevButton: true,
      showSkip: false,
      showCTAButton: false,
      steps: currentTourTest,
      // ctaLabel: 'Oi',
      width: 400,
      i18n: {
        nextBtn: this.translateService.instant('tour.proximo'),
        prevBtn: this.translateService.instant('tour.voltar'),
        doneBtn: this.translateService.instant('tour.comece_a_explorar'),
        skipBtn: this.translateService.instant('tour.pular_tour'),
      }
    };

    return objTourSetup;

  }

  filterPostByDate(postDate) {
    if(isNullOrUndefined(this.filterDate)) return true;

    const postTime = new Date(postDate).getTime();
    const filterTime = new Date(this.filterDate).getTime();
    if(postTime >= filterTime) return true;
    return false;
  }


}
