import {Injectable} from '@angular/core';


import {HttpClient} from '@angular/common/http';
import {StorageUtils} from '../../../utils/storage-utils';
import {User} from './user';
import {RestService} from '../../../utils/rest-service';
import {JsonContainer} from '../../../utils/json-container';
import {KeyAuth} from '../../msk-auth-key/key-auth';
import {MyGroup} from '../../full-pages/group/group/my-group';
import {UserCard} from './user-card';
import { PhotoUpload } from 'app/pages/msk-core/photo/photo-upload';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class UserService extends RestService {

  // `currentUser` contains the current user
  currentUser: Subject<User> = new BehaviorSubject<User>(null);

  constructor(private client: HttpClient, private storageUtils: StorageUtils) {
    super(client, 'userExt', storageUtils);
  }

  login(user: User, callback: (result: JsonContainer, user: User) => void) {
    this.executePost('login', user, callback)
  }

  autoLogin(user: User, callback: (result: JsonContainer, user: User) => void) {
    this.executePost('autoLogin', user, callback)
  }

  forgotPassword(email: string, callback: (result: JsonContainer, user: User) => void) {
    this.executePut('forgotPassword/' + email, null, callback)
  }

  validateKey(authKey: KeyAuth, callback: (result: JsonContainer, validated: boolean) => void) {
    this.executePost('validateKey', authKey, callback)
  }

  updatePassword(user: User, callback: (result: JsonContainer, pass: string) => void) {
    this.executePost('updatePassword', user, callback)
  }

  searchUserLikeEmail(email: string, callback: (result: JsonContainer, users: UserCard[]) => void) {
    this.executeGet('searchUserLikeEmail/' + email, callback)
  }

  searchUser(id: string, callback: (result: JsonContainer, users: User) => void) {
    this.executeGet('load/' + id, callback)
  }

  update(user: User, callback: (result: JsonContainer, users: User) => void) {
    this.executePost('updateUserProfile',user, callback)
  }

  uploadPhoto(formData: FormData, callback: (result: JsonContainer, url: string) => void) {
    this.executePostPhoto('uploadUserPhoto',formData, callback)
  }

  public setCurrentUser(newUser: User): void {
    this.currentUser.next(newUser);

  }

  increaseStudiesCount(user: User, callback: (result: JsonContainer, studiesCount: number) => void) {
    this.executePost('increaseStudiesCount', user, callback)
  }

  cleanStudiesCount(user: User, callback: (result: JsonContainer, studiesCount: number) => void) {
    this.executePost('cleanStudiesCount', user, callback)
  }

  getStudiesList(id: string, callback: (result: JsonContainer, studiesList: string[]) => void) {
    this.executeGet('getStudiesList/' + id, callback)
  }

  getFreeStudiesList(id: string, callback: (result: JsonContainer, studiesList: string[]) => void) {
    this.executeGet('getFreeStudiesList/' + id, callback)
  }

  getQtdStudies(id: string, callback: (result: JsonContainer, studiesList: number) => void) {
    this.executeGet('getQtdStudies/' + id, callback)
  }  
}
