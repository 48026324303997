<div class="add-member-container">

  <div class="add-member-header">
    <ng-container *ngIf="isShowAddUsersBox">
      <span>Selecione um grupo e adicione os participantes: </span>
    </ng-container>
    <ng-container *ngIf="isShowInviteBoxUser">
      <span>Convidar usuários para a plataforma: </span>
      <span>insira os e-mails:</span>
    </ng-container>
  </div>

 
    <div *ngFor="let group of groupsList" class="button-group">
      <button class="btn btn-secondary btn-lg" (click)="getGroupId(group)">{{group.title}}</button>
    </div>
  

  <!-- Area for List members search to include in the group -->
  <div *ngIf="isShowAddUsersBox">
    <div class="form-group form-group-custom-add">
      <div class="container-participant-selected">
        <div class="add-participant-selected-item mr-1" *ngFor="let item of mockAnyParticipantsSelect">
          <div class="avatar mr-1">
            <img src="assets/img/portrait/small/avatar-s-27-base.jpeg" alt="" srcset="">

          </div>
          <div class="description">
            <span>{{item.name}}</span>
          </div>
          <a (click)="deleteMemberItem(i)"> <i class="fas fa-times"></i> </a>
        </div>

        <input type="text" class="form-control" [ngModel]="emailSearchValue" (change)="searchEmailParticipant($event)"
          placeholder="+ E-mail">
      </div>
    </div>

    <div class="add-member-content">
      <div class="add-members-list mt-2" *ngIf="isParticipantEmailFounded">
        <div class="add-member-content">
          <div class="add-members-list-item" *ngFor="let currentParticipant of participantsListResult">

            <div class="avatar mr-3">
              <img src="assets/img/portrait/small/avatar-s-27-base.jpeg" alt="" srcset="">
              <!-- <img *ngIf="currentParticipant.avatar != null" src="{{currentParticipant.avatar}}" alt="" srcset=""> -->
            </div>
            <div class="description mr-3">
              <div class="name-participant">
                <span>{{currentParticipant.name}}</span>
                <!-- <span class="is-admin">admin</span>  -->
              </div>
              <!-- <span>Membro há 2 meses</span>  -->
            </div>
            <button class="btn btn-custom btn-add-member"
              (click)="  addMember(currentParticipant.name, currentParticipant.email , currentParticipant.avatar , currentParticipant.id)">
              <i class="fas fa-user-plus"></i> Adicionar <span class="d-mobile-none">participante</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="groupVerificationEmail && groupVerificationId">
      <button class="btn btn-custom btn-finish" (click)="submitMemberToGroup()">Concluído</button>
    </div>

  </div>
  <!-- End for List members search to include in the group -->

  <!-- Area for Invite member not found -->
  <div class="container-invite-icipants" *ngIf="isShowInviteBoxUser">
    <fieldset>
      <div class="form-group form-group-invite">
        <tag-input [(ngModel)]="newInvitesList" (onSelect)="onSelect($event)" [modelAsStrings]="true" clearOnBlur="true"
          [placeholder]="'Separe por ; ou SPACE'" [secondaryPlaceholder]="'Separe por ; ou SPACE'"
          [separatorKeyCodes]="[32, 188, 191]">
        </tag-input>
      </div>
    </fieldset>
    <!-- teste de log -->
    <!-- <div (click)="log()">
      <p>Log</p>
    </div> -->
    <div class="">
      <button class="btn btn-custom btn-add-member mr-3" (click)="showAddUser()">
        Voltar <span class="d-mobile-none"> </span>
      </button>
      <button class="btn btn-custom btn-send-invite" (click)="confirmEmailInvite()">
        Enviar <span class="d-mobile-none"></span>
      </button>
    </div>
  </div>
  <!-- End Area for Invite member not found -->

</div>

<app-modal-member-not-found [visible]="showModalNotFound" (visibleChange)="modalChangeVisible($event)"
  [memberData]="emailSearchValue" (confirmModalEvent)="modalEventConfirm($event)"></app-modal-member-not-found>
<!-- <app-modal-confirm-invite-members></app-modal-confirm-invite-members> -->