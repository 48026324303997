import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FileUploader } from 'ng2-file-upload';
import swal from 'sweetalert2';

import { Constants } from '../../../utils/constants';
import { StorageUtils } from '../../../utils/storage-utils';

// import { EventEmitter } from 'events';
@Component({
  selector: 'app-upload-imports-page',
  templateUrl: './upload-imports-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./upload-imports-page.component.scss']
})
export class UploadImportsPageComponent implements OnInit {

  arquivosEntrada: number;
  arquivosSaida: number;
  uploading = false;
  progressNumber: number;
  //hhret: string[];
  hhret: number;

  supportedFileTypes: string[] = ['text/txt'];


  // tslint:disable-next-line:no-output-rename
  @Output('uploadResponse') uploadResponse?= new EventEmitter();
  @Output('uploadCompleted') uploadCompleted = new EventEmitter<boolean>();

  private readonly _uploadEndpoint = 'https://goodpoker.startup-kit.net/api/import/uploadTest';
  hasBaseDropZoneOver = false;



  private _optionsUpload = {
    url: this._uploadEndpoint,
    isHTML5: true,
    autoUpload: false,
    removeAfterUpload: true,
    // allowedFileType: ['txt'],
    // maxFileSize: 50,
  };
  // git doc uploader = https://valor-software.com/ng2-file-upload/
  public uploader: FileUploader;

  constructor(
    private translateService: TranslateService,
    private storage: StorageUtils,
    private router: Router,
    private ref: ChangeDetectorRef) {
    this.arquivosEntrada = 0;
    this.arquivosSaida = 0;
    //this.hhret = []
    this.hhret = 0;
  }

  ngOnInit(): void {
    this.createUploader();

  }

  public hasUploadRunning() {
    let cont = 0; console.log(this.hhret);
    for (let i = 0; i < this.hhret; i++) {
      if (this.hhret != 0) {
        cont++;


      }
    }
    console.log(this.uploader);
    if (this.progressNumber == 100 && cont != 0) {
      this.uploading = false;
      this.translateService.instant('upload.dados_foram_incluidos_com_sucesso');
      // swal.fire('Sucesso', 'Dados foram incluidos com sucesso! Quantidades de HandHistorys duplicados: ' + this.hhret, 'success');
      swal.fire(this.translateService.instant('upload.sucesso'), this.translateService.instant('upload.dados_foram_incluidos_com_sucesso'), 'success').then(ret => {
        if (ret.isConfirmed) {
          this.uploadCompleted.emit(true);
        }
        // console.log('ret', ret)
      });
      //this.hhret.splice(0, this.hhret.length);
      this.hhret = 0;
      this.arquivosSaida = 0;
      this.arquivosEntrada = 0;
      cont = 0;
      this.ref.detectChanges();
    } else if (this.progressNumber == 100) {
      swal.fire(this.translateService.instant('upload.sucesso'), this.translateService.instant('upload.dados_foram_incluidos_com_sucesso'), 'success');
      //this.hhret.splice(0, this.hhret.length);
      this.hhret = 0;
      this.arquivosSaida = 0;
      this.arquivosEntrada = 0;
      cont = 0;
      this.uploading = false;
      this.ref.detectChanges();
    }
  }

  public hasErrorUpload(): any {
    console.log('response upload metodo:',);

    return this.uploader.response.subscribe((response) => {
      console.log('response upload:', response);
    });
  }

  public createUploader() {
    this.uploader = new FileUploader({
      url: `${Constants.SERVICE_URL}/api/hh/uploadFile`,
      allowedMimeType: ['text/plain'],
      isHTML5: true,
      headers: [
        { name: 'Authorization', value: `Bearer ${this.storage.getToken()}` },
        { name: 'Access-Control-Allow-Origin', value: '*' },
        { name: 'Access-Control-Allow-Headers', value: 'Content-Type' },
      ],
      itemAlias: 'file',
      queueLimit: 20,
      autoUpload: true,
      parametersBeforeFiles: true,
      additionalParameter: {
        idUser: this.storage.getIdUser()
      }
    });

    this.uploader.onProgressItem = (item: any, progress: any) => {
      console.log('onProgressItem', item, progress);
    };

    this.uploader.onAfterAddingFile = (item: any) => {
      item.file.name = String(item.file.name).normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      console.log('onAfterAddingFile', this.arquivosEntrada, item.file.name); //.queue[this.arquivosEntrada]);
      item.withCredentials = false;
      this.uploading = true;
      this.arquivosEntrada++;
    };

    this.uploader.onErrorItem = (item: any) => {
      console.log('onErrorItem');

      // swal.fire({
      //   title: 'ERRO!',
      //   text: "Verifique se sua importação está traduzida em inglês, e importe novamente",
      //   icon: 'error',
      //   confirmButtonText: 'Voltar',
      //   customClass: {
      //     confirmButton: 'btn btn-primary',
      //     cancelButton: 'btn btn-danger ml-1'
      //   },
      //   buttonsStyling: false,
      // }).then(function (result) {
      //   if (result.value) {
      //     console.log("test");


      //   }
      // });
      // this.reloadComponent()
    };

    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      console.log('onCompleteItem');
      const resp = JSON.parse(response) as number;
      console.log(resp);

      //this.hhret.unshift(resp[0]);
      this.hhret += resp;
      console.log(this.hhret);
      this.arquivosSaida++;
      this._uploadFiles();
      if (this.progressNumber == 100) {
        this.hasUploadRunning();
        this.createUploader();
      }
    };
  }

  public reloadComponent() {
    let currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);
  }

  public fileOverBase(event: any): void {
    this.hasBaseDropZoneOver = event;
    this._uploadFiles();
  }

  public cancelAllUploadItems() {

    this.uploader.cancelAll();
    this.uploader.clearQueue();
    // this.uploader.isUploading = false;
    // this.uploader.progress = 0;

    console.log('upload apos cancelar: ', this.uploader);

  }

  public fileSelected(event) {
    this._uploadFiles();
  }

  //////////////////// PRIVATE METHODS ///////////////////////
  ///////////////////////////////////////////////////////////

  private _uploadFiles() {
    this.progressNumber = Number(((100 / this.arquivosEntrada) * (this.arquivosSaida)).toFixed(2));
    this.uploader.progress = this.progressNumber;
    this.uploader.isUploading = this.uploading;
    // this.uploader.uploadAll();
    // remove this value, something demo
    this.uploader.response.subscribe((response) => {
      this.uploadResponse.emit(true);
    });

    this.ref.detectChanges();
  }

  private _mockUploadFIles() {
    return setTimeout(() => {
      const count = 0;
      // this._spinner.show(undefined,
      //   {
      //     type: 'ball-triangle-path',
      //     size: 'medium',
      //     bdColor: 'rgba(0, 0, 0, 0.8)',
      //     color: '#fff',
      //     fullScreen: true
      //   });

    }, 1000);
  }
}
