<div class="gp">
  <div class="gp-container">
    <div id="gp-estudo">
      <div id="gp-estudo-nome">
        <img src="/assets/img/logos/pokerstars-logo.png" alt="" srcset=""
          *ngIf="handleHistory[sectionStudyService.currentIndex].platform == 'POKER_STARS'" height="30px" width="100px">
        <img src="/assets/img/logos/partypoker-logo.png" alt="" srcset=""
          *ngIf="handleHistory[sectionStudyService.currentIndex].platform == 'PARTY_POKER'" height="30px" width="100px">
        <img src="/assets/img/logos/bodog-logo.png" alt="" srcset="" *ngIf="handleHistory[sectionStudyService.currentIndex].platform == 'BODOG'"
          height="30px" width="100px">
        <!-- <img class="small-img-size" src="/assets/img/logos/888poker-logo.png" alt="" srcset=""
          *ngIf="handleHistory[sectionStudyService.currentIndex].platform == 'POKER_888'" height="30px" width="100px"> -->
        <img class="small-img-size" src="/assets/img/logos/winamax-logo.png" alt="" srcset=""
          *ngIf="handleHistory[sectionStudyService.currentIndex].platform == 'WINAMAX'" height="30px" width="100px">
        <img class="small-img-size" src="/assets/img/logos/wpn_logo.png" alt="" srcset=""
          *ngIf="handleHistory[sectionStudyService.currentIndex].platform == 'WPN'" height="30px" width="100px">
        <img src="/assets/img/logos/ipoker-logo.png" alt="" srcset="" *ngIf="handleHistory[sectionStudyService.currentIndex].platform == 'IPOKER'"
          height="30px" width="100px">
        <img src="/assets/img/logos/logo-ggnetwork.png" alt="" srcset=""
          *ngIf="handleHistory[sectionStudyService.currentIndex].platform == 'GG_NETWORK'" height="30px" width="100px">
        <img src="/assets/img/logos/chico-poker-logo.png" alt="" srcset="" *ngIf="handleHistory[sectionStudyService.currentIndex].platform == 'CHICO'"
          height="30px" width="100px">
      </div>
      <div class="header-info">
        <span class="header-descriptions" id="gp-partidas-carregadas" style="display: none;" [attr.data-partidas_carregadas]="handleHistory.length"><strong>Partidas Carregadas: </strong> {{handleHistory.length}}</span>
        <span class="header-descriptions" id="gp-partidas-atual" style="display: none;" [attr.data-partidas_atual]="sectionStudyService.currentIndex + 1"><strong>Partida Atual: </strong> {{sectionStudyService.currentIndex + 1}}</span>
        <span class="header-descriptions" *ngIf="handleHistory[sectionStudyService.currentIndex]?.hand"> {{handleHistory[sectionStudyService.currentIndex].hand}}</span>
        <span class="header-descriptions" *ngIf="handleHistory[sectionStudyService.currentIndex].cash"> {{handleHistory[sectionStudyService.currentIndex].cash}}</span>
        <span class="header-descriptions">{{handleHistory[sectionStudyService.currentIndex].level}}</span>
        <span class="header-descriptions">{{handleHistory[sectionStudyService.currentIndex].date | date:'dd/MM/yyyy'}}</span>
        <span class="header-descriptions" *ngIf="handleHistory[sectionStudyService.currentIndex].hhPlayer.blindWin"
        [ngStyle]="{'color': handleHistory[sectionStudyService.currentIndex].hhPlayer.blindWin > 0 ? 'green' :
          handleHistory[sectionStudyService.currentIndex].hhPlayer.blindWin < 0 ? '#D92131' : 'black' }">
          {{toFixedIfNecessary(handleHistory[sectionStudyService.currentIndex].hhPlayer.blindWin)}}
        </span>
      </div>
      <div style="visibility: hidden;" id="gp-players-count">1/<span id="gp-players-total-count">0</span> {{ 'controllers.jogadores' | translate }}</div>
      <div style="visibility: hidden;" id="gp-revisoes"><span id="revisao-atual">0</span> de <span id="todas-revisoes">0</span> {{ 'controllers.revisoes' | translate }}
      </div>
    </div>
    <!-- <div id="gp-tutorial">Ver Tutorial</div> -->
  </div>
</div>
<app-desk style="display: flex; justify-content: center;" [handleHistory]="handleHistory" (buttonSkip)="deleteList($event)"></app-desk>
<app-controllers *ngIf="isVisible" [handleHistory]="handleHistory" [studyTitle]="studyTitle" (buttonSkip)="deleteList($event)" (modalChangeVisible)="modalChangeVisible($event)" [showCloseStudySession]="showCloseStudySession"></app-controllers>
