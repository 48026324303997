<div class="modal-container-custom" *ngIf="isVisible">
  <div class="modal-body-custom animate__animated" [ngClass]="{'animate__bounceIn': isVisible, 'animate__bounceOut': !isVisible}">
    <div class="modal-header-custom">
      <p>{{ 'modal.voce_realmente_deseja_encerrar' | translate }}</p>
    </div>
    <div class="modal-content-custom text-center">
      <p>{{ 'modal.nenhum_usuario_conseguira_fazer_mais_revisoes' | translate }}</p>
      <p>{{ 'modal.ao_clicar_em_encerrar_um_video_sera_gerado_desse_estudo_sera_arivado_na_biblioteca' | translate }}</p>
    </div>
    <div class="modal-footer-custom text-center">
      <button class="btn btn-custom btn-modal-cancel mr-3" (click)="closeModal()"> {{ 'modal.cancelar' | translate }} </button>
      <button class="btn btn-custom btn-modal-confirm" (click)="confirmOptionModal()"> {{ 'modal.encerrar' | translate }} </button>
    </div>
  </div>
</div>
