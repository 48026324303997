import { Component, HostBinding, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal-content-shared',
  templateUrl: './modal-content-shared.component.html',
  styleUrls: ['./modal-content-shared.component.scss']
})
export class ModalContentSharedComponent implements OnInit {
  @HostBinding('class.active') active: boolean = false;

  constructor() { }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.dismiss();
  }

  ngOnInit(): void {
  }

  present() {
    this.active = true;
  }
  dismiss() {
    this.active = false;
  }
}
