import { Round } from "app/shared/components/replayer-table/types/desk-type";

export class UsersChat {
  userId: string;
  name: string;
  avatar: string;
  lastChatTime?: string;
  status?: string;
  isPinnedUser?: boolean;
  isMuted?: boolean;
  unreadMessageCount?: string;
  isActiveChat: boolean;
  lastChatMessage?: string;
  chats: Chat[];
  idChat?: String;
  // message: Messages[];


  // constructor(userId: string, name: string, avatar: string, lastChatTime: string, status: string, isPinnedUser: boolean, isMuted: boolean, unreadMessageCount: string, isActiveChat: boolean, lastChatMessage: string, chats: Chat[], ) {
  //   this.userId = userId;
  //   this.name = name;
  //   this.avatar = avatar;
  //   this.lastChatTime = lastChatTime;
  //   this.status = status;
  //   this.isPinnedUser = isPinnedUser;
  //   this.isMuted = isMuted;
  //   this.unreadMessageCount = unreadMessageCount;
  //   this.isActiveChat = isActiveChat;
  //   this.lastChatMessage = lastChatMessage;
  //   this.chats = chats;
  // }

}

export class Chat {
  public isReceived: boolean; // true = received, false = sent
  public time: string;
  public messages: string[];
  public reply: any;
  public messageType: string;
  public round?: Round; // text, audio, video
  public nameUser?: string;
  public avatarUser?: string;

  constructor(isReceived: boolean, time: string, messages: string[], messageType: string, replyMessage?: any, round?: Round, nameUser?: string, avatarUser?: string) {
    this.isReceived = isReceived;
    this.time = time;
    this.messages = messages;
    this.messageType = messageType;
    this.reply = replyMessage || null;
    this.round = round;
    this.nameUser = nameUser;
    this.avatarUser = avatarUser;
  }
}

export class Messages{
  date: string
  idChat: String
  idUser: string
  message: string
  nameUser: string
  replyMessage: string


  // constructor(date: string, idChat: string, idUser: string, message: string ,nameUser: string) {
  //   this.date = date;
  //   this.idChat = idChat;
  //   this.idUser = idUser;
  //   this.message = message;
  //   this.nameUser = nameUser;
  // }
}

export class ChatGroup{
  idUser: string
  idSession: string
  idUserList: Array<any>
}

export class UsersChatGroup{
  idUserList: Array<groupUsers>
  avatar: string;
  lastChatTime?: string;
  status?: string;
  isPinnedUser?: boolean;
  isMuted?: boolean;
  unreadMessageCount?: string;
  isActiveChat: boolean;
  lastChatMessage?: string;
  chats: Chat[];
}

export class groupUsers{
  id: string
  name: string
}

