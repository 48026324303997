import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JsonContainer } from 'app/utils/json-container';
import { RestService } from 'app/utils/rest-service';
import { StorageUtils } from 'app/utils/storage-utils';
import { MyGroup } from '../Model/MyGroup';
import { UserCard } from '../Model/UserCard';

@Injectable()
export class GroupService extends RestService {

  constructor(private client: HttpClient, private storageUtils: StorageUtils) {
    super(client, 'group', storageUtils);
  }

  listByUser(idUser: string, callback: (result: JsonContainer, myGroup: any) => void) {
    this.executePost('listByUser/' + idUser, null, callback)
  }

  imageLoad(idGroup: string, callback: (result: JsonContainer, myGroup: MyGroup) => void) {
    this.executeGet('image/' + idGroup, callback)
  }

  create(myGroup: MyGroup, callback: (result: JsonContainer, myGroup: MyGroup ) => void) {
    this.executePost('create', myGroup, callback)
  }
// 
  addUser(myGroup: MyGroup, callback: (result: JsonContainer , myGroup: MyGroup) => void) {
    this.executePost('adduser', myGroup, callback)
  }

  deleteGroup(myGroup: MyGroup, callback: ( result: JsonContainer, myGroup: MyGroup) => void) {
    this.executePost('delete', myGroup, callback)
  }

  removeUser(myGroup: MyGroup, callback: (result: JsonContainer , myGroup: MyGroup) => void) {
    this.executePost('removeUser', myGroup, callback)
  }

  loadGroup(idGroup : string , callback: (result: JsonContainer, myGroup:any ) => void){
    this.executeGet('load/' + idGroup , callback )
  
  }
  byEmail(myGroup: MyGroup, callback: (result: JsonContainer, user: UserCard) => void) {
    this.executePost('byEmail', myGroup, callback)
  }
  getGroup(id: string, callback: (result: JsonContainer, myGroup: MyGroup) => void) {
    this.executePost('getGroup', id, callback)
}
  inviteUser(myGroup: MyGroup, callback: (result: JsonContainer, myGroup: MyGroup) => void) {
    this.executePost('inviteUser', myGroup, callback)
}

}
