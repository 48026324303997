import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Chat, UsersChat } from 'app/chat/chat.model';
import { GroupUser } from 'app/pages/full-pages/imports-api-group/Model/GroupUser';
import { StudySession } from 'app/pages/study-api/study-model/StudySession';
import { StudyService } from 'app/pages/study-api/study-service/study-service';
import { ReplayerTableComponent } from 'app/shared/components/replayer-table/replayer-table.component';
import { HandleHistory, Round, RoundAction } from 'app/shared/components/replayer-table/types/desk-type';
import { BehaviorSubject } from 'rxjs';
import Swal, { SweetAlertOptions } from 'sweetalert2';


export const listForAnimation = trigger("animationList", [
  transition("* => *", [
    // each time the binding value changes
    query(":leave", [stagger(100, [animate("0.5s", style({ opacity: 0 }))])], {
      optional: true
    }),
    query(
      ":enter",
      [
        style({ opacity: 0 }),
        stagger(300, [animate("0.5s", style({ opacity: 1 }))])
      ],
      { optional: true }
    )
  ])
]);

@Component({
  selector: 'app-record-video',
  templateUrl: './record-video.component.html',
  styleUrls: ['./record-video.component.scss'],
  providers: [StudyService],
  animations: [
    listForAnimation
  ]
})



export class RecordVideoComponent implements OnInit {
  @ViewChild("replayerTable") replayerTable: ReplayerTableComponent;
  handleHistoryJSON: HandleHistory[] = [];
  studyTitle: string = "";
  statusVideo: string = "GRAVANDO";
  statusVideo$ = new BehaviorSubject<string>("GRAVANDO");
  notepad: UsersChat = {} as UsersChat;
  groupUser: GroupUser[] = [];
  arr = [];
  roundVisited: Round[] = [];
  studySessionId: string | undefined;

  buttonPlay = document.getElementById('play');
  buttonPrev = document.getElementById('prev');
  buttonNext = document.getElementById('next');
  buttonSkip = document.getElementById('skip');
  revisao_atual = document.getElementById('revisao-atual');
  revisao_total = document.getElementById('todas-revisoes');
  partidas_atual = document.getElementById('gp-partidas-atual');
  partidas_carregadas = document.getElementById('gp-partidas-carregadas');

  intervalCheck: NodeJS.Timeout = null;

  constructor(
    private studyService: StudyService,
    private ref: ChangeDetectorRef,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    document.body.classList.remove("layout-dark");
    document.body.style.cursor = 'none';
    this.route.params.subscribe((params) => {
      this.studySessionId = params.id;
      this.loadHH(params.id);
    });
  }

  get hand_atual() {
    return Number(this.partidas_atual?.dataset?.partidas_atual);
  }
  get hand_total() {
    return Number(this.partidas_carregadas?.dataset?.partidas_carregadas);
  }

  //GRAVAÇÃO DE TELA
  async recorderScreen() {
    var stream = await navigator.mediaDevices.getDisplayMedia({
      video: true
    });
    const mime = MediaRecorder.isTypeSupported("video/webm; codecs=vp9")
      ? "video/webm; codecs=vp9"
      : "video/webm";
    let mediaRecorder = new MediaRecorder(stream, {
      mimeType: mime
    });

    let chunks: any = [];
    mediaRecorder.addEventListener('dataavailable', function (e) {
      chunks.push(e.data);
    });

    mediaRecorder.addEventListener('stop', () => {
      let blob = new Blob(chunks, {
        type: chunks[0].type
      });
      var file = new File([blob], 'GoodPoker-video', { type: mime, lastModified: Date.now() });
      const formData = new FormData();
      console.log("enviando video");
      formData.append("idStudy", this.studySessionId);
      formData.append("video_file", file, file.name);
      this.saveVideo(formData);

      // let url = URL.createObjectURL(blob);

      // // let video: HTMLVideoElement = document.querySelector("video")!;
      // // video.src = url;

      // let a = document.createElement('a');
      // a.href = url;
      // a.download = `GoodPoker-undefined.webm`;
      // a.click();
    });

    this.statusVideo$.subscribe(status => {
      if (status == "FINALIZADO") {
        mediaRecorder.stop();
      }
    });
    //we have to start the recorder manually
    // mediaRecorder.start();
  }

  saveVideo(file: FormData) {
    this.studyService.closeSession(file, async (response, resp) => {
      const optionsFound: SweetAlertOptions = {
        title: '',
        text: 'Estudo encerrado com sucesso!',
        icon: 'success',
        customClass: {
          container: 'swal2-container-custom',
        },
      };
      Swal.fire(optionsFound).then(() => {
        this.statusVideo = "FINALIZADO";
        var tab = window.open("", "_self");
        tab.close();
      });
      setTimeout(() => {
        this.statusVideo = "FINALIZADO";
        var tab = window.open("", "_self");
        tab.close();
      }, 200);
    });


  }

  async loadHH(_idSession: string) {
    var listSession = new StudySession();
    listSession.id = _idSession;
    this.studyService.tableSearch(listSession, async (response, resp) => {
      if(!response.success) {
        this.forceClose();
        return;
      }

      this.handleHistoryJSON = resp.hhStudy;
      this.studyTitle = resp?.studyTitle;
      this.groupUser = resp?.groupStudy?.participants;

      let statusAction = [
        "SHOWS",
        "MUCKED",
        "CALCULATE",
        "COLLECTED_FROM_POT",
        "CASHED_OUT",
      ];

      let roundCalculate: Round = {
        action: RoundAction.CALCULATE,
        fgAllIn: false,
        index: 222,
        step: "CALCULATE",
      };

      this.handleHistoryJSON.forEach((handleHistory) => {
        handleHistory.rounds.push(roundCalculate);

        handleHistory.rounds = handleHistory.rounds.filter(
          (round) => round.action != "MUCKS"
        );
        handleHistory.rounds = handleHistory.rounds.filter(
          (round) => round.action != "DOENST_SHOW_HAND"
        );
        handleHistory.rounds.sort(
          (a, b) =>
            statusAction.indexOf(a.action) - statusAction.indexOf(b.action)
        );

        let indexCalculate = handleHistory.rounds.findIndex(
          (round) => round.action == "CALCULATE"
        );
        handleHistory.rounds[indexCalculate].step =
          handleHistory.rounds[indexCalculate - 1].step;
      });
      // await this.recorderScreen();
      this.ref.markForCheck();
      setTimeout(() => {
        this.initTable();

      }, 500);
    });
  }

  buttonLoad() {
    this.buttonPlay = document.getElementById('play');
    this.buttonSkip = document.getElementById('skip');
    this.buttonPrev = document.getElementById('prev');
    this.buttonNext = document.getElementById('next');
    this.revisao_atual = document.getElementById('revisao-atual');
    this.revisao_total = document.getElementById('todas-revisoes');
    this.partidas_atual = document.getElementById('gp-partidas-atual');
    this.partidas_carregadas = document.getElementById('gp-partidas-carregadas');
  }

  initTable() {
    this.buttonLoad();
    this.checkRevisao();
    // this.nextTable();
  }

  getImageAnnotation(nameUser: string) {
    const userImage = this.groupUser?.filter(user => user.name == nameUser)[0];
    if (userImage) return userImage.avatar;
    return "";
  }


  checkRevisao() {
    this.intervalCheck = setInterval(() => {
      var handleHistoryCurrent = this.replayerTable?.controllersComponent?.handleHistoryCurrent;
      var roundRevisao = this.replayerTable?.controllersComponent?.roundRevisao;
      var currentIndex = handleHistoryCurrent.rounds.findIndex(round => round.index == roundRevisao.index);


      // Se estiver no ultimo step da HH ele valida se e o ultimo hh da sessao
      if (this.revisao_atual?.innerHTML == this.revisao_total?.innerHTML) {

        if (this.roundVisited.find(round => round.index == roundRevisao.index) == null) {
          clearInterval(this.intervalCheck);
          this.getAnnotations(handleHistoryCurrent.id, roundRevisao);
          return;
        }

        if (Number(this.partidas_atual.dataset.partidas_atual) >= Number(this.partidas_carregadas.dataset.partidas_carregadas)) {
          // Se for o ultimo hh da sessao ele vai stopar o recorder e mandar para o back;
          clearInterval(this.intervalCheck);
          setTimeout(() => {
            document.getElementById("square-top").classList.add("transform-zero");
            document.getElementById("square-bottom").classList.add("transform-zero");
            document.getElementById("logotipo").style.opacity = "1";
            setTimeout(() => {
              this.stopAndReplayRecord();
            }, 2000);
          }, 4000);
        } else {
          // Se não for o ultimo hh da sessao ele vai clicar em next para pular para a proxima hh;
          clearInterval(this.intervalCheck);
          this.roundVisited = [];
          this.buttonSkip.click();
          setTimeout(() => {
            this.buttonLoad();
            this.checkRevisao();
          }, 1000);
        }

      } else {
        // Se mudar o step ele faz o get das anotacoes e exibe
        if ((roundRevisao.step == "SHOW_DOWN" ? "RIVER" : roundRevisao.step) != (handleHistoryCurrent.rounds?.[currentIndex + 1]?.step == "SHOW_DOWN" ? "RIVER" : handleHistoryCurrent.rounds?.[currentIndex + 1]?.step)
          && this.roundVisited.find(round => round.index == roundRevisao.index) == null) {
          clearInterval(this.intervalCheck);
          this.getAnnotations(handleHistoryCurrent.id, roundRevisao);
          return;
        }

        // Se nao estiver no ultimo step ele vai clicando em next
        this.buttonNext.click();
      }
    }, 800);
  }

  getAnnotations(handleHistoryId: string, round: Round) {
    this.roundVisited.push(round);
    this.studyService.searchNotes(handleHistoryId, round.step == "SHOW_DOWN" ? "RIVER" : round.step, this.studySessionId, (response, resp) => {
      this.notepad.chats = [];
      if (response.success && resp?.length > 0) {
        resp.map((annotation) => {
          annotation.annotationList.forEach((messageContent) => {
            this.notepad.chats.push(
              new Chat(
                false,
                messageContent.date,
                [messageContent.text],
                "text",
                null,
                messageContent.round,
                messageContent.nameUser
              )
            );
          });
        });

        // Timer para voltar o player quando tem anotações nesse step
        const numero_chats = this.notepad.chats.length;
        const oito_segundos = 6000;
        const sumArray = [];
        this.notepad.chats.map((chat, index) => sumArray.push((index + 1) * oito_segundos));
        sumArray.map(number => {
          setTimeout(() => {
            this.notepad.chats.shift();
          }, number);
        });
        setTimeout(() => {
          this.checkRevisao();
          this.notepad.chats = [];
        }, numero_chats * oito_segundos);
      } else {
        // Timer para voltar o player quando não anotações nesse step
        setTimeout(() => {
          this.checkRevisao();
        }, 800);
      }
    }
    );
  }


  stopAndReplayRecord() {
    console.log("stopAndReplayRecord");
    this.statusVideo = "FINALIZADO";
    this.cdr.detectChanges();
    console.log(this.statusVideo);
    //const videoEvent: VideoEvent = {
    //  id: this.studySessionId,
    //  events: this.arr
    //    };
    //this.statusVideo$.next("FINALIZADO");
  }

  private forceClose() {
    clearInterval(this.intervalCheck);
    setTimeout(() => {
      document.getElementById("wrapper").style.height = "100vh";
      document.getElementById("square-top").classList.add("transform-zero");
      document.getElementById("square-bottom").classList.add("transform-zero");
      document.getElementById("logotipo").style.opacity = "1";
      setTimeout(() => {
        this.stopAndReplayRecord();
      }, 2000);
    }, 4000);
  }
}
