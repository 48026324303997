import { Component, HostBinding } from '@angular/core';
import { Constants } from 'app/utils/constants';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})

export class FooterComponent{
    //Variables
    currentDate : Date = new Date();
    version = Constants.version;
}
