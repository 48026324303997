<div id="gp-sair" [routerLink]="['/pages/study']">{{ 'controllers.sair_da_sessao' | translate }}</div>
<button *ngIf="showCloseStudySession" id="gp-encerrar" (click)="modalChangeVisible.emit('confirm')">{{ 'controllers.encerrar_sessao' | translate }}</button>
<div class="control-panel">
  <div class="control-panel-container">
    <div class="control-panel-container-infos">
      <p id="control-name" class="name-study">{{ studyTitle }}</p>
    </div>
    <div class="control-panel-container-buttons">
      <div class="button-collun">
        <button class="skip" [disabled]="currentArray == 0" (click)="buttonSkip.emit('skipPrev')">
          <i class="fas fa-arrow-alt-to-left"></i>
        </button>
      </div>
      <div class="button-collun">
        <button style="padding: 15px;" (click)="onclickPrev()" id="prev">{{ 'controllers.anterior' | translate }}</button>
      </div>
      <div class="button-collun">
        <button style="padding: 15px;" (click)="onClickPlay()" id="play">play</button>
        <button style="padding: 15px;display: none;" (click)="onClickPause()" id="pause">pause</button>
      </div>
      <div class="button-collun">
        <button style="padding: 15px;" (click)="onClickNext()" id="next">{{ 'controllers.proximo' | translate }}</button>
      </div>
      <div class="button-collun">
        <button class="skip" id="skip" [disabled]="currentArray == (handleHistory.length - 1)"
          (click)="buttonSkip.emit('skipNext')">
          <i class="fas fa-arrow-alt-to-right"></i>
        </button>
      </div>
    </div>
    <div class="control-panel-container-infos revisao">
      <p id="control-revisao"><strong>
        {{ roundRevisao?.step == 'HOLE_CARDS' ?  "PRE_FLOP" : roundRevisao?.step  == "SHOW_DOWN" ? "RIVER" : roundRevisao?.step }}
      </strong></p>
      <!-- <button class="control-panel-container-buttons-gravando">GRAVANDO...</button> -->
    </div>
  </div>
</div>
<div class="menu-mobile">
  <div class="logo-goodpoker">
    <img src="/assets/img/logos/logo-good-poker-small.png" >
  </div>
  <div class="row justify-content-center w-100 menus">
    <div class="menu" (click)="activeMenu('tab-annotation')">
      <i class="ft-book-open icon"></i>
      <span>{{ 'controllers.anotacoes' | translate }}</span>
    </div>
    <div class="menu" (click)="activeMenu('tab-chat')">
      <i class="ft-message-square icon"></i>
      <span>{{ 'controllers.chat' | translate }}</span>
    </div>
    <!-- <div class="menu">
      <i class="ft-log-out icon"></i>
      <span>Encerrar Sessão</span>
    </div>
    <div class="menu">
      <i class="ft-menu icon"></i>
      <span>Menu</span>
    </div> -->
  </div>
</div>
