import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReplayerTableComponent } from './replayer-table.component';
import { DeskComponent } from './desk/desk.component';
import { ControllersComponent } from './controllers/controllers.component';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "../../../../assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    ReplayerTableComponent,
    DeskComponent,
    ControllersComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    })
  ],
  exports: [
    ReplayerTableComponent
  ]
})
export class ReplayerTableModule { }
