<div class="i18n-dropdown dropdown nav-item mr-2" display="dynamic" ngbDropdown>
  <a class="nav-link d-flex align-items-center dropdown-toggle dropdown-language language-area" id="dropdown-flag"
    data-toggle="dropdown" ngbDropdownToggle>
    <img class="langimg selected-flag" [src]="selectedLanguageFlag" alt="flag" />
    <span class="selected-language d-md-flex d-none">&#32;{{selectedLanguageText | uppercase}}
      <i class="fa fa-chevron-down ml-1"></i>
    </span>
  </a>
  <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right text-left" aria-labelledby="dropdown-flag">
    <a class="dropdown-item" data-language="pt" (click)="changeLanguage('pt', true)">
      <img class="langimg mr-2" src="./assets/img/flags/pt-br.png" alt="flag" />
      <span class="font-small-3">Português</span>
    </a>

    <a class="dropdown-item" data-language="en" (click)="changeLanguage('en', true)">
      <img class="langimg mr-2" src="./assets/img/flags/en.png" alt="flag" />
      <span class="font-small-3">English</span>
    </a>

    <a class="dropdown-item" data-language="es" (click)="changeLanguage('es', true)">
      <img class="langimg mr-2" src="./assets/img/flags/es.png" alt="flag" />
      <span class="font-small-3">Español</span>
    </a>

    <a class="dropdown-item" data-language="it" (click)="changeLanguage('it', true)">
      <img class="langimg mr-2" src="./assets/img/flags/it.png" alt="flag" />
      <span class="font-small-3">Italiano</span>
    </a>

    <a class="dropdown-item" data-language="de" (click)="changeLanguage('de', true)">
      <img class="langimg mr-2" src="./assets/img/flags/de.png" alt="flag" />
      <span class="font-small-3">Deutsch</span>
    </a>

    <a class="dropdown-item" data-language="fr" (click)="changeLanguage('fr', true)">
      <img class="langimg mr-2" src="./assets/img/flags/fr.png" alt="flag" />
      <span class="font-small-3">Français</span>
    </a>

    <a class="dropdown-item" data-language="nl" (click)="changeLanguage('nl', true)">
      <img class="langimg mr-2" src="./assets/img/flags/nl.png" alt="flag" />
      <span class="font-small-3">Nederlands</span>
    </a>

    <a class="dropdown-item" data-language="pl" (click)="changeLanguage('pl', true)">
      <img class="langimg mr-2" src="./assets/img/flags/pl.png" alt="flag" />
      <span class="font-small-3">Polski</span>
    </a>

    <a class="dropdown-item" data-language="ro" (click)="changeLanguage('ro', true)">
      <img class="langimg mr-2" src="./assets/img/flags/ro.png" alt="flag" />
      <span class="font-small-3">Română</span>
    </a>

    <a class="dropdown-item" data-language="si" (click)="changeLanguage('si', true)">
      <img class="langimg mr-2" src="./assets/img/flags/si.png" alt="flag" />
      <span class="font-small-3">Slovenščina</span>
    </a>

    <a class="dropdown-item" data-language="ru" (click)="changeLanguage('ru', true)">
      <img class="langimg mr-2" src="./assets/img/flags/ru.png" alt="flag" />
      <span class="font-small-3">Русский</span>
    </a>

    <a class="dropdown-item" data-language="ja" (click)="changeLanguage('ja', true)">
      <img class="langimg mr-2" src="./assets/img/flags/ja.png" alt="flag" />
      <span class="font-small-3">日本語</span>
    </a>

    <a class="dropdown-item" data-language="zh" (click)="changeLanguage('zh', true)">
      <img class="langimg mr-2" src="./assets/img/flags/zh.png" alt="flag" />
      <span class="font-small-3">简体中文</span>
    </a>

  </div>
</div>
